import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Subject, timer } from "rxjs";
import { SpinnerService } from "./spinner.service";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from "@angular/router";
import { filter, takeUntil, tap } from "rxjs/operators";
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: "roofing-passport-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnDestroy, AfterViewInit {
  endlessSpinnerSubject: Subject<void>;

  @ViewChild("spinnerElement") spinnerElement: ElementRef;

  afterRouteHideDelayInMilliseconds = 800;
  faCircleNotch = faCircleNotch;

  constructor(
    public spinner: SpinnerService,
    private router: Router,
    private ngZone: NgZone,
    private renderer: Renderer2
  ) {
    this.endlessSpinnerSubject = new Subject<void>();
  }

  ngAfterViewInit(): void {
    this.configureSpinner();
  }

  ngOnDestroy(): void {
    this.endlessSpinnerSubject.complete();
  }

  private togglerSpinner(show: boolean) {
    this.ngZone.runOutsideAngular(() => {
      this.renderer.setStyle(this.spinnerElement.nativeElement, "display", show ? "flex" : "none");
    });
  }

  private configureSpinner() {
    let screenElementsLoading = true;
    window.addEventListener("load", function () {
      console.log("All resources finished loading!");
      screenElementsLoading = false;
    });

    this.spinner.status$.pipe(tap((show: boolean) => this.togglerSpinner(show))).subscribe();

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError),
        tap((e: RouterEvent) => {
          //review app insight addition importance.
          //this.appInsights.trackPageView(e.id.toString(), e.url);
          timer(this.afterRouteHideDelayInMilliseconds).subscribe(() => {
            if (!screenElementsLoading) {
              console.log("Contents fully loaded....Hiding spinner after data is loaded.");
            }
          });
        }),
        takeUntil(this.endlessSpinnerSubject)
      )
      .subscribe();

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        tap(() => {}),
        takeUntil(this.endlessSpinnerSubject)
      )
      .subscribe();
  }
}
