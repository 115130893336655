import { Component, HostBinding, Input, OnInit, TemplateRef } from "@angular/core";
import { SidebarService } from "../services/sidebar.service";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "detail-sidebar",
  templateUrl: "./detail-sidebar.component.html",
  styleUrls: ["./detail-sidebar.component.scss"],
})
export class DetailSidebarComponent implements OnInit {
  @Input() isLeft: boolean = false;
  faTimes = faTimes;

  isOpen: boolean = false;
  content: any;

  @HostBinding("class.active") get active() {
    return this.isOpen;
  }

  @HostBinding("class.left") get left() {
    return this.isLeft;
  }

  @HostBinding("class.right") get right() {
    return !this.isLeft;
  }

  constructor(private sidebarService: SidebarService) {}

  ngOnInit(): void {
    this.sidebarService.isOpen$.subscribe((isOpen) => (this.isOpen = isOpen));
    this.sidebarService.template$.subscribe((content) => (this.content = content));
  }

  closeSidebar(): void {
    this.sidebarService.closeSidebar();
  }
}
