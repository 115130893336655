import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { RegisterAccountComponent } from "./register-account.component";

const RegisterAccountRoutes: Routes = [
  {
    path: "register-account",
    title: 'Register - Roofing Passport',
    component: RegisterAccountComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(RegisterAccountRoutes)],
  exports: [RouterModule],
})
export class RegisterAccountRoutingModule {}
