<section class="p-4 border-1 border-outline flex flex-column">
  <div class="flex flex-column align-items-center row-gap-3">
    <fa-icon [icon]="faCircleCheck" size="7x" class="text-main"></fa-icon>
    <h1 class="font-light text-4xl">Job created successfully!</h1>
    <p class="text-center text-sm max-w-32rem">
      Your new job has been processed and is already available to download. You can download the generated takeoff
      report or try again with different options.
    </p>
  </div>
  <div class="flex justify-content-between mt-5">
    <button pButton class="default outline" (click)="tryAgain()">Try again</button>
    <button pButton (click)="downloadTakeoffReport()">
      <fa-icon [icon]="faDownload"></fa-icon>
      Download takeoff report
    </button>
  </div>
  <alert
    color="main"
    [icon]="faCircleInfo"
    label="About takeoff reports"
    description="This sample takeoff report is available instantly because it was pre-generated using sample data. In real use cases, takeoff reports take between 2-4 hours to generate, depending on the data providers involved."
    styleClass="my-4"
  ></alert>
</section>
<footer class="border-1 border-outline p-4 border-top-none flex justify-content-center">
  <img src="assets/img/logo/rfLogo_icon.svg" alt="Roofing Passport" class="w-7rem" />
  <div class="ml-6 row-gap-3 flex flex-column">
    <h2 class="font-light text-2xl">Join Roofing Passport</h2>
    <p class="max-w-32rem text-sm">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
    </p>
    <div>
      <button pButton>Contact us</button>
      <button pButton class="default outline text-main border-main ml-3">Learn more</button>
    </div>
  </div>
</footer>
