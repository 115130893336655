<div
  [ngClass]="{
    main: color === 'main',
    success: color === 'success',
    warning: color === 'warning',
    danger: color === 'danger',
    mono: color === 'mono',
    outline: style === 'outline',
    transparent: hasBackground === false,
    hasIcon: icon
  }"
  class="statusBadge {{styleClass}}"
>
  <span class="statusBadge__dot"></span>
  <fa-icon *ngIf="icon" class="statusBadge__icon" [icon]="icon"></fa-icon>
  <span class="statusBadge__label">{{ text }}</span>
</div>
