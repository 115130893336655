import { Component, Output, EventEmitter, Input } from "@angular/core";
import { Router } from "@angular/router";
import { faTimes, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { ManufacturerProfile } from "src/app/core/store/app.models";
import { AppState } from "src/app/shared/services/app-state";
import { ProfileService } from "src/app/shared/services/profile.service";

@Component({
  selector: "app-register-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: [],
})
export class WelcomeComponent {
  @Input() isInvited: boolean;
  @Output() beginClicked = new EventEmitter<void>();

  infoVisible: boolean = false;
  faArrowRight = faArrowRight;
  faTimes = faTimes;

  profile: ManufacturerProfile;

  constructor(
    appState: AppState,
    private profileService: ProfileService,
    private router: Router
  ) {
    this.profile = appState.currentProfile;
  }

  toggleInfo() {
    this.infoVisible = !this.infoVisible;
  }

  onBeginClick() {
    this.beginClicked.emit();
  }

  onRejectClick() {
    if (
      window.confirm("Are you sure you want to reject this invitation from " + this.profile.manufacturer.name + "?")
    ) {
      this.profileService.completeInvitation(this.profile.manufacturer.id, false).subscribe({
        next: () => {
          this.router.navigate(["select-company"]);
        },
      });
    }
  }
}
