import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AppState} from "./shared/services/app-state";
import {catchError, map} from "rxjs/operators";
import {UserRole} from "./core/data/models/AppInitializationData";

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(
        private appState: AppState,
        private router: Router
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.appState.appData$.pipe(
            map(appData => appData.user.roles.includes(UserRole.RPAdmin) || this.router.createUrlTree([''])),
            catchError(() => of(this.router.createUrlTree([''])))
        );
    }
}
