import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxsModule } from "@ngxs/store";
import { CoreModule } from "src/app/core/core.module";
import { MessageModule } from "primeng/message";
import { CalendarModule } from "primeng/calendar";
import { CompanySettingsComponent } from "./company-settings.component";
import { CompanySettingsRoutingModule } from "./company-settings-routing.module";
import { CompanyComponent } from "./company/company.component";
import { AffiliationsComponent } from "./company/affiliations/affiliations.component";

@NgModule({
  declarations: [CompanySettingsComponent, CompanyComponent, AffiliationsComponent],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    ReactiveFormsModule,
    FormsModule,
    MessageModule,
    CalendarModule,
    CompanySettingsRoutingModule,
  ],
  exports: [],
  providers: [],
})
export class CompanySettingsModule {}
