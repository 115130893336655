<section class="statusCardsCont" *ngIf="!isLoadingStatistics && orderStatistics">
  <h3 class="srOnly">Stats</h3>
  <p class="statusCard active-jobs" *ngIf="!isLoadingStatistics">
    <span class="statusCard__number">
      {{ orderStatistics.currentMonthToDate }}
    </span>
    <span class="statusCard__label">Current month to date</span>
  </p>
  <p class="statusCard pending-dimensions" *ngIf="!isLoadingStatistics">
    <span class="statusCard__number">
      {{ orderStatistics.currentYearToDate }}
    </span>
    <span class="statusCard__label">Current year to date</span>
  </p>
  <p class="statusCard completed" *ngIf="!isLoadingStatistics">
    <span class="statusCard__number">
      {{ orderStatistics.rolling12Months }}
    </span>
    <span class="statusCard__label">Rolling 12 months</span>
  </p>
  <span *ngIf="isLoadingStatistics">
    <fa-icon [icon]="faCircleNotch" class="loadingSpinner"></fa-icon>
  </span>
</section>
