import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() currentPage: number = 1;
  @Input() totalPages: number = 1;
  @Input() itemsPerPage: number = 12;
  @Input() totalItems: number = 0;
  @Output() pageChange: EventEmitter<any> = new EventEmitter();
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter();

  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  Math = Math;
  pageSizeOptions = [10, 15, 20, 25, 50];
  pageSize = 10;

  ngOnInit() {
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.pageChange.emit(this.currentPage);
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
    this.pageChange.emit(this.currentPage);
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
    this.pageChange.emit(this.currentPage);
  }

  getPageRange(): number[] {
    const range = [];
    const start = Math.max(1, this.currentPage - 1);
    const end = Math.min(this.totalPages, start + 2);

    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    return range;
  }

  onPageSizeChange() {
    this.pageSizeChange.emit(this.pageSize);
  }
}
