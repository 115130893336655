<input
  #fileInput
  type="file"
  [id]="id"
  [name]="name"
  [accept]="accept"
  [multiple]="multiple"
  [disabled]="disabled"
  (change)="onChange($event)"
  class="srOnly"
/>
<label [for]="id" pDroppable (onDrop)="onDrop($event)" class="fileDropLabel" [ngClass]="{ styleClass }">
  <span class="label">{{ label }}</span>
  <div class="fileDropInnerLabel">
    <fa-icon [icon]="faUpload" class="fileDropLabel__icon"></fa-icon>
    <span class="fileDropLabel__title">Drop your file<span *ngIf="multiple">s</span> here</span>
    <span class="fileDropLabel__or">or</span>
    <span pButton class="outline small">Browse files</span>
    <span class="fileDropLabel__accept"
      >Supported formats: <span>{{ acceptText }}</span></span
    >
  </div>
</label>
<div *ngIf="fileList.length != 0" class="fileDropTableCont">
  <p-table
    [value]="fileList"
    [paginator]="false"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{totalRecords} file{multiple : 's'} uploaded"
    margin="0px;"
  >
    <ng-template pTemplate="header">
      <tr class="srOnly">
        <th scope="col">Name</th>
        <th scope="col">Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-file let-i="rowIndex">
      <tr>
        <td>
          {{ file.name }}
        </td>
        <td class="tableButtonCell">
          <button title="Remove file" class="tableButton danger" (click)="onRemoveFile(i)">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
