<div>
  <h3 class="srOnly">Profile</h3>

  <form [formGroup]="companyForm">
    <input type="hidden" id="manufacturerId" formControlName="manufacturerId" class="srOnly"/>

    <div class="grid my-0 flex-column-reverse xl:flex-row">
      <div class="col-12 xl:col-9 py-0">
        <div class="mb-2">
          <div class="inputCont">
            <label for="companyProfile_name" class="label">Company name *</label>
            <input
              type="text"
              id="companyProfile_name"
              class="w-full"
              pInputText
              placeholder="Company name"
              formControlName="name"
            />
            <div
              *ngIf="companyForm.controls['name'].invalid && (companyForm.controls['name'].dirty || companyForm.controls['name'].touched)"
              class="p-error">
              <div *ngIf="companyForm.controls['name'].errors?.required">Name is required.</div>
              <div *ngIf="companyForm.controls['name'].errors?.maxlength">Name must not exceed 50 characters.</div>
            </div>
          </div>
          <div class="inputCont">
            <label for="companyProfile_description" class="label">Description</label>
            <textarea
              id="companyProfile_description"
              formControlName="description"
              class="w-full"
              placeholder="Description"
              pInputTextarea
            ></textarea>
          </div>
        </div>

        <section class="mb-2">
          <h4 class="title4 mb-3">Legal address</h4>
          <div class="grid my-0">
            <div class="col-12 xl:col-8 py-0">
              <div class="inputCont">
                <label for="companyProfile_street" class="label">Street *</label>
                <input
                  type="text"
                  id="companyProfile_street"
                  class="w-full"
                  pInputText
                  placeholder="Street"
                  formControlName="street"
                />
                <div
                  *ngIf="companyForm.controls['street'].invalid && (companyForm.controls['street'].dirty || companyForm.controls['street'].touched)"
                  class="p-error">
                  <div *ngIf="companyForm.controls['street'].errors?.required">Street is required.</div>
                </div>
              </div>
            </div>
            <div class="col-12 lg:col-4 py-0">
              <div class="inputCont">
                <label for="companyProfile_city" class="label">City *</label>
                <input
                  type="text"
                  id="companyProfile_city"
                  class="w-full"
                  pInputText
                  placeholder="City"
                  formControlName="city"
                />
                <div
                  *ngIf="companyForm.controls['city'].invalid && (companyForm.controls['city'].dirty || companyForm.controls['city'].touched)"
                  class="p-error">
                  <div *ngIf="companyForm.controls['city'].errors?.required">City is required.</div>
                </div>
              </div>
            </div>
            <div class="col-12 lg:col-4 py-0">
              <div class="inputCont">
                <label for="companyProfile_state" class="label">State/Province *</label>
                <p-dropdown
                  inputId="companyProfile_state"
                  [options]="states"
                  optionLabel="name"
                  [filter]="true"
                  optionValue="abbreviation"
                  class="w-full"
                  placeholder="Select state/province"
                  formControlName="state"
                ></p-dropdown>
                <div
                  *ngIf="companyForm.controls['state'].invalid && (companyForm.controls['state'].dirty || companyForm.controls['state'].touched)"
                  class="p-error">
                  <div *ngIf="companyForm.controls['state'].errors?.required">State is required.</div>
                </div>
              </div>
            </div>
            <div class="col-6 lg:col-4 py-0">
              <div class="inputCont">
                <label for="companyProfile_postalCode" class="label">Postal code *</label>
                <input
                  type="text"
                  id="companyProfile_postalCode"
                  class="w-full"
                  pInputText
                  placeholder="Postal code"
                  formControlName="postalCode"
                />
                <div
                  *ngIf="companyForm.controls['postalCode'].invalid && (companyForm.controls['postalCode'].dirty || companyForm.controls['postalCode'].touched)"
                  class="p-error">
                  <div *ngIf="companyForm.controls['postalCode'].errors?.required">Postal code is required.</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="mb-2">
          <h4 class="title4 mb-3">Contact info</h4>
          <div class="grid my-0">
            <div class="col-12 xl:col-8 py-0">
              <div class="inputCont">
                <label for="companyProfile_website" class="label">Website</label>
                <input
                  type="text"
                  id="companyProfile_website"
                  class="w-full"
                  pInputText
                  placeholder="Website"
                  formControlName="website"
                />
                <div
                  *ngIf="companyForm.controls['website'].invalid && (companyForm.controls['website'].dirty || companyForm.controls['website'].touched)"
                  class="p-error">
                  <div *ngIf="companyForm.controls['website'].errors?.maxlength">Website must not exceed 50
                    characters.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid my-0">
            <div class="col-12 xl:col-4 py-0">
              <div class="inputCont">
                <label for="companyProfile_contactName" class="label">Contact name</label>
                <input
                  type="text"
                  id="companyProfile_contactName"
                  class="w-full"
                  pInputText
                  placeholder="Contact name"
                  formControlName="contactName"
                />
              </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4 py-0">
              <div class="inputCont">
                <label for="companyProfile_contactEmail" class="label">Contact email</label>
                <input
                  type="email"
                  id="companyProfile_contactEmail"
                  class="w-full"
                  pInputText
                  placeholder="Contact email"
                  formControlName="contactEmail"
                />
                <div
                  *ngIf="companyForm.controls['contactEmail'].invalid && (companyForm.controls['contactEmail'].dirty || companyForm.controls['contactEmail'].touched)"
                  class="p-error">
                  <div *ngIf="companyForm.controls['contactEmail'].errors?.email">A valid email address is required.
                  </div>
                  <div *ngIf="companyForm.controls['contactEmail'].errors?.maxlength">Email must not exceed 50
                    characters.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4 py-0">
              <div class="inputCont">
                <label for="companyProfile_contactPhone" class="label">Contact phone</label>
                <input
                  type="tel"
                  id="companyProfile_contactPhone"
                  class="w-full"
                  pInputText
                  placeholder="Contact phone"
                  formControlName="contactPhone"
                />
              </div>
            </div>
          </div>
        </section>

        <section class="mb-4">
          <h4 class="title4 mb-4">Advanced</h4>
          <section class="card card_border card_border_danger p-0">
            <alert
              color="danger"
              [icon]="faExclamationCircle"
              label="Please be careful"
              description="The following settings are for advanced users only. Don't modify these settings unless you know what you're doing."
            >
            </alert>
            <div class="grid my-0 p-4 pb-0">
              <div class="col-6 xl:col-4 py-0">
                <div class="inputCont">
                  <label for="companyProfile_roofingWRXCompanyId" class="label">Roofing WRX Company ID</label>
                  <input
                    type="text"
                    id="companyProfile_roofingWRXCompanyId"
                    class="w-full"
                    pInputText
                    placeholder="Roofing WRX Company ID"
                    formControlName="roofingWRXCompanyId"
                  />
                  <span
                    class="p-error"
                    *ngIf="companyForm.controls['roofingWRXCompanyId'].invalid && (companyForm.controls['roofingWRXCompanyId'].touched || companyForm.controls['roofingWRXCompanyId'].dirty)"
                  >
                    The Roofing WRX company ID must be an integer number.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>

    <section class="footActionsCont spaced mt-5">
      <button pButton class="main fullMobile" type="button" (click)="save()" [disabled]="isDisabled()">
        <fa-icon [icon]="faSave"></fa-icon>
        Save changes
      </button>
      <button pButton class="default outline fullMobile" type="button" (click)="redirectToCompanies()">
        <fa-icon [icon]="faTimes"></fa-icon>
        Cancel
      </button>
    </section>
  </form>
</div>
