import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {FeaturesComponent} from "./features.component";
import {NewJobComponent} from "./orders/new-job/new-job.component";
import {UserProfileComponent} from "./user-profile/user-profile.component";
import {CompanyProfileComponent} from "./company-profile/company-profile.component";
import {JobListComponent} from "./orders/job-list/job-list.component";
import {HelpComponent} from "./help/help.component";
import {SelectCompanyComponent} from "./select-company/select-company.component";
import {CreatedSuccessfullyComponent} from "./orders/created-successfully/created-successfully.component";
import {SetupGuard} from "../setup.guard";

const FeaturesRoutes: Routes = [
  {
    path: "jobs",
    component: FeaturesComponent,
    canActivate: [SetupGuard],
    children: [
      {
        path: "",
        title: "Job list - Roofing Passport",
        component: JobListComponent,
      },
      {
        path: "new",
        title: "New job - Roofing Passport",
        component: NewJobComponent,
      },
      {
        path: "created-successfully",
        title: "Job created successfully - Roofing Passport",
        component: CreatedSuccessfullyComponent,
      },
    ],
  },
  {
    path: "user-profile",
    title: "User - Roofing Passport",
    component: UserProfileComponent,
    canActivate: [SetupGuard],
  },
  {
    path: "company-profile",
    title: "Company - Roofing Passport",
    component: CompanyProfileComponent,
    canActivate: [SetupGuard],
  },
  {
    path: "help",
    title: "Help & Support - Roofing Passport",
    component: HelpComponent,
    canActivate: [SetupGuard],
  },
  {
    path: "select-company",
    title: "Select company - Roofing Passport",
    component: SelectCompanyComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(FeaturesRoutes)],
  exports: [RouterModule],
})
export class FeaturesRoutingModule {
}
