<div class="flex align-items-center justify-content-center">
  <div *ngFor="let step of steps; let i = index" class="flex align-items-center">
    <div class="flex flex-column align-items-center w-2rem">
      <div
        [ngClass]="{ 'bg-main': isCurrentOrPastStep(step), 'bg-outline': !isCurrentOrPastStep(step) }"
        class="circle border-circle"
      ></div>
      <div class="mt-2 text-xs">{{ step.label }}</div>
    </div>
    <div
      *ngIf="i < steps.length - 1"
      [ngClass]="{
        'bg-main': isCurrentOrPastStep(steps[i + 1]),
        'bg-outline': !isCurrentOrPastStep(steps[i + 1])
      }"
      class="w-4rem h-2px -mt-4"
    ></div>
  </div>
</div>
