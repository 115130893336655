<section>
  <p class="text-sm mb-2">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
    aliqua.
  </p>
  <p-table
    class="mt-2"
    [value]="companies"
    [paginator]="true"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first}-{last} of {totalRecords} elements"
    selectionMode="single"
    [rows]="5"
    styleClass="p-datatable-striped"
  >
    <ng-template pTemplate="header">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Contact Person</th>
        <th scope="col">Email</th>
        <th scope="col">Phone</th>
        <th scope="col" class="tableBadgeCell">Affiliation Status</th>
        <th scope="col" class="tableButtonCell"><span class="srOnly">Actions</span></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-company>
      <tr [pSelectableRow]="company.jobId">
        <td class="tableSubtitleCell tableLeftMarginCellMobile">
          {{ company.name }}
        </td>
        <td class="tableRegularCell">
          <span class="tableMobileLabel" aria-hidden="true">Address</span>
          {{ company.contact }}
        </td>
        <td class="tableSubtitleCell tableLeftMarginCellMobile">
          {{ company.email }}
        </td>
        <td class="tableRegularCell">
          <span class="tableMobileLabel" aria-hidden="true">Address</span>
          {{ company.phoneNumber }}
        </td>
        <td class="tableBadgeCell">
          <status-badge
            [text]="company.status"
            [color]="getCompanyStatusColor(company.status)"
            [hasBackground]="true"
          ></status-badge>
        </td>
        <td class="tableButtonCell">
          <button
            (click)="affiliationsActionsMenu.toggle($event)"
            title="Company Affiliations Actions"
            class="tableButton"
          >
            <fa-icon [icon]="faEllipsisV"></fa-icon>
          </button>
          <p-overlayPanel #affiliationsActionsMenu [appendTo]="'body'">
            <ng-template pTemplate> </ng-template>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>
  </p-table>
</section>
<section *ngIf="companies.length === 0">
  <empty-state label="No Company Affiliations yet" description="Add Affiliation and it'll show up here.">
    <button pButton type="button" class="small">
      <fa-icon [icon]="faPlus"></fa-icon>
      Add Affiliation
    </button>
  </empty-state>
</section>
