<div class="generalCont">
  <div class="mainCont">
    <div class="container_fluid">
      <div class="pageHeading">
        <div>
          <h2 class="title2">Company</h2>
        </div>
        <div *ngIf="tabIndex === 2">
          <a routerLink="../affiliation/new" pButton>
            <fa-icon [icon]="faPlus"></fa-icon>
            Add Affiliation
          </a>
        </div>
      </div>
    </div>

    <div class="container_fluid">
      <p-tabView (onChange)="tabChanged($event)" [(activeIndex)]="tabIndex">
        <p-tabPanel header="Profile"> </p-tabPanel>
        <p-tabPanel header="Structure"> </p-tabPanel>
        <p-tabPanel header="Affiliations">
          <app-affiliations></app-affiliations>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
