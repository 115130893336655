import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxsModule } from "@ngxs/store";
import { CoreModule } from "src/app/core/core.module";
import { SharedModule } from "src/app/shared/shared.module";
import { AccountSettingsComponent } from "./account-settings.component";
import { AccountSettingsRoutingModule } from "./account-settings-routing.module";
import { ProfileComponent } from "./profile/profile.component";
import { SecurityComponent } from "./security/security.component";
import { CompaniesComponent } from "./companies/companies.component";
import { IntegrationsComponent } from "./integrations/integrations.component";

@NgModule({
  declarations: [
    AccountSettingsComponent,
    ProfileComponent,
    SecurityComponent,
    CompaniesComponent,
    IntegrationsComponent,
  ],
  imports: [AccountSettingsRoutingModule, CommonModule, SharedModule, CoreModule, NgxsModule.forFeature([])],
  exports: [AccountSettingsComponent, ProfileComponent, SecurityComponent, CompaniesComponent, IntegrationsComponent],
  providers: [],
})
export class AccountSettingsModule {}
