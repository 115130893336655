<div class="sidebar" *ngIf="isOpen" [ngClass]="{ active: isOpen, left: isLeft, right: !isLeft }">
  <div class="detailSidebar_topActions mb-6" title="More actions">
    <button class="unstyledButton detailSidebar_topActions__btn" title="Close" (click)="closeSidebar()">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>

  <ng-container *ngIf="content">
    <div class="pt-6">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </ng-container>
</div>
