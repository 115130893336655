<div class="container_fluid">
  <div class="pageHeading">
    <div>
      <h3 class="title2">KPI Reporting</h3>
    </div>
    <div>
      <button pButton type="button" class="outline" pTooltip="Export as CSV" tooltipPosition="bottom"
        (click)="exportOrdersCSV(selectedTab)">
        <fa-icon [icon]="faDownload"></fa-icon>
        Export
      </button>
    </div>
  </div>
  <p-tabView [(activeIndex)]="selectedTabIndex" (activeIndexChange)="onTabChange($event)">
    <p-tabPanel header='Orders' >
      <app-order-statistics></app-order-statistics>
      <div class="container_fluid">
        <section>
          <div class="flex justify-content-between align-items-center">
            <div class="inputCont">
              <label class="label" for="fromDateFilter">From</label>
              <p-calendar id="fromDateFilter" [(ngModel)]="fromDateFilter" (ngModelChange)="onDateChange()"
                [readonlyInput]="true" inputId="fromDateFilter" dateFormat="mm-dd-yy" styleClass="block"
                inputStyleClass="w-10rem small" [appendTo]="'body'"></p-calendar>
            </div>
            <app-clear-filters-button *ngIf="areFiltersApplied()" (onClick)="clearFilters()"></app-clear-filters-button>
          </div>

          <p-table #ordersTable styleClass="p-datatable p-datatable-striped" [columns]="ordersTableColumns"
            [value]="orders" [rows]="10" [(selection)]="selectedOrder" [rowHover]="true" [loading]="isLoadingOrders">
            <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let col of ordersTableColumns" pSortableColumn="{{ col.field }}" id="{{ col.field }}"
                  scope="col">
                  {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
              <tr>
                <td headers="jobId">{{ order.jobNumber }}</td>
                <td headers="jobName">{{ order.jobName }}</td>
                <td headers="createdDate">{{ order.createdOn | date: "MM/dd/yyyy" : "+0000" }}</td>
                <td headers="createdBy">{{ order.createdBy }}</td>
                <td headers="jobStatus">{{ order.jobStatus }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <empty-state label="No orders found"
                description="There are no order for the selected timeframe."></empty-state>
            </ng-template>
          </p-table>
          <pagination [totalPages]="getTotalPages()" [currentPage]="page" [itemsPerPage]="pageSize"
            [totalItems]="totalReportsCount" (pageChange)="onPageChange($event)"
            (pageSizeChange)="onPageSizeChange($event)" selectedJobId></pagination>
        </section>
        <p-messages styleClass="absolute bottom-0 right-0 z-5"></p-messages>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Orders by company">
      <app-kpi-company (listCompanyEmit)="listCompanies($event)"></app-kpi-company>
    </p-tabPanel>
    <p-tabPanel header="Orders by user" >
      <app-kpi-company-user (listCompanyByUserEmit)="listCompaniesByUser($event)"></app-kpi-company-user>
    </p-tabPanel>
  </p-tabView>
</div>