<div class="container_fluid">
  <div class="pageHeading">
    <div>
      <h2 class="title2">Companies</h2>
    </div>
    <div *ngIf="CREATE_COMPANY_FEATURE_FLAGS.THREE_DOTS_PANEL">
      <button pButton class="main float-right" (click)="redirectTo('company')">
        <fa-icon [icon]="faPlus"></fa-icon>
        New company
      </button>
    </div>
  </div>

  <app-clear-filters-button
    class="block ml-auto"
    *ngIf="areFiltersApplied()"
    (onClick)="clearFilters()"
  ></app-clear-filters-button>
  <section>
    <p-table
      #companiesTable
      [value]="companies"
      [showCurrentPageReport]="true"
      [rows]="10"
      styleClass="p-datatable-striped"
      margin="0px;"
      [customSort]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" (click)="onHeaderClick('Name')" pSortableColumn="name"  class="">Name <p-sortIcon field="name"></p-sortIcon></th>
          <th scope="col" class="">Users</th>
          <th scope="col" (click)="onHeaderClick('Company')" pSortableColumn="Company" class="">Parent company <p-sortIcon field="Company"></p-sortIcon></th>
          <th scope="col" class="tableButtonCell"><span class="srOnly">Edit</span></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-company>
        <tr>
          <td class="tableRegularCell">
            <span class="tableMobileLabel text-bold" aria-hidden="true">Name</span>
            {{company.name}}
          </td>
          <td class="tableRegularCell">
            <span class="tableMobileLabel" aria-hidden="true">Users</span>
            {{company.users > 0 ? company.users : '-'}}
          </td>
          <td class="tableRegularCell">
            <span class="tableMobileLabel" aria-hidden="true">Parent company</span>
            {{company.parentCompanyName ?? '-' }}
          </td>
          <td class="tableButtonCell">
            <a (click)="redirectTo('edit-company', company.id)" class="tableButton" title="Edit">
              <fa-icon [icon]="faPen"></fa-icon>
            </a>
          </td>
          <td class="tableButtonCell" *ngIf="CREATE_COMPANY_FEATURE_FLAGS.CREATE_NEW_COMPANY">
            <button (click)="jobActionsMenu.toggle($event)" title="Actions" class="tableButton">
              <fa-icon [icon]="faEllipsisV"></fa-icon>
            </button>
            <p-overlayPanel #jobActionsMenu [appendTo]="'body'">
              <ng-template pTemplate>
                <ul class="dropdownMenu">
                  <li (click)="redirectTo('edit-company', company, 1)">
                    <button class="dropdownButton">
                      <fa-icon [icon]="faSitemap" class="dropdownButton__icon"></fa-icon>
                      <span>View company structure</span>
                    </button>
                  </li>
                  <li (click)="redirectTo('edit-company', company, 2)">
                    <button class="dropdownButton">
                      <fa-icon [icon]="faCircleNodes" class="dropdownButton__icon"></fa-icon>
                      <span>View affiliated companies</span>
                    </button>
                  </li>
                  <li>
                    <button class="dropdownButton danger">
                      <fa-icon [icon]="faToggleOff" class="dropdownButton__icon"></fa-icon>
                      <span>Deactivate</span>
                    </button>
                  </li>
                  <li>
                    <button class="dropdownButton danger">
                      <fa-icon [icon]="faTrash" class="dropdownButton__icon"></fa-icon>
                      <span>Delete</span>
                    </button>
                  </li>
                </ul>
              </ng-template>
            </p-overlayPanel>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <pagination
    [totalPages]="totalPages"
    [currentPage]="pageNumber"
    [itemsPerPage]="pageSize"
    [totalItems]="totalCount"
    (pageChange)="onPageChange($event)"
    (pageSizeChange)="onPageSizeChange($event)"
    selectedJobId
    ></pagination>
  </section>
</div>
