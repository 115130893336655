import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxsModule } from "@ngxs/store";
import { CoreModule } from "src/app/core/core.module";
import { SharedModule } from "src/app/shared/shared.module";
import { RegisterAccountComponent } from "./register-account.component";
import { RegisterAccountRoutingModule } from "./register-account-routing.module";
import { WelcomeComponent } from "./welcome/welcome.component";
import { TermsComponent } from "./terms/terms.component";
import { FormsModule } from "@angular/forms";
import { DetailsComponent } from './details/details.component';
import { VerificationComponent } from './verification/verification.component';
import { SummaryComponent } from './summary/summary.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { ConfirmComponent } from './confirm/confirm.component';

@NgModule({
  declarations: [RegisterAccountComponent, WelcomeComponent, TermsComponent, DetailsComponent, VerificationComponent, SummaryComponent, IntegrationsComponent, ConfirmComponent],
  imports: [
    RegisterAccountRoutingModule,
    CommonModule,
    SharedModule,
    CoreModule,
    FormsModule,
    NgxsModule.forFeature([]),
  ],
  exports: [RegisterAccountComponent, WelcomeComponent, TermsComponent],
  providers: [],
})
export class RegisterAccountModule {}
