<div class="container_fluid">
  <div class="pageHeading">
    <h2 class="title2">Companies</h2>
  </div>
  <div>
    <p class="text-sm max-w-36rem mb-3">
      These are the companies you're associated with. Your active company will absorb the cost of quotes created through
      Roofing Passport, but might limit your marketplace features and part selection.
    </p>
    <p class="text-sm max-w-36rem mb-5">
      If you have multiple companies, you can swap them at any time from the account menu (
      <fa-icon [icon]="faUserCircle"></fa-icon>
      ). Your jobs for each company will remain separate.
    </p>
    <div>
      <div
        class="border-1 border-outline mt-4 flex flex-column row-gap-3 md:row-gap-0 md:flex-row justify-content-between px-4 py-3"
        *ngFor="let profile of profiles"
      >
        <div class="flex align-items-center">
          <img
            class="w-3rem h-3rem md:w-4rem md:h-4rem border-outline border-1 border-circle"
            src="assets/img/placeholder/companyProfilePicPlaceholder.png"
            alt=""
          />
          <div class="pl-4">
            <h3 class="text-base md:text-lg font-bold">{{ profile.manufacturer.name }}</h3>
            <p class="text-xs md:text-sm">Business relation type / Role</p>
          </div>
        </div>
        <div class="flex align-items-center justify-content-end column-gap-3">
          <status-badge
            color="success"
            text="Active"
            styleClass="py-2 px-3"
            *ngIf="activeProfileId === profile.manufacturer.id"
          ></status-badge>
        </div>
      </div>
    </div>
  </div>
</div>
