<p-dialog
  styleClass="w-30rem overflow-hidden"
  [(visible)]="isVisible"
  [modal]="true"
  [draggable]="false"
  (onHide)="closeModal()"
>
  <p-header>
    <h2 class="font-light text-2xl">Edit in SmartBuild</h2>
  </p-header>
  <p class="text-sm mt-3 mb-4">
    After generating your takeoff report, you can use SmartBuild to accurately estimate material and labor costs.
  </p>

  <div class="flex flex-column align-items-center mb-5">
    <div
      class="border-1 py-3 px-4 flex align-items-center justify-content-center font-semibold lineAfter animated-line relative"
      [ngClass]="{
        'border-main': currentStep === 1,
        'border-outline': currentStep !== 1
      }"
    >
      <img src="assets/img/logo/rfLogo_icon.svg" alt="Roofing Passport" class="w-2rem" />
      <span class="text-sm ml-4">Roofing Passport</span>
    </div>
    <div
      class="border-1 mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold relative"
      [ngClass]="{
        'border-main': currentStep === 2,
        'border-outline': currentStep !== 2
      }"
    >
      <img src="assets/img/icons/source_sb.png" alt="SmartBuild" class="w-2rem" />
      <span class="text-sm ml-4">SmartBuild</span>
    </div>
  </div>

  <p-divider></p-divider>
  <h4 class="my-3 font-semibold text-xs">Step {{ currentStep }}</h4>
  <p class="text-sm" [ngSwitch]="currentStep">
    <ng-container *ngSwitchCase="1">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. <span class="font-semibold">Roofing Passport</span> Sed
      ipsam.
    </ng-container>
    <ng-container *ngSwitchCase="2">
      Lorem ipsum sit amet consectetur adipisicing elit. <span class="font-semibold">SmartBuild</span> Sed ipsam eaque
      voluptatibus.
    </ng-container>
  </p>
  <div class="flex justify-content-between mt-4">
    <button *ngIf="currentStep > 1" pButton class="default outline text-main border-main" (click)="previousStep()">
      <fa-icon [icon]="faArrowLeft"></fa-icon>
      Back
    </button>
    <div class="ml-auto">
      <button *ngIf="currentStep === 1" pButton class="default outline mr-3 text-main border-main" (click)="skip()">
        Skip
      </button>
      <button pButton (click)="nextStep()">
        {{ currentStep === 2 ? "Continue" : "Next" }}
        <fa-icon [icon]="faArrowRight"></fa-icon>
      </button>
    </div>
  </div>
</p-dialog>
