<div class="generalCont">
  <roofing-passport-side-navigation
    headerText="Account settings"
    [menuItems]="adminMenuItems"
  >
  </roofing-passport-side-navigation>
  <div class="mainCont hasSidebar">
    <router-outlet></router-outlet>
  </div>
</div>
