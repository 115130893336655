<div class="container" *ngIf="isVisible">
  <div class="pageHeading pageHeading_center columnMobileOnly">
    <h1 class="pageTitle pageTitle_steps title2 my-0">Create new job</h1>
  </div>
  <section class="card card_border card_border_noMobile">
    <div class="jobTitleSummary">
      <div class="jobTitleSummary__imgCont">
        <img
          *ngIf="dataSource === 'EagleView'"
          src="assets/img/icons/source_ev.png"
          alt="EagleView"
          class="jobTitleSummary__img"
        />
        <img
          *ngIf="dataSource === 'RoofingWRX'"
          src="assets/img/icons/source_rw.png"
          alt="Roofing WRX"
          class="jobTitleSummary__img"
        />
      </div>
      <h2 class="jobTitleSummary__titleCont">
        <span class="subtitle">Job No. {{ jobNumber }}</span>
        <span class="title3">{{ jobName }}</span>
      </h2>
    </div>
    <div class="text-center mb-5">
      <div class="messageCircle main mb-3">
        <fa-icon [icon]="faCheck"></fa-icon>
      </div>
      <h3 class="title2 mb-3">Job created successfully!</h3>
      <p class="textWidthLimit">
        Your new job is being processed and is expected to be completed in a few hours. You can keep track its progress
        or create another job.
      </p>
    </div>
    <section class="footActionsCont spaced">
      <div class="footActionsCont">
        <!-- <button pButton class="main fullMobile" type="button">View job</button> -->
        <button (click)="hideAndNavigate()" pButton class="outline main fullMobile">
          <fa-icon [icon]="faPlus"></fa-icon> Create another job
        </button>
      </div>
      <a [routerLink]="['/jobs']" pButton class="outline default fullMobile"> Go back to jobs list </a>
    </section>
  </section>
</div>
