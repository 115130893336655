import { Component, EventEmitter, Input, Output } from "@angular/core";
import { faCircleUser, faLocationDot, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-dialog-first-step",
  templateUrl: "./dialog-first-step.component.html",
  styleUrls: ["./dialog-first-step.component.scss"],
})
export class DialogFirstStepComponent {
  @Input() isVisible: boolean = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Input() jobDataSource: "option1" | "option2" | "option3";

  faCircleUser = faCircleUser;
  faLocationDot = faLocationDot;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;

  constructor() {}

  closeModal = () => {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  };
}
