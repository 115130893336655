import { Component, OnInit } from "@angular/core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"],
})
export class CompanyComponent implements OnInit {
  faPlus = faPlus;
  companies: any = [];
  tabIndex: any;
  constructor() {}

  ngOnInit(): void {}

  tabChanged(event: any) {
    console.log("Value: ", event);
    console.log("Tab Index ", this.tabIndex);
  }
}
