<p class="commentListIndicator">{{ comments.length }} notes</p>
<ul class="commentList">
  <li class="comment" *ngFor="let comment of comments">
    <div class="commentHead">
      <img src="assets/img/placeholder/userProfilePicPlaceholder.png" alt="" class="commentHead__img" />
      <p class="commentHead__text">
        <span class="commentHead__date">{{ comment.date | date: "medium" }}</span>
        <span class="commentHead__title">
          <span class="commentHead__title__name">{{ comment.contact_name || "Unknown user" }}</span>
        </span>
      </p>
    </div>
    <p class="commentBody">
      {{ comment.text }}
    </p>
  </li>
</ul>
