import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "status-badge",
  templateUrl: "./status-badge.component.html",
  styleUrls: ["./status-badge.component.scss"],
})
export class StatusBadgeComponent implements OnInit {
  @Input() text: string;
  @Input() icon: string;
  @Input() color: string;
  @Input() style: string;
  @Input() hasBackground: boolean = true;
  @Input() styleClass: string = null;

  constructor() {}

  ngOnInit(): void {}
}
