import { Component, Input } from "@angular/core";
import {
  faCircleUser,
  faLocationDot,
  faArrowRight,
  faArrowLeft,
  faGear,
  faPaperPlane,
  faCamera,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-eagleview-third-step",
  templateUrl: "./eagleview.component.html",
  styleUrls: ["./eagleview.component.scss"],
})
export class EagleviewComponent {
  @Input() onClose: () => void;
  @Input() onContinueClick: () => void;

  currentStep: number = 1;

  faCircleUser = faCircleUser;
  faLocationDot = faLocationDot;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  faGear = faGear;
  faPaperPlane = faPaperPlane;
  faCamera = faCamera;
  faCheck = faCheck;
  constructor() {}

  nextStep() {
    if (this.currentStep < 4) {
      this.currentStep++;
    } else {
      if (this.onContinueClick) this.onContinueClick();
      this.onClose();
    }
  }

  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  skip() {
    this.onClose();
    if (this.onContinueClick) this.onContinueClick();
  }
}
