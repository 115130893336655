<p-dialog styleClass="max-w-30rem" [(visible)]="isVisible" [modal]="true" [draggable]="false" (onHide)="closeModal()">
  <ng-template pTemplate="header">
    <h4 class="font-light text-2xl">Add {{ partnerSystem }} account</h4>
  </ng-template>
  <p class="text-sm">Set up your account in order to gain access to {{ partnerSystem }}.</p>
  <p-divider styleClass="mt-4 mb-3"></p-divider>
  <h4 class="font-light text-xl">Request account</h4>
  <p class="mt-2 text-sm">
    If you don't have an {{ partnerSystem }} account, you can apply for one. Requests usually take less than 24 hours.
  </p>
  <button
    pButton
    class="mt-3"
    type="button"
    [disabled]="authorization?.registrationRequested"
    (click)="requestPartnerSystemAccess()"
  >
    Request {{ partnerSystem }} account
  </button>
  <p class="text-xs mt-3">
    You'll request an account with your current company, <span class="font-semibold">{{ companyName }}</span>, which will be
    responsible for usage costs.
  </p>
  <div
    *ngIf="authorization?.registrationRequested && !authorization?.registrationCompleted && !authorization?.rejected"
    class="bg-success text-success bg-success-10 flex align-items-baseline p-3 mt-4"
  >
    <fa-icon [icon]="faCircleCheck"></fa-icon>
    <div class="ml-3">
      <h4 class="font-bold text-sm">Account requested</h4>
      <p class="text-sm max-w-24rem pt-1">
        Your request is being processed; we'll notify you as soon as your new account is available.
      </p>
    </div>
  </div>
  <div
    *ngIf="authorization?.registrationCompleted && !authorization?.rejected"
    class="bg-success text-success bg-success-10 flex align-items-baseline p-3 mt-4"
  >
    <fa-icon [icon]="faCircleCheck"></fa-icon>
    <div class="ml-3">
      <h4 class="font-bold text-sm">Account created</h4>
      <p class="text-sm max-w-24rem pt-1">
        Your account has been created; please check your email for your new credentials.
      </p>
    </div>
  </div>
  <div
    *ngIf="authorization?.rejected"
    class="bg-danger text-danger bg-danger-10 flex align-items-baseline p-3 mt-4"
  >
    <fa-icon [icon]="faCircleXmark"></fa-icon>
    <div class="ml-3">
      <h4 class="font-bold text-sm">Account rejected</h4>
      <p class="text-sm max-w-24rem pt-1">
        Your account request has been rejected. Please contact our support team for further assistance.
      </p>
    </div>
  </div>
  <p-divider styleClass="mt-4 mb-3"></p-divider>
  <h4 class="font-light text-xl">Sign in</h4>
  <p class="mt-2 text-sm">You can also sign in with an existing account if you have one:</p>
  <button pButton class="mt-4" type="button" (click)="signIn()">Sign in to {{ partnerSystem }}</button>
</p-dialog>
