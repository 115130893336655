import { Component, OnInit, ViewChild } from "@angular/core";
import { faHammer, faGears, faFile, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IntroModalComponent } from "../intro-modal/intro-modal.component";

@Component({
  selector: "app-start",
  templateUrl: "./start.component.html",
  styleUrls: ["./start.component.scss"],
})
export class StartComponent implements OnInit {
  faHammer = faHammer;
  faGears = faGears;
  faFile = faFile;
  faArrowRight = faArrowRight;

  startDemoForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.startDemoForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }

  @ViewChild(IntroModalComponent) introModal: IntroModalComponent;

  handleBeginClick() {
    if (this.startDemoForm.valid) {
      const email = this.startDemoForm.value.email;
      this.introModal.open(email);
    }
  }
}
