import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {faArrowRight, faCheck, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {ProfileService} from "../../../shared/services/profile.service";
import {finalize, tap} from "rxjs/operators";
import {Router} from "@angular/router";

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"],
})
export class ConfirmComponent implements OnInit {
  @Input() isInvited: boolean;
  @Output() continueClicked = new EventEmitter<void>();

  hasOwnCompany: boolean = false;
  faArrowRight = faArrowRight;
  faCheck = faCheck;
  faInfoCircle = faInfoCircle;

  constructor(private profileService: ProfileService, private router: Router) {
  }

  ngOnInit(): void {
  }

  getStarted(): void {
    this.profileService.validateProfile().pipe(finalize(() => this.router.navigate(['/jobs']))).subscribe();
  }
}
