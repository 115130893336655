import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class HeaderService {

  private _isSelectingCompany = new BehaviorSubject<boolean>(false);
  isSelectingCompany = this._isSelectingCompany.asObservable();

  constructor() {
  }

  setIsSelectingCompany(isSelecting: boolean) {
    this._isSelectingCompany.next(isSelecting)
  }
}
