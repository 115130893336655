<div class="commentInput">
  <img [src]="image" alt="" class="commentInput__img" />
  <div class="commentInput__inputCont">
    <textarea
      pInputTextarea
      [autoResize]="true"
      class="commentInput__input"
      [placeholder]="placeholder"
      title="Comment"
      [(ngModel)]="comment"
    ></textarea>
    <p *ngIf="false" class="p-error"><fa-icon [icon]="faWarning"></fa-icon> Error message</p>
    <button
      [disabled]="isCommentEmpty()"
      (click)="onSubmit()"
      pButton
      class="commentInput__submitBtn small square"
      title="Submit"
    >
      <fa-icon [icon]="faPaperPlane"></fa-icon>
    </button>
  </div>
</div>
