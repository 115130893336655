<section>
  <div class="h-11rem bg-gray-200"></div>
  <div class="mx-auto max-w-54rem">
    <header class="w-full flex flex-row justify-content-between border-outline border-bottom-1 pb-4">
      <div class="flex flex-column">
        <img class="w-8rem h-8rem border-outline border-1 border-circle -mt-8 bg-white" src="/" alt="user" />
        <h2 class="pt-2 font-light text-4xl">Company Inc.</h2>
        <a href="#" class="pt-3 text-sm text-main font-bold">
          www.companyinc.com
        </a>
      </div>
      <div class="flex flex-column row-gap-3 pt-4 pr-8">
        <h4 class="font-light text-xl">Contact Info</h4>
        <p class="text-sm flex align-items-center">
          <i class="pi pi-map-marker mr-2"></i>
          Chicago, IL (United States)
        </p>
        <p class="text-sm flex align-items-center">
          <i class="pi pi-envelope mr-2"></i>
          john.doe@company.com <a class="ml-2 font-bold text-main" href="#">Mail</a>
        </p>
        <p class="text-sm flex align-items-center">
          <i class="pi pi-phone mr-2"></i>
          +155523456789 <a class="ml-2 font-bold text-main" href="#">Call</a>
        </p>
      </div>
    </header>
    <div class="pt-4 max-w-33rem">
      <div class="flex">
        <button
          pButton
          class="text-base font-semibold flex align-items-center"
          type="button"
        >
          <i class="pi pi-dollar font-bold"></i>
          <span class="pl-3">Request pricing</span>
        </button>
        <button
          pButton
          class="text-base font-semibold p-button-outlined flex align-items-center ml-3"
          type="button"
        >
          <i class="pi pi-user-plus font-bold"></i>
          <span class="pl-3">Add to contacts</span>
        </button>
      </div>
      <h3 class="pt-5 font-light text-2xl pb-3">About</h3>
      <p class="text-sm">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      </p>
      <h3 class="font-light text-2xl pt-5 pb-3">Work history</h3>
      <div class="bg-gray-200 p-3 flex align-items-baseline">
        <i class="pi pi-info-circle mr-3"></i>
        <div class="text-sm">
          <p class="font-semibold">No records yet</p>
          <p>It seems you or your company haven't interacted with Company Inc. yet. Any project you've been involved with Company Inc. will appear here.</p>
        </div>
      </div>
    </div>
  </div>
</section>
