import { Component, EventEmitter, Input, Output } from "@angular/core";
import { faCog, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-service-item",
  templateUrl: "./service-item.component.html",
  styleUrls: ["./service-item.component.scss"],
})
export class ServiceItemComponent {
  @Input() label: string;
  @Input() description: string;
  @Input() disclaimer?: string;
  @Input() logo: string;
  @Input() isLogoVisible: boolean = true;
  @Input() learnMoreLink?: string;
  @Input() account?: string;
  @Input() isServiceAvailable: boolean;
  @Input() disabled: boolean;
  @Input() isCompact: boolean = false;
  @Input() isBanner: boolean = false;
  @Input() styleClass: string;
  @Input() isDemo: boolean = false;
  @Input() infoTooltip: string;
  @Input() infoAlertTitle: string;
  @Input() infoAlertDescription: string;
  @Input() isInfoAlertVisible: boolean = false;

  @Output() infoButtonClick: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() addAccountClick: EventEmitter<void> = new EventEmitter<void>();

  faCog = faCog;
  faQuestionCircle = faQuestionCircle;

  constructor() {}

  onAddAccount() {
    this.addAccountClick.emit();
  }

  onInfoButtonClick(event: Event) {
    this.infoButtonClick.emit(event);
  }
}
