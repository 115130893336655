<p-accordionTab [selected]="selected" [disabled]="!manualToggle" class="stepCard">
  <ng-template pTemplate="header">
    <div class="stepCardHeader">
      <div class="stepCircle" [ngClass]="{ 'main' : completed }">
        <span *ngIf="!completed">{{number}}</span>
        <fa-icon [icon]="faCheck" *ngIf="completed"></fa-icon>
      </div>
      <div class="stepCardHeader__titleCont">
        <span class="title4">{{label}}</span>
        <span class="stepCardHeader__subtitle" *ngIf="selected && !completed">{{textSelected}}</span>        
        <span class="stepCardHeader__subtitle" *ngIf="completed">
          <fa-icon *ngIf="iconCompleted" [icon]="iconCompleted" class="mr-1"></fa-icon>
          {{textCompleted}}
        </span>
      </div>
    </div>
  </ng-template>
  <ng-content></ng-content>
</p-accordionTab>
