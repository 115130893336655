import {Component} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {faArrowUpRightFromSquare, faPen, faSave} from "@fortawesome/free-solid-svg-icons";
import {AppState} from "src/app/shared/services/app-state";
import {UpdateUserRequest} from "../../../core/data/models/UpdateUserRequest";
import {MessageService} from "primeng/api";
import {AppInitializerService} from "../../../shared/services/app-initializer.service";
import {AdminService} from "../../../shared/services/admin.service";

@Component({
  selector: "app-account-settings-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent {
  faSave = faSave;
  faPen = faPen;
  faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  user = this.appState.user;
  userForm: FormGroup;
  originalValues: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  }

  constructor(
    private appState: AppState,
    private fb: FormBuilder,
    private adminService: AdminService,
    private messageService: MessageService,
    private appInitializerService: AppInitializerService
  ) {
    this.userForm = this.fb.group({
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      email: [this.user.email, [Validators.email, Validators.required]],
      phoneNumber: [this.user.phoneNumber, [Validators.required]],
    });
    this.originalValues = {...this.userForm.value}
  }

  updateUser() {
    const currentValues = {...this.userForm.value};

    const request: UpdateUserRequest = {
      userId: parseInt(this.appState.appData.user.id),
      companyId: this.appState.companyId,
      firstName: currentValues.firstName,
      lastName: currentValues.lastName,
      phone: currentValues.phoneNumber,
      isDeleted: false,
    }

    let errorMessage: string;
    this.adminService.updateUser(request).subscribe(
      () => {
        this.originalValues = this.userForm.value;

        this.appInitializerService.getAppInitialData().subscribe(() => this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "User has been successfully updated.",
          })
        );
      },
      (responseError) => {
        if (responseError.error) {
          errorMessage =
            responseError.error.detail ?? this.appState.configs.NewOrderCommunicationErrorMessage;
        } else {
          errorMessage = responseError.error;
        }

        this.messageService.add({
          severity: "error",
          summary: "Update User Error",
          detail: errorMessage,
        });
      }
    );
  }

  isDisabled() {
    const currentValues = this.userForm.value;
    const valuesChanged =
      currentValues.firstName !== this.originalValues.firstName ||
      currentValues.lastName !== this.originalValues.lastName ||
      currentValues.phoneNumber !== this.originalValues.phoneNumber;

    return !(this.userForm.valid && this.userForm.dirty && valuesChanged);
  }
}
