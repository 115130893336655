import { Component, Input, OnInit } from "@angular/core";
import { UiTemplateData } from "src/app/core/data/models/AppInitializationData";
import { AppState } from "src/app/shared/services/app-state";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  @Input() footerData: UiTemplateData[];
  currentYear = new Date().getFullYear();
  configs = this.appState.configs;

  constructor(public appState: AppState) {}

  ngOnInit(): void {}
}
