import { Component, OnInit } from "@angular/core";
import { faPlus, faEye, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { CompanyAffiliationStatusEnum } from "src/app/core/enums/company-affiliation-status.enum";

@Component({
  selector: "app-affiliations",
  templateUrl: "./affiliations.component.html",
  styleUrls: ["./affiliations.component.scss"],
})
export class AffiliationsComponent implements OnInit {
  faPlus = faPlus;
  faEye = faEye;
  faEllipsisV = faEllipsisV;
  companies: any = [];
  affiliationStatuses = CompanyAffiliationStatusEnum;
  constructor() {}

  ngOnInit(): void {
    this.getCompanyAffiliations();
  }

  getCompanyAffiliations() {
    this.companies = [
      {
        name: "Company Inc",
        contact: "John Doe",
        email: "john.doe@company.com",
        phoneNumber: "+1234567890",
        status: "Active",
      },
      {
        name: "Company Inc",
        contact: "John Doe",
        email: "john.doe@company.com",
        phoneNumber: "+1234567890",
        status: "Pending",
      },
      {
        name: "Company Inc",
        contact: "John Doe",
        email: "john.doe@company.com",
        phoneNumber: "+1234567890",
        status: "Active",
      },
      {
        name: "Company Inc",
        contact: "John Doe",
        email: "john.doe@company.com",
        phoneNumber: "+1234567890",
        status: "Active",
      },
    ];
  }

  getCompanyStatusColor(status: string): string {
    return this.affiliationStatuses[status];
  }
}
