import { Injectable, TemplateRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  private isOpenSubject = new BehaviorSubject<boolean>(false);
  private templateSubject = new BehaviorSubject<TemplateRef<any> | null>(null);

  isOpen$ = this.isOpenSubject.asObservable();
  template$ = this.templateSubject.asObservable();

  openSidebar(template: TemplateRef<any>): void {
    this.templateSubject.next(template);
    this.isOpenSubject.next(true);
  }

  closeSidebar(): void {
    this.isOpenSubject.next(false);
    this.templateSubject.next(null);
  }
}
