import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faArrowLeft,faEnvelope } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {
  @Output() nextClicked = new EventEmitter<void>();
  @Output() goBackClicked = new EventEmitter<void>();
  faArrowLeft = faArrowLeft;
  faEnvelope= faEnvelope;
  displayHelp: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  toggleHelp() {
    this.displayHelp = !this.displayHelp;
  }

  resendEmail() {
  }

  onGoBackClick(){
    this.goBackClicked.emit();
  }
}
