import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { AppState } from "./app-state";
import { ConfirmDialogComponent } from "src/app/features/confirm-dialog/confirm-dialog.component";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  confirmDialog(
    title: string,
    message: string,
    primaryButtonText: string = "Yes",
    secondaryButtonText: string = "No",
    maxWidth: string = "800px"
  ): Observable<boolean> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        data: { title, message, primaryButtonText, secondaryButtonText },
        minWidth: "400px",
        maxWidth,
        disableClose: true,
      })
      .afterClosed();
  }

  eagleViewNoCoverage(): Observable<boolean> {
    return this.confirmDialog(
      "No EagleView Coverage",
      "There is no detailed image data for this location.<br/><br/>If dimensioned sketches or plans are available for your project, we also provide the same services through Roofing WRX.",
      "Use Roofing WRX",
      "Retry Address"
    );
  }
}

export interface ConfirmDialogData {
  title: string;
  message: string;
  primaryButtonText: string;
  secondaryButtonText: string;
}
