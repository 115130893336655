<p-dialog
  styleClass="max-w-30rem"
  [(visible)]="isVisible"
  [modal]="true"
  [draggable]="false"
  (onHide)="startNewJobDemo()"
>
  <h1 class="font-light text-2xl">Before you begin</h1>
  <div class="text-sm mt-3">
    <p class="mb-3">
      You’ll we able to walk through an example of the <span class="font-semibold">job creation process</span>.
    </p>
    <p class="mb-3">
      This process is the core feature of Roofing Passport, which lets you generate
      <span class="font-semibold">takeoff reports</span> automatically.
    </p>
    <p>
      For your convenience, we'll be providing you with sample data and will fill all forms for you.
      <span class="font-semibold">Please understand that you won't be able to edit this data</span>.
    </p>
  </div>
  <p-divider></p-divider>
  <div class="flex flex-column align-items-baseline">
    <h2 class="font-light text-lg mb-2">Want to know more?</h2>
    <p class="text-sm mb-3">
      If you want more information about certain features, look for <span class="font-semibold">info buttons</span> like
      this one:
    </p>
    <app-info-button></app-info-button>
    <p class="text-sm mt-3">
      Pressing them will show you contextual information, like additional details about what certain things do and and
      how they work.
    </p>
  </div>
  <p-divider></p-divider>
  <button pButton class="main ml-auto block" (click)="startNewJobDemo()">Continue</button>
</p-dialog>
