import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-dialog-second-step",
  templateUrl: "./dialog-second-step.component.html",
  styleUrls: ["./dialog-second-step.component.scss"],
})
export class DialogSecondStepComponent {
  @Input() isVisible: boolean = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Input() jobDataSource: "option1" | "option2" | "option3";

  constructor() {}

  closeModal = () => {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  };
}
