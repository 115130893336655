<div class="emptyState">
  <div class="emptyState__detailCont">
    <div *ngIf="icon" class="emptyState__icon">
      <fa-icon [icon]="icon"></fa-icon>
    </div>
    <p *ngIf="label" class="title3 emptyState__title">{{label}}</p>
    <p *ngIf="description" class="emptyState__description">{{description}}</p>
  </div>
  <ng-content></ng-content>
</div>
