<div class="container_fluid">
  <div class="pageHeading">
    <h2 class="title2">Security and password</h2>
  </div>
  <section>
    <h2 class="title4 mb-3">Change password</h2>
    <div class="grid flex-column-reverse md:flex-row my-0">
      <div class="col-12 md:col-6 xl:col-5 py-0">
        <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
          <div class="inputCont">
            <label for="oldPassword" class="label">Old password</label>
            <div class="inputIconCont right">
              <input
                pPassword
                [type]="showOldPassword ? 'text' : 'password'"
                id="oldPassword"
                formControlName="oldPassword"
                [feedback]="false"
                placeholder="Old password"
                class="w-full"
                aria-describedby="error_oldPassword"
              />
              <button
                pButton
                type="button"
                class="default link square inputIconBtn"
                (click)="showOldPassword = !showOldPassword"
                title="Show/hide password"
                tabindex="-1"
              >
                <fa-icon [icon]="showOldPassword ? faEyeSlash : faEye"></fa-icon>
              </button>
            </div>
            <p
              *ngIf="passwordForm.get('oldPassword')?.invalid && passwordForm.get('oldPassword')?.touched"
              class="p-error"
              id="error_oldPassword"
            >
              <fa-icon [icon]="faWarning"></fa-icon>Please enter your old password.
            </p>
          </div>
          <div class="inputCont">
            <label for="newPassword" class="label">New password</label>
            <div class="inputIconCont right">
              <input
                pPassword
                [type]="showNewPassword ? 'text' : 'password'"
                id="newPassword"
                formControlName="newPassword"
                [feedback]="false"
                placeholder="New password"
                class="w-full"
                aria-describedby="error_newPassword"
              />
              <button
                pButton
                type="button"
                class="default link square inputIconBtn"
                (click)="showNewPassword = !showNewPassword"
                title="Show/hide password"
                tabindex="-1"
              >
                <fa-icon [icon]="showNewPassword ? faEyeSlash : faEye"></fa-icon>
              </button>
            </div>
            <p
              *ngIf="passwordForm.get('newPassword')?.errors?.['required'] && passwordForm.get('newPassword')?.touched"
              class="p-error"
              id="error_newPassword"
            >
              <fa-icon [icon]="faWarning"></fa-icon>
              <span>Please enter your new password.</span>
            </p>
          </div>
          <div class="inputCont">
            <label for="newPasswordRepeat" class="label">Repeat new password</label>
            <div class="inputIconCont right">
              <input
                pPassword
                [type]="showNewPasswordRepeat ? 'text' : 'password'"
                id="newPasswordRepeat"
                formControlName="newPasswordRepeat"
                [feedback]="false"
                placeholder="Repeat new password"
                class="w-full"
                aria-describedby="error_newPasswordRepeat"
              />
              <button
                pButton
                type="button"
                class="default link square inputIconBtn"
                (click)="showNewPasswordRepeat = !showNewPasswordRepeat"
                title="Show/hide password"
                tabindex="-1"
              >
                <fa-icon [icon]="showNewPasswordRepeat ? faEyeSlash : faEye"></fa-icon>
              </button>
            </div>
            <p
              *ngIf="passwordForm.get('newPasswordRepeat')?.invalid && passwordForm.get('newPasswordRepeat')?.touched"
              class="p-error"
              id="error_newPasswordRepeat"
            >
              <fa-icon [icon]="faWarning"></fa-icon>Please enter your new password again.
            </p>
            <p
              *ngIf="passwordForm.errors?.['passwordsMismatch'] && passwordForm.get('newPasswordRepeat')?.touched"
              class="p-error"
            >
              <fa-icon [icon]="faWarning"></fa-icon>Passwords do not match.
            </p>
          </div>
          <button pButton class="fullMobile" type="submit" [disabled]="passwordForm.invalid">Change password</button>
        </form>
      </div>
      <div class="col-12 md:col-6 xl:col-7">
        <div class="passwordInstructions mb-4">
          <p>Your password must have:</p>
          <ul class="passwordInstructionsList list-none pl-0">
            <li>
              <fa-icon class="text-success" *ngIf="isConditionMet('minLength'); else bullet" [icon]="faCheck"></fa-icon>
              <ng-template #bullet>&bull;</ng-template>
              At least 8 characters.
            </li>
            <li>
              <fa-icon
                class="text-success"
                *ngIf="isConditionMet('hasLowerCase'); else bullet"
                [icon]="faCheck"
              ></fa-icon>
              <ng-template #bullet>&bull;</ng-template>
              At least one lower case character.
            </li>
            <li>
              <fa-icon
                class="text-success"
                *ngIf="isConditionMet('hasUpperCase'); else bullet"
                [icon]="faCheck"
              ></fa-icon>
              <ng-template #bullet>&bull;</ng-template>
              At least one upper case character.
            </li>
            <li>
              <fa-icon class="text-success" *ngIf="isConditionMet('hasNumber'); else bullet" [icon]="faCheck"></fa-icon>
              <ng-template #bullet>&bull;</ng-template>
              At least one number.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</div>
