<div>
  <h2 class="title4 pb-3">Please confirm your account details</h2>
  <section class="userSummary mb-4">
    <div class="userAvatar">
      <img src="assets/img/placeholder/userProfilePicPlaceholder.png" alt="" class="userAvatar__img"/>
    </div>
    <div class="userSummaryContent">
      <h3 class="userSummary__text userSummary__name">
        <span class="srOnly">User name:</span>
        {{ userDetails.firstName }} {{ userDetails.lastName }}
      </h3>
      <p class="userSummary__text light">
        <span class="srOnly">Email:</span>
        {{ userDetails.email }}
      </p>
      <p class="userSummary__text light">
        <span class="srOnly">Type:</span>
        {{ profile.manufacturer.name }}
      </p>
    </div>
  </section>
  <section>
    <h3 class="srOnly">Integrations</h3>
    <ul class="grid list-none mt-0 mb-3 px-0">
      <li *ngIf="eagleviewAuthorization.status == 'Valid'" class="col-12 md:col-6 lg:col-4 pb-3">
        <div class="flex align-items-center gap-3 card card_border pb-3">
          <img src="assets/img/icons/source_ev.png" alt="EagleView" class="h-3rem"/>
          <span class="text-sm">{{ eagleviewAuthorization.claims.email }}</span>
        </div>
      </li>
      <li class="col-12 md:col-6 lg:col-4 pb-3">
        <div
          *ngIf="smartbuildAuthorization.status == 'Valid'"
          class="flex align-items-center gap-3 card card_border pb-3"
        >
          <img src="assets/img/icons/source_sb.png" alt="SmartBuild" class="h-3rem"/>
          <span class="text-sm">{{ smartbuildAuthorization.claims.email }}</span>
        </div>
      </li>
      <li class="col-12 md:col-6 lg:col-4 pb-3">
        <div
          *ngIf="roofingWRXAuthorization.status == 'Valid'"
          class="flex align-items-center gap-3 card card_border pb-3"
        >
          <img src="assets/img/icons/source_rw.png" alt="Roofing WRX" class="h-3rem"/>
          <span class="text-sm">{{ roofingWRXAuthorization.claims.email }}</span>
        </div>
      </li>
    </ul>
  </section>

  <section class="footActionsCont spaced">
    <button pButton class="success fullMobile" type="button" (click)="onConfirmClick()">
      <fa-icon [icon]="faCheck"></fa-icon>
      Confirm
    </button>
    <button pButton class="default outline fullMobile" type="button" (click)="onGoBackClick()">
      <fa-icon [icon]="faArrowLeft"></fa-icon>
      Go back
    </button>
  </section>
</div>
