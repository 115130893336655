import { Component, Input } from "@angular/core";
import { faInfoCircle, faCheckCircle, faExclamationCircle, faWarning } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
})
export class ToastComponent {

  @Input() claims: any;
  
  faInfoCircle = faInfoCircle;
  faCheckCircle = faCheckCircle;
  faExclamationCircle = faExclamationCircle;
  faWarning = faWarning;

  constructor() {}
}
