<p-header>
  <h2 class="font-light text-2xl">How data providers work</h2>
</p-header>
<p class="text-sm mt-3 mb-4">Visualize how we create your takeoff report, step-by-step:</p>

<div class="flex flex-column align-items-center mb-7rem">
  <div
    class="border-1 border-outline py-3 px-4 flex align-items-center justify-content-center font-semibold lineAfter"
    [ngClass]="{ 'animated-line': currentStep === 2 }"
  >
    <fa-icon size="lg" class="text-main" [icon]="faCircleUser"></fa-icon>
    <span class="text-sm ml-4">You</span>
    <fa-icon *ngIf="currentStep === 2" [icon]="faPaperPlane" class="icon bottomIcon"></fa-icon>
  </div>
  <div
    class="border-1 mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold lineAfter relative border-main animated-line"
  >
    <img src="assets/img/logo/rfLogo_icon.svg" alt="Roofing Passport" class="w-2rem" />
    <span class="text-sm ml-4">Roofing Passport</span>
    <fa-icon *ngIf="currentStep === 2" [icon]="faPaperPlane" class="icon bottomIcon"></fa-icon>
  </div>
  <div class="relative w-full flex flex-column align-items-center">
    <div class="border-1 mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold border-outline">
      <fa-icon *ngIf="currentStep === 1" [icon]="faPaperPlane" class="icon topIcon"></fa-icon>
      <img src="assets/img/icons/source_rw.png" alt="Roofing WRX" class="w-2rem" />
      <span class="text-sm ml-4">Roofing WRX</span>
    </div>
    <div
      class="border-1 border-outline mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold border-outline nonSelectedService"
    >
      <img src="assets/img/icons/source_ev.png" alt="EagleView" class="w-2rem" />
      <span class="text-sm ml-4">EagleView</span>
    </div>
    <div
      class="border-1 border-outline mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold border-outline nonSelectedService jobLocationService"
    >
      <fa-icon size="lg" class="text-main" [icon]="faLocationDot"></fa-icon>
      <span class="text-sm ml-4">Job Location</span>
    </div>
  </div>
</div>

<p-divider></p-divider>
<h4 class="my-3 font-semibold text-xs">Step {{ currentStep }}</h4>
<p class="text-sm" [ngSwitch]="currentStep">
  <ng-container *ngSwitchCase="1">
    The dimensions data obtained from EagleView is converted by Roofing Passport to make it readable by Roofing WRX. The
    data is then sent to Roofing WRX, and converted into
    <span class="font-semibold">3D models and roofing measurements</span>.
  </ng-container>
  <ng-container *ngSwitchCase="2">
    Finally, Roofing WRX sends data back to Roofing Passport, and generates the final
    <span class="font-semibold">takeoff report</span>which is sent to you.
  </ng-container>
</p>
<div class="flex justify-content-between mt-4">
  <button *ngIf="currentStep > 1" pButton class="default outline text-main border-main" (click)="previousStep()">
    <fa-icon [icon]="faArrowLeft"></fa-icon>
    Back
  </button>
  <div class="ml-auto">
    <button *ngIf="currentStep === 1" pButton class="default outline mr-3 text-main border-main" (click)="skip()">
      Skip
    </button>
    <button pButton (click)="nextStep()">
      {{ currentStep === 2 ? "Continue" : "Next" }}
      <fa-icon *ngIf="currentStep === 1" [icon]="faArrowRight"></fa-icon>
    </button>
  </div>
</div>
