import { Component, Input } from "@angular/core";
import { Step } from "./types";

@Component({
  selector: "app-steps",
  templateUrl: "./steps.component.html",
  styleUrls: ["./steps.component.scss"],
})
export class StepsComponent<T> {
  @Input() steps: Step<T>[] = [];
  @Input() currentStep: T;

  isCurrentOrPastStep(step: Step<T>): boolean {
    const currentIndex = this.steps.findIndex((s) => s.id === this.currentStep);
    const stepIndex = this.steps.findIndex((s) => s.id === step.id);
    return stepIndex <= currentIndex;
  }
}
