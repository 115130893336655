import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AuthContext, AuthenticationService} from "src/app/shared/services/authentication.service";
import {AppConstants} from "src/app/shared/constants/app-constants";
import {ProfileService} from "src/app/shared/services/profile.service";
import {catchError, tap} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {MessageService} from "primeng/api";
import {EMPTY} from "rxjs";
import {faCircleCheck, faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {AppState} from "src/app/shared/services/app-state";
import {ThirdPartyAuth} from "../../data/models/AppInitializationData";

@Component({
  selector: "app-add-account-modal",
  templateUrl: "./add-account-modal.component.html",
  styleUrls: ["./add-account-modal.component.scss"],
})
export class AddAccountModalComponent implements OnInit {
  @Input() isVisible: boolean = false;
  @Input() partnerSystem: string = null;
  @Input() authorization: ThirdPartyAuth = null;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() isVisibleChange = new EventEmitter<boolean>();
  faCircleCheck = faCircleCheck;
  faCircleXmark = faCircleXmark;
  companyName: string;

  constructor(
    private authService: AuthenticationService,
    private profileService: ProfileService,
    private messageService: MessageService,
    private appState: AppState
  ) {
  }

  ngOnInit(): void {
    this.appState.profile$
      .subscribe((profile) => { this.companyName = profile.manufacturer.name });
  }

  closeModal() {
    this.isVisible = false;
    this.onClose.emit();
    this.isVisibleChange.emit(this.isVisible);
  }

  signIn() {
    this.authService.loginWithPopup(this.authorization.context).subscribe({
      next: ({isAuthenticated}) => {
        isAuthenticated =
          this.authorization.context == AuthContext.RoofingWRX
            ? this.authService.isAuthenticated(this.authorization.context)
            : isAuthenticated;

        if (isAuthenticated) {
          this.profileService.storeUserToken(this.authorization.context).subscribe({
            next: () => {
              this.closeModal();
            },
          });
        }
      },
    });
  }

  requestPartnerSystemAccess() {
    this.profileService
      .requestPartnerSystemAccess(this.authorization.context)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error?.error?.detail ?? AppConstants.RoofingPassportCommunicationError;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: errorMessage,
          });
          return EMPTY;
        }),
        tap((profile) => {
          this.appState.updateProfile(profile);
          this.authorization = profile.authorizations.find((x) => x.context === this.authorization.context);

          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Invitation successfully sent.",
          });
        })
      )
      .subscribe();
  }
}
