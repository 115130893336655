import { Component, Input, OnInit } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'step-card',
  templateUrl: './step-card.component.html',
  styleUrls: ['./step-card.component.scss']
})
export class StepCardComponent implements OnInit {

  faCheck = faCheck;

  @Input() number: number;
  @Input() label: string;
  @Input() textSelected: string;
  @Input() textCompleted: string;
  @Input() selected: boolean;
  @Input() completed: boolean;
  @Input() manualToggle: boolean = false;
  @Input() iconCompleted: object;
  constructor() { }

  ngOnInit(): void {
  }
}
