import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-info-button",
  templateUrl: "./info-button.component.html",
  styleUrls: ["./info-button.component.scss"],
})
export class InfoButtonComponent implements OnInit {
  @Input() styleClass: string = "";
  @Output() click = new EventEmitter<Event>();

  faInfoCircle = faInfoCircle;

  constructor() {}

  ngOnInit(): void {}

  onClick(event: Event): void {
    this.click.emit(event);
  }
}
