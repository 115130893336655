<footer class="mainFooter">
  <div>
    <ul class="footerLinksCont">
      <li *ngFor="let item of footerData">
        <a href="{{ item.url }}" class="footerLink link_small" target="_blank">{{ item.urlText }}</a>
      </li>
    </ul>
  </div>
  <div>
    <small class="footerCopyright">© {{ currentYear }} The Sherwin-Williams Company.</small>
  </div>
</footer>
