import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { BaseChildRedirectComponent } from "../base-child-route-redirect-component";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { INavMenu } from "src/app/core/data/models/i-nav-menu.model";
import { SubSink } from "subsink";
import { AppState } from "src/app/shared/services/app-state";
import { faLayerGroup, faUserGroup, faBuilding, faListCheck, faPuzzlePiece, faHammer, faStore, faChartSimple, faMagnifyingGlassChart } from "@fortawesome/free-solid-svg-icons";
import { UiTemplateData } from "src/app/core/data/models/AppInitializationData";
import { AuthContext, AuthenticationService } from "src/app/shared/services/authentication.service";
import { skipWhile } from "rxjs/operators";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent extends BaseChildRedirectComponent implements OnInit, OnDestroy {
  @Output() onClose = new EventEmitter();
  private subs = new SubSink();
  errorMessage: string;
  menuItems$: Observable<INavMenu[]>;
  defaultRouterLink: string[];
  returnButtonLink = ["../jobs"];
  headerText = "Administration";
  adminMenuItems: MenuItem[] = [];

  faLayerGroup = faLayerGroup;
  faUserGroup = faUserGroup;
  faBuilding = faBuilding;
  faListCheck = faListCheck;
  faPuzzlePiece = faPuzzlePiece;
  faHammer = faHammer;
  faStore = faStore;
  faChartSimple = faChartSimple;
  faMagnifyingGlassChart = faMagnifyingGlassChart;
  footerData: UiTemplateData[];
  constructor(
    public appState: AppState,
    public spinner: SpinnerService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected store: Store,
    private authService: AuthenticationService,
  ) {
    super(route, router, store);
  }

  ngOnInit() {
    this.adminMenuItems = this.appState.setAdminMenuItems();
    this.appState.appData$
    .pipe(skipWhile((d) => d === null))
    .subscribe(
      (appData) =>
        (this.footerData = appData.templateData.filter(
          (data: UiTemplateData) =>
            data.urlText === "Terms of Use" ||
            data.urlText === "Accessibility Statement" ||
            data.urlText === "Privacy Policy" ||
            data.urlText === "Do Not Sell My Personal Information"
        ))
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  returnToProjectList() {
    this.router.navigate(["../jobs"], { relativeTo: this.route });
  }
  get claims() {
    if (this.authService.isAuthenticated(AuthContext.Azure)) {
      return this.authService.claims(AuthContext.Azure);
    }
    return false;
  }
  canShowFooter(): boolean {
    return this.footerData.length > 0 && this.claims;
  }

}
