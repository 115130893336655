import { CommonModule, CurrencyPipe, PercentPipe } from "@angular/common";
import { GridComponent } from "./grid/grid.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { NgModule } from "@angular/core";
import { PrimengLibraryModule } from "./libraries/primeng-library.module";
import { SideNavigationComponent } from "./side-navigation/side-navigation.component";
import { ConfirmationService, MessageService, TreeNode, TreeDragDropService } from "primeng/api";
import { SmartBuildService } from "./services/smartbuild.service";
import { WindowRefService } from "./helpers/window-ref.service";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MenuModule } from "primeng/menu";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { SidebarModule } from "primeng/sidebar";
import { TableModule } from "primeng/table";
import { TabViewModule } from "primeng/tabview";
import { DividerModule } from "primeng/divider";
import { AccordionModule } from "primeng/accordion";
import { DragDropModule } from "primeng/dragdrop";
import { ButtonModule } from "primeng/button";
import { TreeSelectModule } from "primeng/treeselect";
import { CheckboxModule } from "primeng/checkbox";
import { RadioButtonModule } from "primeng/radiobutton";
import { TreeModule } from "primeng/tree";
import { DetailSidebarComponent } from "./detail-sidebar/detail-sidebar.component";
import { BadgeComponent } from "./badge/badge.component";
import { StatusBadgeComponent } from "./status-badge/status-badge.component";
import { EmptyStateComponent } from "./empty-state/empty-state.component";
import { AlertComponent } from "./alert/alert.component";
import { CommentInputComponent } from "./comment-input/comment-input.component";
import { CommentListComponent } from "./comment-list/comment-list.component";
import { FileDropInputComponent } from "./file-drop-input/file-drop-input.component";
import { OptionBoxComponent } from "./option-box/option-box.component";
import { StepCardComponent } from "./step-card/step-card.component";
import { ToastComponent } from "./toast/toast.component";
import { MaintenanceComponent } from "./maintenance/maintenance.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { InputTextModule } from "primeng/inputtext";
import { InfoButtonComponent } from "../features/demo/info-button/info-button.component";
import { SafePipe } from "./pipes/safe.pipe";
import {HeaderService} from "./services/header.service";

@NgModule({
  declarations: [
    GridComponent,
    SideNavigationComponent,
    DetailSidebarComponent,
    BadgeComponent,
    StatusBadgeComponent,
    EmptyStateComponent,
    FileDropInputComponent,
    OptionBoxComponent,
    AlertComponent,
    CommentInputComponent,
    CommentListComponent,
    StepCardComponent,
    ToastComponent,
    MaintenanceComponent,
    PaginationComponent,
    SafePipe,
    InfoButtonComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    PrimengLibraryModule,
    FormsModule,
    FlexLayoutModule,
    SidebarModule,
    TableModule,
    MenuModule,
    OverlayPanelModule,
    TabViewModule,
    DividerModule,
    DragDropModule,
    ButtonModule,
    TreeSelectModule,
    CheckboxModule,
    RadioButtonModule,
    AccordionModule,
    TreeModule,
    InputTextModule,
  ],
  exports: [
    GridComponent,
    PrimengLibraryModule,
    SideNavigationComponent,
    ReactiveFormsModule,
    SidebarModule,
    TableModule,
    MenuModule,
    TabViewModule,
    DividerModule,
    OverlayPanelModule,
    AccordionModule,
    DragDropModule,
    ButtonModule,
    FontAwesomeModule,
    TreeSelectModule,
    CheckboxModule,
    RadioButtonModule,
    TreeModule,
    DetailSidebarComponent,
    BadgeComponent,
    StatusBadgeComponent,
    EmptyStateComponent,
    FileDropInputComponent,
    OptionBoxComponent,
    AlertComponent,
    CommentInputComponent,
    CommentListComponent,
    StepCardComponent,
    ToastComponent,
    MaintenanceComponent,
    PaginationComponent,
    SafePipe,
    InfoButtonComponent,
  ],


  providers: [
    PercentPipe,
    CurrencyPipe,
    DynamicDialogComponent,
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    MessageService,
    TreeDragDropService,
    SmartBuildService,
    WindowRefService,
    ConfirmationService,
    HeaderService
  ],
})
export class SharedModule {}
