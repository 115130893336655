export enum EXPECTED_OUTCOME {
  takeOffReport = 1,
  takeOffReportAndEditInSmartBuild = 2,
}

export type RoofingSystem = {
  label: string;
  value: number;
};

export type RoofingSystemInput = {
  defaultValue: number;
  description: string;
  qualifier: string;
  roofingSystemUserInputXRefId: number;
};
