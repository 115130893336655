import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { AccountSettingsComponent } from "./account-settings.component";
import { ProfileComponent } from "./profile/profile.component";
import { SecurityComponent } from "./security/security.component";
import { CompaniesComponent } from "./companies/companies.component";
import { IntegrationsComponent } from "./integrations/integrations.component";
import {SetupGuard} from "../../setup.guard";

const AccountSettingsRoutes: Routes = [
  {
    path: "account-settings",
    component: AccountSettingsComponent,
    canActivate: [SetupGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "profile",
      },
      {
        path: "profile",
        title: "Profile - Account settings - Roofing Passport",
        component: ProfileComponent,
      },
      {
        path: "security",
        title: "Security - Account settings - Roofing Passport",
        component: SecurityComponent,
      },
      {
        path: "companies",
        title: "Companies - Account settings - Roofing Passport",
        component: CompaniesComponent,
      },
      {
        path: "integrations",
        title: "Integrations - Account settings - Roofing Passport",
        component: IntegrationsComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(AccountSettingsRoutes)],
  exports: [RouterModule],
})
export class AccountSettingsRoutingModule {}
