import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faDownload, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ApiEndpoints } from "src/app/shared/constants/api-endpoints";
import { MessageService } from "primeng/api";
import { AppState } from "src/app/shared/services/app-state";

@Component({
  selector: "app-create-job-success",
  templateUrl: "./create-job-success.component.html",
  styleUrls: ["./create-job-success.component.scss"],
})
export class CreateJobSuccessComponent implements OnInit {
  faCircleCheck = faCircleCheck;
  faDownload = faDownload;
  faCircleInfo = faCircleInfo;
  demoUserEmail: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private messageService: MessageService,
    private appState: AppState
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.demoUserEmail = params["email"];

      if (!this.demoUserEmail) {
        this.router.navigate(["/demo"]);
        return;
      }
    });
  }

  tryAgain(): void {
    this.router.navigate(["/demo/create-job"], {
      queryParams: { email: this.demoUserEmail },
    });
  }

  downloadTakeoffReport(): void {
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.downloadTakeoffReport}`;
    this.http.get(url, { responseType: "blob" }).subscribe(
      (response: Blob) => {
        const blobUrl = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "takeoff_report.pdf";
        link.click();
        window.URL.revokeObjectURL(blobUrl);
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Download Error",
          detail: error instanceof HttpErrorResponse ? error.message : "Error downloading takeoff report",
        });
      }
    );
  }
}
