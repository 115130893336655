import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faPeopleGroup, faQuestionCircle, faPlus, faUserGroup, faDiagramProject, faGlobeAmericas, faBuilding, faCheck, faGripVertical, faChevronDown, faChevronUp, faPen, faEllipsisV, faTrash, faUpDownLeftRight, faToggleOff, faToggleOn, faSave } from "@fortawesome/free-solid-svg-icons";

export interface User {
  id;
  name;
  role;
  lastSeen;
}

@Component({
  selector: "app-edit-company-structure",
  templateUrl: "./edit-company-structure.component.html",
  styleUrls: ["./edit-company-structure.component.scss"],
  providers: [],
})

export class EditCompanyStructureComponent implements OnInit {
  @Input() showAdvancedView: boolean;

  nodes: any[];
  placeholderUsers: any[];
  selectedUsers: User[]
  placeholderOptions: any[] = [{ name: 'Lorem ipsum' }, { name: 'Dolor sit amet' },];
  displayNodeDialog: boolean = false;
  displayUsersDialog: boolean = false;
  displayMoveUsersDialog: boolean = false;
  nodeDialogIsEditing: boolean = false;
  moveDialogIsMultiple: boolean = false;
  structureForm: FormGroup = this.fb.group({
    name: ["", [Validators.required, Validators.maxLength(100)]],
    street: [""],
    city: [""],
    postalCode: [""],
    state: [""],
    website: [""],
    contactName: [""],
    contactEmail: [""],
    contactPhone: [""],
  });
  nodeForm: FormGroup = this.fb.group({
    name: ["", [Validators.required, Validators.maxLength(100)]],
    street: [""],
    city: [""],
    postalCode: [""],
    state: [""],
  });

  get name() {
    return this.structureForm.get("name");
  }
  get street() {
    return this.structureForm.get("street");
  }
  get city() {
    return this.structureForm.get("city");
  }
  get state() {
    return this.structureForm.get("state");
  }
  get postalCode() {
    return this.structureForm.get("postalCode");
  }
  get website() {
    return this.structureForm.get("website");
  }
  get contactName() {
    return this.structureForm.get("contactName");
  }
  get contactEmail() {
    return this.structureForm.get("contactEmail");
  }
  get contactPhone() {
    return this.structureForm.get("contactPhone");
  }
  faPeopleGroup = faPeopleGroup;
  faQuestionCircle = faQuestionCircle;
  faPlus = faPlus;
  faUserGroup = faUserGroup;
  faDiagramProject = faDiagramProject;
  faGlobeAmericas = faGlobeAmericas;
  faBuilding = faBuilding;
  faCheck = faCheck;
  faGripVertical = faGripVertical;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faPen = faPen;
  faEllipsisV = faEllipsisV;
  faTrash = faTrash;
  faUpDownLeftRight = faUpDownLeftRight;
  faToggleOff = faToggleOff;
  faToggleOn = faToggleOn;
  faSave = faSave;

  constructor(private fb: FormBuilder) {

  }

  ngOnInit() {
    this.nodes = [
      {
        id: '1',
        label: 'Lorem ipsum',
        type: 'node',
        nodeType: 'company',
        status: 'active',
        billable: false,
        expanded: true,
        children: [
          {
            id: '2',
            label: 'Lorem ipsum',
            type: 'node',
            nodeType: 'region',
            status: 'active',
            billable: false,
            expanded: true,
            children: [
              {
                id: '3',
                label: 'Lorem ipsum',
                type: 'node',
                nodeType: 'branch',
                status: 'active',
                billable: true,
                children: [
                  {
                    type: 'usersTable',
                    draggable: false,
                    droppable: false,
                    data: [
                      {
                        id: '1',
                        name: 'John Doe',
                        role: 'normal',
                        lastSeen: '12/3/2024',
                      },
                      {
                        id: '2',
                        name: 'Jane Doe',
                        role: 'salesperson',
                        lastSeen: '12/3/2024',
                      },
                    ],
                  },
                ],
              },
              {
                id: '4',
                label: 'Lorem ipsum',
                type: 'node',
                nodeType: 'branch',
                status: 'inactive',
                billable: false,
                children: [
                  {
                    type: 'usersTable',
                    draggable: false,
                    droppable: false,
                    data: [
                      {
                        id: '1',
                        name: 'John Doe',
                        role: 'normal',
                        lastSeen: '12/3/2024',
                      },
                      {
                        id: '2',
                        name: 'Jane Doe',
                        role: 'salesperson',
                        lastSeen: '12/3/2024',
                      },
                    ],
                  },
                ],
              },
              {
                id: '5',
                label: 'Lorem ipsum',
                type: 'node',
                nodeType: 'branch',
                status: 'active',
                billable: false,
                children: [
                  {
                    type: 'usersTable',
                    draggable: false,
                    droppable: false,
                    data: [
                      {
                        id: '1',
                        name: 'John Doe',
                        role: 'normal',
                        lastSeen: '12/3/2024',
                      },
                      {
                        id: '2',
                        name: 'Jane Doe',
                        role: 'salesperson',
                        lastSeen: '12/3/2024',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'newNode',
              },
            ],
          },
          {
            id: '6',
            label: 'Lorem ipsum',
            type: 'node',
            nodeType: 'branch',
            status: 'active',
            billable: true,
            children: [
              {
                type: 'usersTable',
                draggable: false,
                droppable: false,
                data: [
                  {
                    id: '1',
                    name: 'John Doe',
                    role: 'normal',
                    lastSeen: '12/3/2024',
                  },
                  {
                    id: '2',
                    name: 'Jane Doe',
                    role: 'salesperson',
                    lastSeen: '12/3/2024',
                  },
                ],
              },
            ],
          },
          {
            id: '7',
            label: 'Lorem ipsum',
            type: 'node',
            nodeType: 'branch',
            status: 'active',
            billable: false,
          },
          {
            type: 'newNode',
          },
        ],
      },
    ];

    this.placeholderUsers = [
      {
        id: '1',
        name: 'John Doe',
        role: 'normal',
        lastSeen: '12/3/2024',
      },
      {
        id: '2',
        name: 'Jane Doe',
        role: 'salesperson',
        lastSeen: '12/3/2024',
      },
    ];
  }

  showNodeDialog(nodeId?: number) {
    this.displayNodeDialog = true;

    if (nodeId) this.nodeDialogIsEditing = true;
    else this.nodeDialogIsEditing = false;
  }

  showUsersDialog() {
    this.displayUsersDialog = true;
  }

  showMoveUsersDialog(nodes: []) {
    this.displayMoveUsersDialog = true;

    if (nodes.length > 1) this.moveDialogIsMultiple = true;
    else this.moveDialogIsMultiple = false;
  }

  getNodeIcon(nodeType: string) {
    switch (nodeType) {
      case 'company': {
        return this.faBuilding;
      }
      case 'region': {
        return this.faGlobeAmericas;
      }
      case 'branch': {
        return this.faDiagramProject;
      }
      default: {
        return this.faDiagramProject;
      }
    }
  }

  getNodeStatus(nodeStatus: string, nodeBillable: boolean) {
    if (nodeBillable) {
      return { color: 'main', text: 'Billable' };
    }
    switch (nodeStatus) {
      case 'active': {
        return { color: 'success', text: 'Active' };
      }
      case 'inactive': {
        return { color: 'danger', text: 'Inactive' };
      }
    }
  }

  toggleNode(node) {
    node.expanded = !node.expanded;
  }
}
