<div [ngClass]="{
    'main': color === 'main',
    'success': color === 'success',
    'warning': color === 'warning',
    'danger': color === 'danger',
    'mono': color === 'mono'
  }" class="alert {{styleClass}}">
  <fa-icon *ngIf="icon" [icon]="icon" class="alertIcon"></fa-icon>
  <div class="alertContent">
    <div class="alertText">
      <p *ngIf="label" class="alertText__title">{{ label }}</p>
      <p *ngIf="description" class="alertText__description">{{ description }}</p>
    </div>
    <div class="alertExtraContent">
      <ng-content></ng-content>
    </div>
  </div>
</div>
