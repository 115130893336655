<div class="grid align-items-end">
    <div class="col-5">
        <label for="startDate" class="mr-2">Start Date:</label>
        <p-calendar [(ngModel)]="startDate" dateFormat="mm.dd.yy" class="full-width-calendar" [disabledDays]="[0,6]"
            [minDate]="maxDate" (onClose)="onDateChange($event)"></p-calendar>
    </div>
    <div class="col-5">
        <label for="endDate" class="mr-2">End Date:</label>
        <p-calendar [(ngModel)]="endDate" dateFormat="mm.dd.yy" class="full-width-calendar" [disabledDays]="[0,6]"
            [minDate]="minDate"></p-calendar>
    </div>
    <div class="col-2">
        <button pButton class="main" (click)="applyDateFilter()">Filter</button>
    </div>
</div>
<div style="max-width: calc(100vw - 330px);">
    <p-table #CompaniesByUserTable styleClass="p-datatable p-datatable-striped" [value]="listByCompany"
        [scrollable]="true" [rows]="10" [rowHover]="true" [loading]="isLoading" scrollHeight="700px">
        <ng-template pTemplate="header" let-orderComputer>
            <tr>
                <th>Company Name</th>
                <th>Eagleview</th>
                <th>Roofing WRX</th>
                <th>XML</th>
                <th>Eagleview %</th>
                <th>Roofing WRX %</th>
                <th>XML %</th>
                <th>Total</th>
                <th *ngFor="let col of displayedColumnsByCompany"> {{ col }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-orderComputer>
            <tr>
                <td>{{orderComputer.companyName}}</td>
                <td>{{orderComputer.eagleViewOrders}}</td>
                <td>{{orderComputer.roofingWRXOrders}}</td>
                <td>{{orderComputer.xmlOrders}}</td>
                <td>{{orderComputer.eagleViewOrderPercent}}</td>
                <td>{{orderComputer.roofingWrxOrderPercent}}</td>
                <td>{{orderComputer.xmlOrderPercent}}</td>
                <td>{{orderComputer.totalOrders}}</td>
                <td *ngFor="let column of displayedColumnsByCompany"> {{orderComputer[column]}}</td>
            </tr>
        </ng-template>
    </p-table>
    <div *ngIf="listByCompany.length === 0"><empty-state label="No orders found"
        description="There are no order for the selected timeframe."></empty-state>
    </div>
</div>