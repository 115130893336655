import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-create-job",
  templateUrl: "./create-job.component.html",
  styleUrls: ["./create-job.component.scss"],
})
export class CreateJobComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
