import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { SpinnerService } from "../spinner/spinner.service";
import { ApiEndpoints } from "src/app/shared/constants/api-endpoints";
import { AppState } from "src/app/shared/services/app-state";

interface InterceptedRequest {
  url: string;
  method: string;
  noSpinner?: boolean;
  loadingText?: string;
  hasParams?: boolean;
}

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private countRequest = 0;
  private notableRequests: InterceptedRequest[] = this.getNotableRequests();

  constructor(
    private spinner: SpinnerService,
    private appState: AppState
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const intercepted: InterceptedRequest = this.notableRequests.find(
      (s) =>
        (`${this.appState?.configs?.ServicesBaseUrl}${s.url}` === request.url ||
          (s.hasParams && request.url.includes(`${this.appState?.configs?.ServicesBaseUrl}${s.url}`))) &&
        s.method === request.method
    );

    if (intercepted?.noSpinner) {
      return next.handle(request);
    }

    if (!this.countRequest) {
      this.spinner.show(intercepted?.loadingText);
    }

    this.countRequest++;

    return next.handle(request).pipe(
      finalize(() => {
        this.countRequest--;
        if (!this.countRequest) {
          this.spinner.hide();
        }
      })
    );
  }

  private getNotableRequests(): InterceptedRequest[] {
    return [
      {
        url: ApiEndpoints.getProjectList,
        noSpinner: true,
        method: "GET",
      },
      {
        url: ApiEndpoints.getIPASignature(),
        noSpinner: true,
        method: "GET",
        hasParams: true,
      },
      {
        url: ApiEndpoints.createInvitation,
        loadingText: "Creating invitation...",
        method: "POST",
      },
      {
        url: ApiEndpoints.resendRPInvitation,
        loadingText: "Resending invitation...",
        method: "POST",
      },
      {
        url: ApiEndpoints.updateTrainingStatus,
        loadingText: "Saving user changes...",
        method: "POST",
      },
      {
        url: ApiEndpoints.completeRegistration,
        loadingText: "Completing registration...",
        method: "POST",
      },
      {
        url: ApiEndpoints.processNewOrder,
        loadingText: "Creating job...",
        method: "POST",
      },
      {
        url: ApiEndpoints.getSmartBuildReport(),
        loadingText: "Fetching SB report file...",
        method: "GET",
        hasParams: true,
      },
      {
        url: ApiEndpoints.getEagleViewReportFile(),
        loadingText: "Downloading image...",
        method: "GET",
        hasParams: true,
      },
      {
        url: ApiEndpoints.getEagleViewPremiumReport(),
        loadingText: "Fetching premium PDF...",
        method: "GET",
        hasParams: true,
      },
      {
        url: ApiEndpoints.downloadUserGuide,
        loadingText: "Fetching user guide...",
        method: "GET",
      },
      {
        url: ApiEndpoints.downloadAdminUserGuide,
        loadingText: "Fetching user guide...",
        method: "GET",
      },
      {
        url: ApiEndpoints.createInvitation,
        loadingText: "Creating invitation...",
        method: "POST",
      },
      {
        url: ApiEndpoints.updateRoofingWRXJobComments(),
        loadingText: "Updating comments...",
        method: "POST",
        hasParams: true,
      },
      {
        url: ApiEndpoints.uploadRoofingWRXJobFiles(),
        loadingText: "Uploading files...",
        method: "POST",
        hasParams: true,
      },
      {
        url: ApiEndpoints.getRoofingWRXJobInfo(),
        loadingText: "Fetching WRX job info...",
        method: "GET",
        hasParams: true,
      },
      {
        url: ApiEndpoints.getRoofingWRXReportFile(),
        loadingText: "Downloading file...",
        method: "GET",
        hasParams: true,
      },
      {
        url: ApiEndpoints.downloadUserReport(),
        loadingText: "Downloading user report...",
        method: "GET",
        hasParams: true,
      },
    ];
  }
}
