<app-header *ngIf="claims && !isDemo" [claims]="claims" (logout)="logout()" (unlinkAll)="unlinkAll()"></app-header>
<app-demo-header *ngIf="isDemo"></app-demo-header>

<div class="generalCont">
  <div class="mainCont">
    <router-outlet *ngIf="showRouterOutput"></router-outlet>
  </div>
  <detail-sidebar #sidebar [isLeft]="false" class="detailSidebar"></detail-sidebar>
</div>

<app-toast></app-toast>
<roofing-passport-confirm></roofing-passport-confirm>
<roofing-passport-spinner></roofing-passport-spinner>
