<div class="container_fluid">
  <div class="pageHeading">
    <h2 class="title2">Integrations</h2>
  </div>
  <div>
    <p class="text-sm max-w-36rem mb-3">
      These are your currently available integrations. Integrations are third-party tools you can link to Roofing
      Passport in order to access additional features.
    </p>
    <app-third-party-integrations [services]="services" (mapServices)="mapServices()"></app-third-party-integrations>
    <div class="flex justify-content-end my-4">
      <button *ngIf="unlinkAllAccounts | async" pButton type="button" class="outline danger small"
              (click)="logoutAll()">Unlink all accounts
      </button>
    </div>
  </div>
</div>
