import { Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import {
  faUserGroup,
  faBuilding,
  faListCheck,
  faPuzzlePiece,
  faLayerGroup,
  faHammer,
  faStore,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-company-settings",
  templateUrl: "./company-settings.component.html",
  styleUrls: ["./company-settings.component.scss"],
})
export class CompanySettingsComponent implements OnInit {
  companySettingsMenuItems: MenuItem[] = [];
  headerText = "Company Settings";
  constructor() {}

  ngOnInit(): void {
    this.setMenuItems();
  }

  setMenuItems() {
    this.companySettingsMenuItems.push(
      {
        label: "Work Queue",
        routerLink: "./work-queue",
        iconStyle: faLayerGroup,
      },
      {
        label: "Company",
        routerLink: "./company",
        iconStyle: faBuilding,
      },
      {
        label: "Users",
        routerLink: "./users",
        iconStyle: faUserGroup,
      },
      {
        label: "Jobs",
        routerLink: "./jobs",
        iconStyle: faHammer,
      },
      {
        label: "Integrations",
        routerLink: "./integrations",
        iconStyle: faPuzzlePiece,
      },
      {
        label: "Reports",
        routerLink: "./reports",
        iconStyle: faListCheck,
      }
    );
  }
}
