import { Component, Input, OnInit } from "@angular/core";
import { faPaperPlane, faWarning } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "comment-input",
  templateUrl: "./comment-input.component.html",
  styleUrls: ["./comment-input.component.scss"],
})
export class CommentInputComponent implements OnInit {
  @Input() submitComment: (comment: string) => void;
  @Input() image: string = "assets/img/placeholder/userProfilePicPlaceholder.png";
  @Input() placeholder: string = "Add a new note";

  comment: string = "";

  faPaperPlane = faPaperPlane;
  faWarning = faWarning;

  constructor() {}

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.comment.trim()) {
      this.submitComment(this.comment);
      this.comment = "";
    }
  }

  isCommentEmpty(): boolean {
    return this.comment.trim().length === 0;
  }
}
