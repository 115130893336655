import { Component } from "@angular/core";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: [],
})
export class UserProfileComponent {
  constructor() {}
}
