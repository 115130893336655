import { Component, Input, OnInit } from "@angular/core";
import { faEllipsisV, faBan, faTrash } from "@fortawesome/free-solid-svg-icons";
import { JobComment } from "src/app/core/store/app.models";

@Component({
  selector: "comment-list",
  templateUrl: "./comment-list.component.html",
  styleUrls: ["./comment-list.component.scss"],
})
export class CommentListComponent implements OnInit {
  @Input() comments: JobComment[] = [];

  faEllipsisV = faEllipsisV;
  faBan = faBan;
  faTrash = faTrash;

  constructor() {}

  ngOnInit(): void {
    this.sortComments();
  }

  sortComments() {
    this.comments = this.comments.sort(
      (a: JobComment, b: JobComment) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }
}
