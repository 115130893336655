import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {AppState} from './shared/services/app-state';
import {ProfileStatus} from './core/data/models/AppInitializationData';
import {HeaderService} from "./shared/services/header.service";
import {Header} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class SetupGuard implements CanActivate {

  constructor(
    private headerService: HeaderService,
    private appState: AppState,
    private router: Router,
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.headerService.isSelectingCompany.subscribe(isSelecting => {
      if (isSelecting) {
        return this.router.createUrlTree(['/select-company']);
      }
    });

    return this.appState.profile$.pipe(
      map(profile => (profile.status === ProfileStatus.Accepted) || this.router.createUrlTree(['/register-account'])),
      catchError(() => of(this.router.createUrlTree(['/register-account'])))
    );
  }
}
