import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppState } from "src/app/shared/services/app-state";
import { faTableCellsLarge, faList, faChevronDown, faDiagramProject, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ActivatedRoute } from "@angular/router";
import { Company } from "../admin-users/edit-user/edit-user.models";

@Component({
  selector: "app-edit-company",
  templateUrl: "./edit-company.component.html",
  styleUrls: ["./edit-company.component.scss"],
})
export class EditCompanyComponent implements OnInit {
  company: Company;
  @Output() onCancel = new EventEmitter();

  isRPAdmin = this.appState.isAdminOrRPAdmin;
  structureViewOption: string;
  tabIndex: number;

  faTableCellsLarge = faTableCellsLarge;
  faList = faList;
  faChevronDown = faChevronDown;
  faDiagramProject = faDiagramProject;
  faPlus = faPlus;

  constructor(
    private appState: AppState,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.appState.CompanySelection.subscribe(data=>{
      this.company = data;
    })
    this.route.paramMap.subscribe(params => {
      this.tabIndex = +params.get('index');
    });

  }

  onTabChange(e) {
    this.tabIndex = e.index;
  }
}
