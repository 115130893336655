<div class="flex flex-column">
  <h2 class="title4 pb-3">Verify your email</h2>
  <div class="flex flex-row gap-4">
    <div class="circle primary">
      <fa-icon [icon]="faEnvelope"></fa-icon>
    </div>
    <div class="verificationContent">
      <p>Before continuing, we need to verify your email address.</p>
      <p class="mt-4">
        We just sent you a confirmation message to <span class="fontW_semibold">john.doe@company.com</span> with a verification link. Check your
        inbox for our message and follow the instructions there.
      </p>
      <button pButton class="link main mt-4 mb-4" (click)="toggleHelp()">Didn’t receive an email?</button>
      <div [ngClass]="{ 'hidden' : !displayHelp }">
        <p>Try doing the following:</p>
        <ul>
          <li class="mb-2">Check your email’s spam folder.</li>
          <li class="mb-2">
            <button pButton class="link main" (click)="resendEmail()">Resend</button> the email.
          </li>
          <li class="mb-2">Is your email properly written? If not, you can <button pButton class="default link" type="button" (click)="onGoBackClick()">Go back</button> and correct it.</li>
          <li class="mb-2">Still having trouble? <a href="mailto:support-roofingpassport@sherwin.com" pButton class="link main">Contact us</a></li>
        </ul>
      </div>
    </div>
  </div>

  <section class="w-full pt-3">
    <button pButton class="default outline fullMobile" type="button" (click)="onGoBackClick()">
      <fa-icon [icon]="faArrowLeft"></fa-icon>
      Go back
    </button>
  </section>
</div>
