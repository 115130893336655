<app-toast></app-toast>

<div class="container_fluid">
  <div class="pageHeading">
    <div>
      <p *ngIf="isRPAdmin" class="subtitle mb-2">Company Inc.</p>
      <h2 class="title2">Company</h2>
    </div>
    <div *ngIf="tabIndex === 1" class="flex md:align-items-center gap-3 flex-row flex-wrap">
      <div>
        <button class="unstyledButton billableBranchesBtn" (click)="billableBranchesList.toggle($event)">
          <span class="statusDot main"></span>
          2 billable branches
          <fa-icon [icon]="faChevronDown" class="text-xs"></fa-icon>
        </button>
        <p-overlayPanel #billableBranchesList [appendTo]="'body'">
          <ng-template pTemplate>
            <ul class="dropdownMenu">
              <li>
                <button class="dropdownButton">
                  <fa-icon [icon]="faDiagramProject" class="dropdownButton__icon"></fa-icon>
                  <span>Branch ABC</span>
                </button>
              </li>
              <li>
                <button class="dropdownButton">
                  <fa-icon [icon]="faDiagramProject" class="dropdownButton__icon"></fa-icon>
                  <span>Branch DEF</span>
                </button>
              </li>
            </ul>
          </ng-template>
        </p-overlayPanel>
      </div>
      <fieldset class="flex align-items-center">
        <legend class="srOnly">View:</legend>
        <span class="text-xs mr-2" aria-hidden="true">View:</span>
        <div>
          <input type="radio" id="structureViewCheck_simple" name="structureView" [value]="false" [(ngModel)]="structureViewOption" class="srOnly" [checked]="true">
          <label for="structureViewCheck_simple" pButton class="square" [ngClass]="{ 'outline': structureViewOption }">
            <fa-icon [icon]="faTableCellsLarge"></fa-icon>
            <span class="srOnly">Simple</span>
          </label>
        </div>
        <div>
          <input type="radio" id="structureViewCheck_adv" name="structureView" [value]="true" [(ngModel)]="structureViewOption" class="srOnly">
          <label for="structureViewCheck_adv" pButton class="square" [ngClass]="{ 'outline': !structureViewOption }">
            <fa-icon [icon]="faList"></fa-icon>
            <span class="srOnly">Advanced (List)</span>
          </label>
        </div>
      </fieldset>
    </div>
    <div *ngIf="tabIndex === 2">
      <a routerLink="../affiliation/new" pButton>
        <fa-icon [icon]="faPlus"></fa-icon>
        Add affiliation
      </a>
    </div>
  </div>

  <!-- Edit -->
  <p-tabView *ngIf="company" (onChange)="onTabChange($event)" [(activeIndex)]="tabIndex">
    <p-tabPanel header="Profile">
      <app-edit-company-profile></app-edit-company-profile>
    </p-tabPanel>
    <p-tabPanel header="Structure">
      <app-edit-company-structure [showAdvancedView]="structureViewOption"></app-edit-company-structure>
    </p-tabPanel>
    <p-tabPanel header="Affiliations">
      <app-affiliations></app-affiliations>
    </p-tabPanel>
  </p-tabView>

  <!-- New -->
  <app-edit-company-profile *ngIf="!company"></app-edit-company-profile>
</div>
