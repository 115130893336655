import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiEndpoints } from "../constants/api-endpoints";
import { AppState } from "./app-state";
import { AuthenticationService } from "./authentication.service";
import { AdminTask } from "src/app/core/data/models/AdminTask";
import { ISortableRequest } from "src/app/interfaces/sortable-request";

@Injectable({
  providedIn: "root",
})
export class TaskService {
  constructor(
    private http: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService
  ) {}

  getTasks( pageIndex?: number, pageSize?: number,request?:ISortableRequest): Observable<AdminTask[]> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getTasks}`;
    let params = new HttpParams();
    params = params.append("SortBy", request.SortBy);
    params = params.append("SortDescending", request.SortDescending);
    return this.http.get<AdminTask[]>(url, { headers , params });
  }

  completeTask(task: AdminTask): Observable<boolean> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.completeTask}`;

    return this.http.post<boolean>(url, task, { headers });
  }
}