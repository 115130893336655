import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { faUpload, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'file-drop-input',
  templateUrl: './file-drop-input.component.html',
  styleUrls: ['./file-drop-input.component.scss']
})
export class FileDropInputComponent implements OnInit {

  faUpload = faUpload;
  faTimes = faTimes;

  @Input() id: number;
  @Input() model: string;
  @Input() name: string;
  @Input() accept: string;
  @Input() acceptText: string;
  @Input() multiple: boolean = false;
  @Input() disabled: boolean = false;
  @Input() fileList: any[];
  @Input() label: string;
  @Input() styleClass: string;
  @Input() change = new EventEmitter();
  @Input() drop = new EventEmitter();
  @Output() removeFile = new EventEmitter<number>();

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  onChange(event: Event) {
    this.change.emit(event);
  }

  onDrop(event: Event) {
    this.drop.emit(event);
  }

  onRemoveFile(index: number) {
    this.removeFile.emit(index);
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }
}
