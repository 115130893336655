import { Component, OnInit } from "@angular/core";
import { RegisterStep } from "./types";
import { Step } from "src/app/core/steps/types";

@Component({
  selector: "app-register-account",
  templateUrl: "./register-account.component.html",
  styleUrls: [],
})
export class RegisterAccountComponent implements OnInit {
  isInvited: boolean = true;
  emailInvited: string = "lorem@ipsum.com";
  currentStep: RegisterStep = RegisterStep.WELCOME;
  RegisterStep = RegisterStep;
  steps: Step<RegisterStep>[] = [];

  constructor() {}

  ngOnInit(): void {
    this.setupSteps();
  }

  setupSteps() {
    if (this.isInvited) {
      this.steps = [
        { id: RegisterStep.TERMS, label: "Terms" },
        { id: RegisterStep.INTEGRATIONS, label: "Integrations" },
        { id: RegisterStep.SUMMARY, label: "Summary" },
      ];
    } else {
      this.steps = [
        { id: RegisterStep.TERMS, label: "Terms" },
        { id: RegisterStep.DETAILS, label: "Details" },
        { id: RegisterStep.VERIFICATION, label: "Verification" },
      ];
    }
  }

  goToStep(step: RegisterStep) {
    this.currentStep = step;
  }

  shouldShowSteps(): boolean {
    return this.currentStep !== RegisterStep.WELCOME && this.currentStep !== RegisterStep.CONFIRM;
  }
}
