import { Component, EventEmitter, Output } from "@angular/core";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ThirdPartyAuth } from "src/app/core/store/app.models";
import { Service, SERVICE_DETAILS } from "src/app/core/third-party-integrations/service-details";
import { AppState } from "src/app/shared/services/app-state";

@Component({
  selector: "app-integrations",
  templateUrl: "./integrations.component.html",
  styleUrls: ["./integrations.component.scss"],
})
export class IntegrationsComponent {
  @Output() nextClicked = new EventEmitter<void>();
  @Output() goBackClicked = new EventEmitter<void>();

  isEagleviewLinked: boolean = false;
  isRoofingwrxLinked: boolean = false;
  isSmartbuildLinked: boolean = false;
  serviceStatus = this.appState.serviceStatus;
  services: Service[] = [];

  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;

  constructor(private appState: AppState) {}

  mapServices(): void {
    this.services = this.serviceStatus.map((service) => {
      const details = SERVICE_DETAILS[service.service];
      const auth: ThirdPartyAuth = this.appState.authorizations.find(
        (p) => p.context.toString() == service.service.toString()
      );
      return {
        ...service,
        ...details,
        ...auth,
      };
    });
  }

  ngOnInit(): void {
    this.mapServices();
  }

  onNextClick() {
    this.nextClicked.emit();
  }

  onGoBackClick() {
    this.goBackClicked.emit();
  }
}
