<p-header>
  <h2 class="font-light text-2xl">How data providers work</h2>
</p-header>
<p class="text-sm mt-3 mb-4">Visualize how we create your takeoff report:</p>

<div class="flex flex-column align-items-center mb-7rem">
  <div class="border-1 border-main mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold">
    <img src="assets/img/logo/rfLogo_icon.svg" alt="Roofing Passport" class="w-2rem" />
    <span class="text-sm ml-4">Roofing Passport</span>
  </div>
</div>

<p-divider></p-divider>
<p class="text-sm">
  Using <span class="font-semibold">XML</span> has the advantages of being compatible with different sources and
  requiring no third party integrations.
</p>
<p class="text-sm">
  All data is processed within Roofing Passport, which results in quicker processing times and no extra fees.
</p>
<div class="flex justify-content-between mt-4">
  <div class="ml-auto">
    <button pButton (click)="onClose()">continue</button>
  </div>
</div>
