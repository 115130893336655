import {Component, OnInit, Inject, LOCALE_ID} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ParamMap, Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {catchError, map, tap} from "rxjs/operators";
import {BehaviorSubject, EMPTY, Observable} from "rxjs";
import {AppState} from "src/app/shared/services/app-state";
import {SpinnerService} from "src/app/core/spinner/spinner.service";
import {ProfileService} from "src/app/shared/services/profile.service";
import {AdminService} from "src/app/shared/services/admin.service";
import {ConfirmService} from "src/app/core/confirm/confirm.service";
import {type ManufacturerInvitation} from "src/app/core/data/models/ManufacturerInvitation";
import {UserRole} from "./edit-user.models";
import {MessageService} from "primeng/api";
import {
  faEnvelope,
  faToggleOn,
  faToggleOff,
  faTrash,
  faCheck,
  faTimes,
  faCrown,
  faQuestion,
  faSave,
  faInfoCircle,
  faBriefcase,
  faBuilding,
  faHandshakeAlt,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import {GetUserResponse} from "../../../../core/data/models/GetUserResponse";
import {UpdateUserRequest} from "../../../../core/data/models/UpdateUserRequest";
import {AuthenticationService} from "../../../../shared/services/authentication.service";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"],
})
export class EditUserComponent implements OnInit {
  isRPAdmin = this.appState.isAdminOrRPAdmin;
  userId: number;
  user: GetUserResponse;
  userForm: FormGroup;
  originalValues: GetUserResponse;
  private _profileStatus: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public profileStatus: Observable<string> = this._profileStatus.asObservable();
  UserRole = UserRole;

  faEnvelope = faEnvelope;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;
  faTrash = faTrash;
  faCheck = faCheck;
  faTimes = faTimes;
  faCrown = faCrown;
  faQuestion = faQuestion;
  faSave = faSave;
  faInfoCircle = faInfoCircle;
  faBriefcase = faBriefcase;
  faBuilding = faBuilding;
  faHandshakeAlt = faHandshakeAlt;
  faScrewdriverWrench = faScrewdriverWrench;

  constructor(
    private route: ActivatedRoute,
    private appState: AppState,
    public spinner: SpinnerService,
    private adminService: AdminService,
    private profileService: ProfileService,
    private confirmService: ConfirmService,
    private messageService: MessageService,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    @Inject(LOCALE_ID) public locale: string
  ) {
  }

  fetchUserData(userId: number) {
    this.adminService.getUser(userId).subscribe((user) => {
      this.user = user;
      this.patchForm(user);
      this.originalValues = {...this.userForm.value}
    });
  }

  setupForm(userId: number) {
    this.userForm = this.fb.group({
      userId: [{value: userId}, Validators.required],
      userProfileId: [{value: null, disabled: !this.isRPAdmin}],
      userProfileCompanyId: [{value: "", disabled: !this.isRPAdmin}],
      invited: [{value: null, disabled: true}],
      invitationRedeemed: [{value: null, disabled: true}],
      licenseAgreement: [{value: null, disabled: true}],
      completion: [{value: null, disabled: true}],
      roofingWRX: [{value: null, disabled: true}],
      eagleView: [{value: null, disabled: true}],
      smartBuild: [{value: null, disabled: true}],
      invitedBy: [{value: "", disabled: true}],
      firstName: [{value: "", disabled: !this.isRPAdmin}, [Validators.required, Validators.maxLength(50)]],
      lastName: [{value: "", disabled: !this.isRPAdmin}, [Validators.required, Validators.maxLength(50)]],
      email: [{value: "", disabled: true}, [Validators.required, Validators.email, Validators.maxLength(50)]],
      phone: [{value: "", disabled: !this.isRPAdmin}, [Validators.required, Validators.maxLength(50)]],
      userProfileCompanyName: [{value: "", disabled: true}],
      status: [{value: "", disabled: !this.isRPAdmin}],
      isActive: [{value: false, disabled: !this.isRPAdmin}],
      inviteSent: [{value: false, disabled: true}],
      inviteAccepted: [{value: false, disabled: true}],
      relatedCompanies: [[]],
      userRoles: [[]],
    });

    this.originalValues = {...this.userForm.value};
  }

  patchForm(user: GetUserResponse) {
    this.userForm.patchValue({
      userId: user.userId,
      userProfileId: user.userProfileId,
      userProfileCompanyId: user.userProfileCompanyId,
      invited: user.invited,
      invitationRedeemed: user.invitationRedeemed,
      licenseAgreement: user.licenseAgreement,
      completion: user.completion,
      roofingWRX: user.roofingWRX,
      eagleView: user.eagleView,
      smartBuild: user.smartBuild,
      invitedBy: user.invitedBy,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      userProfileCompanyName: user.userProfileCompanyName,
      status: user.status,
      isActive: user.isActive,
      inviteSent: user.inviteSent,
      inviteAccepted: user.inviteAccepted,
      relatedCompanies: user.relatedCompanies,
    });

    this._profileStatus.next(this.user.status);

    this.userForm.markAsPristine();
    this.userForm.markAsUntouched();

    if (user) {
      this.userForm.markAllAsTouched();
    }
  }

  initializeUser() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const userId = parseInt(params.get("id"));
      if (userId) {
        this.setupForm(userId);
        this.fetchUserData(userId);
      }
    });
  }

  ngOnInit(): void {
    this.initializeUser();
  }

  redirectToSelectCompany() {
    this.appState.redirectToSelectCompany();
  }

  redirectToUsers() {
    this.appState.redirectToUsers();
  }

  isUserInactive(): boolean {
    return this.userForm.get("status").value === "Inactive";
  }

  resendRPInvitation() {
    const invite: ManufacturerInvitation = {
      companyId: this.userForm.get("userProfileCompanyId").value,
      firstName: this.userForm.get("firstName").value,
      lastName: this.userForm.get("lastName").value,
      companyName: this.userForm.get("userProfileCompanyName").value,
      contactPhoneNumber: this.userForm.get("phone").value,
      email: this.userForm.get("email").value,
    };

    this.profileService
      .resendRPInvitation(invite)
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Invitation resent successfully!",
          });
          return EMPTY;
        }),
        catchError((error: HttpErrorResponse) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: error.message ?? "Invitation resent error. Please try again.",
          });
          return EMPTY;
        })
      )
      .subscribe();
  }

  addRoofingWRX() {
  }

  deactivateUser() {
    this.confirmService.confirm(
      "Warning",
      "Are you sure you want to make selected user inactive?",
      "pi pi-question-circle",
      () => {
        this.profileService
          .deactivateUser(this.user.userProfileId)
          .pipe(
            tap((user: GetUserResponse) => {
              this.messageService.add({
                severity: "success",
                summary: "Success",
                detail: "User was successfully deactivated",
              });

              const isUserDeactivatingHisProfile = this.appState.currentProfile?.manufacturer?.id === user.userProfileCompanyId;
              const hasMoreProfiles = this.appState.profiles.length > 1;

              if (hasMoreProfiles && isUserDeactivatingHisProfile) {
                return this.redirectToSelectCompany();
              }

              if (!hasMoreProfiles && isUserDeactivatingHisProfile) {
                sessionStorage.clear();
                return this.authService.logout();
              }

              this.user = user;
              this._profileStatus.next(this.user.status);
            }),
            catchError((error: HttpErrorResponse) => {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: error.message ?? "Deactivate user error.",
              });
              return EMPTY;
            })
          )
          .subscribe();
      }
    );
  }

  handleCancelClick() {
    return this.router.navigate(["/admin/user-management"]);
  }

  save() {
    if (this.userForm.valid) {
      let updateUserRequest: UpdateUserRequest = {
        userId: this.userForm.get("userId").value,
        companyId: this.userForm.get("userProfileCompanyId").value,
        firstName: this.userForm.get("firstName").value,
        lastName: this.userForm.get("lastName").value,
        phone: this.userForm.get("phone").value,
        isDeleted: this.isUserInactive(),
      };

      this.adminService
        .updateUser(updateUserRequest)
        .pipe(
          map((x: any) => {
            if (!x.error?.message) {
              this.profileService.validateProfile().subscribe(() => {
                this.messageService.add({
                  severity: "success",
                  summary: "Success",
                  detail: "User profile successfully updated.",
                });
                this.initializeUser();
              });

              this.originalValues = this.userForm.value;
              this.userForm.markAsPristine();
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: x.errors.detail ?? "",
              });
            }
          })
        )
        .subscribe();
    }
  }

  isDisabled(): boolean {
    const currentValues = this.userForm.value;
    const valuesChanged =
      currentValues.firstName !== this.originalValues.firstName ||
      currentValues.lastName !== this.originalValues.lastName ||
      currentValues.phone !== this.originalValues.phone;

    return !(this.userForm.valid && this.userForm.dirty && valuesChanged);
  }
}
