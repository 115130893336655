import { Address } from "src/app/core/data/models/CreateOrderRequest";

export type User = {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  companyAddress: string;
  associatedManufacturer: string;
  billTo: string;
  invitationDateTime: string;
  invitationStatus: string;
  eagleViewOrders: number;
  roofingWRXOrders: number;
  xmlOrders: number;
  smartBuildUserName: string | null;
  smartBuildDistributor: string;
  smartBuildRegistrationCompleted: string;
  eagleViewUserName: string | null;
  eagleViewRegistrationCompleted: string;
  roofingWRXUserName: string | null;
  roofingWRXRegistrationCompleted: string;
  completionDate: string;
  createdDate: string;
  licenseAgreementAcceptedDate: string | null;
  invitationRedeemedDateTime: string;
  rpLastConnected: string;
  status: string;
  loggedInAndConnectedToPartnerSystems: boolean;
  evTokenPresent: boolean;
  wrxTokenPresent: boolean;
  sbTokenPresent: boolean;
  credentialsCreated: boolean;
  trainingCompleted: string;
  registrationCompleted: string | null;
  trainingStatus: string;
  adminTrainingStatus: string | null;
  adminTrainingStatusId: number;
  trainingStatusId: number;
  roles: string;
  inviterName: string | null;
  roofingWRXEnabled: boolean;
  roofingWRXJobsEnabled: boolean;
  manufacturerId: number;
  nonWrxUserAtRegistration: boolean;
};

export type Company = {
  businessTypeId: number;
  addressId: number;
  address: Address;
  externalCompanyId: string;
  name: string;
  isActive: boolean;
  isBillable: boolean;
  partnerSystemsDetails: unknown[];
  pointsOfContact: unknown[];
  id: number;
  createdBy: number;
  createdOn: string;
  updatedBy: number;
  updatedOn: string;
  isDeleted: boolean;
};

export enum UserRole {
  Normal = "Normal",
  RPAdmin = "RPAdmin",
}
