<section class="container_fluid">
  <div class="pageHeading">
    <h2 class="title2">Work queue</h2>
  </div>
  <p class="text-sm">You currently have <span class="font-bold">{{ tasks.length }}</span> pending tasks.</p>

  <p-table
    #dt
    styleClass="p-datatable-striped pt-5"
    [value]="tasks"
    [rows]="10"
    [paginator]="true"
    dataKey="id"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first}-{last} of {totalRecords} tasks"
    [customSort]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="text-left" (click)="sortByColumn('Date')"  pSortableColumn="Date">Date <p-sortIcon field="Date"></p-sortIcon></th>
        <th class="text-left" (click)="sortByColumn('Type')"  pSortableColumn="Type">Type <p-sortIcon field="Type"></p-sortIcon></th>
        <th class="text-left" (click)="sortByColumn('Company')"  pSortableColumn="Company">Company <p-sortIcon field="Company"></p-sortIcon></th>
        <th class="text-left" (click)="sortByColumn('User')"  pSortableColumn="User">User <p-sortIcon field="User"></p-sortIcon></th>
        <th scope="col"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-task>
      <tr>
        <td>{{ task.createdOn | date: "longDate" }}</td>
        <td>
          <status-badge [text]="task.typeDisplay" [icon]="faBuilding" [hasBackground]="true"> </status-badge>
        </td>
        <td>{{ task.inviteeCompanyName }}</td>
        <td>
          {{ task.inviteeFullName }}
        </td>
        <td class="tableButtonCell">
          <button class="tableButton" (click)="viewTask(task)" pTooltip="View task" tooltipPosition="top">
            <fa-icon [icon]="faArrowRight"></fa-icon>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <work-queue-details-modal
    [(selectedTask)]="selectedTask"
    [(isVisible)]="isVisible"
    (onClose)="isVisible=false;loadTasks();"    
  ></work-queue-details-modal>
</section>
