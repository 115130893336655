<span [ngClass]="{
    'main': color === 'main',
    'success': color === 'success',
    'warning': color === 'warning',
    'danger': color === 'danger',
    'mono': color === 'mono',
    'subtleStyle': subtle === true
  }" class="badge">
  <ng-content></ng-content>
</span>
