import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppState } from "./app-state";
import { AuthenticationService } from "./authentication.service";
import { SmartBuildProject } from "src/app/core/data/models/SmartBuildProject";
import { ApiEndpoints } from "../constants/api-endpoints";
import { ISortableRequest } from "src/app/interfaces/sortable-request";

@Injectable({
  providedIn: "root",
})
export class SmartBuildService {
  constructor(
    private http: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService
  ) {}

  getProjects(archived: string, pageIndex?: number, pageSize?: number, orderStatusId?: number,request?:ISortableRequest): Observable<SmartBuildProject[]> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getProjectList}`;
    let params = new HttpParams();
    params = params.append("archived", archived);
    params = params.append("pageIndex", pageIndex);
    params = params.append("pageSize", pageSize);
    orderStatusId ? params = params.append("orderStatusId", orderStatusId) : null;
    params = params.append("SortBy", request.SortBy);
    params = params.append("SortDescending", request.SortDescending);
    return this.http.get<SmartBuildProject[]>(url, { headers, params });
  }

  getJobCountsByStatus() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getJobCountsByStatus}`;

    return this.http.get<Map<number, number>>(url, { headers});
  }

  getSmartBuildToken(): Observable<string> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getSmartBuildToken}`;
    return this.http.get<string>(url, { headers });
  }
}
