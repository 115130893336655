<div class="container_fluid">
  <div class="pageHeading">
    <div>
      <h2 class="title2">Job List</h2>
    </div>
    <div class="">
      <a routerLink="../jobs/new" pButton>
        <fa-icon [icon]="faPlus"></fa-icon>
        New job
      </a>
    </div>
  </div>
</div>

<section class="statusCardsCont" [ngClass]="{ small: isSidebarOpen === true }">
  <h3 class="srOnly">Stats</h3>
  <p class="statusCard cursor-pointer started" (click)="getAllJobs('Job Created', 1)">
    <span class="statusCard__number">
      {{ jobCounts.created }}
      <fa-icon class="statusCard__icon mono" [icon]="faPlus"></fa-icon>
    </span>
    <span class="statusCard__label">Created</span>
  </p>
  <p class="statusCard cursor-pointer pending-dimensions" (click)="getAllJobs('Dimension Data Generating', 1)">
    <span class="statusCard__number">
      {{ jobCounts.pendingDimensions }}
      <fa-icon class="statusCard__icon warning" [icon]="faRulerCombined"></fa-icon>
    </span>
    <span class="statusCard__label">Generating Dimensions</span>
  </p>
  <p class="statusCard cursor-pointer pending-takeoff" (click)="getAllJobs('TakeOff Report Generating', 1)">
    <span class="statusCard__number">
      {{ jobCounts.pendingTakeoff }}
      <fa-icon class="statusCard__icon warning" [icon]="faPaperPlane"></fa-icon>
    </span>
    <span class="statusCard__label">Generating Takeoff</span>
  </p>
  <p class="statusCard cursor-pointer completed" (click)="getAllJobs('Job Completed', 1)">
    <span class="statusCard__number">
      {{ jobCounts.completed }}
      <fa-icon class="statusCard__icon success" [icon]="faHouseCircleCheck"></fa-icon>
    </span>
    <span class="statusCard__label">Completed</span>
  </p>
  <p class="statusCard cursor-pointer failed" (click)="getAllJobs('Job Failed', 1)">
    <span class="statusCard__number">
      {{ jobCounts.failed }}
      <fa-icon class="statusCard__icon danger" [icon]="faExclamation"></fa-icon>
    </span>
    <span class="statusCard__label">Failed</span>
  </p>
  <p class="statusCard cursor-pointer canceled" (click)="getAllJobs('Job Canceled', 1)">
    <span class="statusCard__number">
      {{ jobCounts.canceled }}
      <fa-icon class="statusCard__icon danger" [icon]="faTimes"></fa-icon>
    </span>
    <span class="statusCard__label">Canceled</span>
  </p>
  <p class="statusCard cursor-pointer information-required" (click)="getAllJobs('Information Required', 1)">
    <span class="statusCard__number">
      {{ jobCounts.informationRequired }}
      <fa-icon class="statusCard__icon danger" [icon]="faInfo"></fa-icon>
    </span>
    <span class="statusCard__label">Information Required</span>
  </p>
</section>

<div class="container-fluid pr-4 pl-4">
  <p-tabView>
    <p-tabPanel header="Active">
      <section *ngIf="jobs.length > 0">
        <h3 class="srOnly">Active jobs</h3>
        <app-clear-filters-button
          class="block ml-auto"
          *ngIf="areFiltersApplied()"
          (onClick)="clearFilters()"
        ></app-clear-filters-button>
        <p-table
          #jobsTable
          [value]="jobs"
          [paginator]="false"
          [showCurrentPageReport]="true"
          selectionMode="single"
          [(selection)]="job"
          [rows]="pageSize"
          margin="0px;"
          styleClass="p-datatable-striped responsiveTable"
          [customSort]="true"
        >
          <ng-template pTemplate="header">
            <tr>
              <th scope="col" class="tableImgCell">Source</th>
              <th scope="col" pSortableColumn="id" (click)="sortByColumn('Number')">Number <p-sortIcon field="id"></p-sortIcon></th>
              <th scope="col" pSortableColumn="jobName" (click)="sortByColumn('Name')">Name <p-sortIcon field="jobName"></p-sortIcon></th>
              <th scope="col" pSortableColumn="address" (click)="sortByColumn('Address')" >Address <p-sortIcon field="address"></p-sortIcon></th>
              <th scope="col" pSortableColumn="updatedOn" (click)="sortByColumn('Date')" *ngIf="isSidebarOpen === false">
                Last modified <p-sortIcon field="updatedOn"></p-sortIcon>
              </th>
              <th scope="col" pSortableColumn="status" class="tableBadgeCell" (click)="sortByColumn('Status')">
                Status <p-sortIcon field="status"></p-sortIcon>
              </th>
              <th scope="col" class="tableButtonCell"><span class="srOnly">View</span></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-job>
            <tr pSelectableRow (click)="selectedJob = job; openSidebar(job)">
              <td scope="row" class="tableImgCell">
                <img [src]="sourceIcon(job.sequenceId)" alt="Roofing Works" class="tableImg" />
              </td>
              <td class="tableTitleCell tableLeftMarginCellMobile">
                {{ job.id }}
              </td>
              <td class="tableSubtitleCell tableLeftMarginCellMobile">
                {{ job.jobName }}
              </td>
              <td class="tableRegularCell">
                <span class="tableMobileLabel" aria-hidden="true">Address</span>
                {{ formatJobAddress(job) }}
              </td>
              <td class="tableRegularCell" *ngIf="isSidebarOpen === false">
                <span class="tableMobileLabel" aria-hidden="true">Last modified</span>
                {{ job.updatedOn | date: "shortDate" }}
              </td>
              <td class="tableBadgeCell">
                <status-badge
                  [text]="getJobStatus(job.statusId)"
                  [color]="getJobStatusColor(job.statusId)"
                  [hasBackground]="true"
                ></status-badge>
              </td>
              <td class="tableButtonCell">
                <button title="View job details" class="tableButton">
                  <fa-icon [icon]="faEye"></fa-icon>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <pagination
          [totalPages]="getTotalPages(totalJobsCount)"
          [currentPage]="page"
          [itemsPerPage]="pageSize"
          [totalItems]="totalJobsCount"
          (pageChange)="onPageChange($event)"
          (pageSizeChange)="onPageSizeChange($event)"
          selectedJobId
        ></pagination>
      </section>
      <section *ngIf="jobs.length === 0">
        <empty-state label="No active jobs yet" description="Create a new job and it'll show up here.">
          <button pButton type="button" class="small">
            <fa-icon [icon]="faPlus"></fa-icon>
            New job
          </button>
        </empty-state>
      </section>
    </p-tabPanel>
  </p-tabView>
</div>

<ng-template #sidebarContent>
  <div class="detailSidebar">
    <div class="detailSidebar__header">
      <div class="detailSidebar__header__sourceImgCont">
        <img [src]="sourceIcon(selectedJob.sequenceId)" alt="Job source" class="detailSidebar__header__sourceImg" />
      </div>
      <h4 class="detailSidebar__title">
        <span class="subtitle">Job No. {{ selectedJob.id }}</span>
        <span class="title3">{{ selectedJob.jobName }}</span>
      </h4>
    </div>

    <div class="statusBar">
      <div class="statusBar__startCont">
        <p>
          <span class="srOnly">Status:</span>
          <status-badge
            [color]="getJobStatusColor(selectedJob.statusId)"
            [text]="getJobStatus(selectedJob.statusId)"
            [hasBackground]="false"
          >
          </status-badge>
        </p>
        <p>Request date: {{ selectedJob.createdOn | date: "MM/dd/yyyy" }}</p>
      </div>
      <p>Last update: {{ selectedJob.updatedOn | date: "MM/dd/yyyy" }}</p>
    </div>

    <div class="detailSidebar__body">
      <div class="detailSidebar__tabsCont">
        <p class="text-sm" *ngIf="selectedJob.address">
          <span class="font-semibold">Address:</span> {{ formatJobAddress(selectedJob) }}
        </p>
        <p-tabView>
          <p-tabPanel *ngIf="canDisplayFiles" header="Files">
            <section *ngIf="contentPublished">
              <h5 class="srOnly">Files</h5>
              <p-table
                [value]="files"
                [paginator]="false"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{totalRecords} files"
                margin="0px;"
              >
                <ng-template pTemplate="header">
                  <tr class="srOnly">
                    <th scope="col">Name</th>
                    <th scope="col">Download</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-file>
                  <tr>
                    <td>
                      {{ file.name }}
                    </td>
                    <td class="tableButtonCell">
                      <button title="Download file" class="tableButton" (click)="downloadFile(file.name)">
                        <fa-icon [icon]="faDownload"></fa-icon>
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </section>
            <section *ngIf="!contentPublished">
              <empty-state
                label="No files yet"
                description="Generated takeoff report files will show up here."
              ></empty-state>
            </section>
          </p-tabPanel>
          <p-tabPanel *ngIf="canDisplayComments" header="Job notes">
            <section>
              <h5 class="srOnly">Job notes</h5>
              <div class="commentsCont">
                <comment-input [submitComment]="submitComment.bind(this)"></comment-input>
                <comment-list *ngIf="comments.length > 0" [comments]="comments"></comment-list>
              </div>
            </section>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</ng-template>
