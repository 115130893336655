import {Injectable, NgZone} from "@angular/core";
import {HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {map, catchError} from "rxjs/operators";
import {AppState} from "./app-state";
import {AuthenticationService} from "./authentication.service";
import {saveAs} from "file-saver";
import {ApiEndpoints} from "../constants/api-endpoints";
import {runInZone} from "../pipes/run-in-zone.pipe";

@Injectable({
  providedIn: "root",
})
export class UserInfoService {
  constructor(
    private httpClient: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService,
    private ngZone: NgZone
  ) {
  }

  acceptRoofingPassportLicenseAgreement(): Observable<{
    success: boolean;
    error: string;
  }> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.acceptRoofingPassportLicenseAgreement}`;
    return this.httpClient.post(url, {}, {headers, responseType: "text" as "json"}).pipe(
      runInZone(this.ngZone),
      map(() => ({success: true, error: ""})),
      catchError((err: HttpErrorResponse) => of({success: false, error: err.message}))
    );
  }

  downloadUserGuide(): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.downloadUserGuide}`;
    return this.httpClient.get(url, {headers, observe: "response", responseType: "blob"}).pipe(
      runInZone(this.ngZone),
      map((res) => {
        this.saveBlobResponse(res);
        return of(res.body);
      })
    );
  }

  private saveBlobResponse(response: HttpResponse<Blob>) {
    const disposition = response.headers.get("Content-Disposition");
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    const filename = matches?.length > 0 ? matches[1].replace(/['"]/g, "") : "user_guide.pdf";
    saveAs(response.body, filename);
  }
}
