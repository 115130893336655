<div class="container">
  <h2 class="title2 pageTitle">Help & Support</h2>
  <div class="card card_border">
    <section class="helpSection">
      <h3 class="title3 helpTitle">User guides</h3>
      <p class="helpSubtitle">The user guide will introduce and walk you through the common tasks you can perform in Roofing Passport:</p>

      <div class="downloadsCont">
        <button (click)="downloadUserGuide(false)" pButton class="main outline fullMobile">
          <fa-icon [icon]="faDownload"></fa-icon>
          Download user guide
        </button>
        <button *ngIf="isAdmin" (click)="downloadUserGuide(true)" pButton class="main outline fullMobile">
          <fa-icon [icon]="faDownload"></fa-icon>
          Download administrator guide
        </button>
      </div>
    </section>

    <section class="helpSection">
      <h3 class="title3 helpTitle">Frequently asked questions</h3>
      <p class="helpSubtitle">Find solutions to common issues and errors:</p>

      <div class="faqAccordionCont">
        <p-accordion [multiple]="true">
          <p-accordionTab header="Why am I getting an error when I hit create job?">
            In many cases, when EagleView displays that the system is “unable to communicate with EagleView’s ordering system”, this is actually a billing error. You need to contact EagleView and verify that your account billing or that of the manufacturer has been set up and approved. Once this is done, you should be able to create a job without the error.
          </p-accordionTab>
          <p-accordionTab header="Why am I unable to order a report in a region that shows imagery coverage?">
            EagleView 3D data requires complete oblique imagery coverage – meaning all sides of the roof (from all four directions) must have high clarity for accurate measurements. Some parcels, although in coverage areas, contain obstructions that restrict the views of the roof (i.e. heavy tree coverage). In these cases, EagleView is unable to obtain all four obliques required for the measurements.
          </p-accordionTab>
          <p-accordionTab header="Can I use my regular EagleView login credentials for Roofing Passport?">
            No, being a Roofing Passport user allows you to obtain a unique set of credentials from EagleView and Roofing Passport that are specific to the program. Attempting to log-in to EagleView with your normal account credentials through Roofing Passport will NOT allow you to access your Roofing Passport specific account.
          </p-accordionTab>
          <p-accordionTab header="Why can’t I login using my EagleView credentials?">
            Your browser may have the old Eagleview login credentials saved which has been known to cause issues when logging in. Please clear your browser’s cache, reload the page, and try logging in again. Your EagleView email address may have changed. Please try logging in using the following domain: @eagleviewrp.com.
          </p-accordionTab>
          <p-accordionTab header="Why am I receiving a billing error when trying to create a job using Roofing WRX?">
            The billing for your manufacturer may not have been set up, or was not set up correctly. Please reach out to the admin or person responsible for billing within your manufacturer for further assistance.
          </p-accordionTab>
          <p-accordionTab header="What XML file types can I upload when creating a job in the XML tab?">
            The following XML file takeoffs can be used to create a job: EagleView, TopView (v1.19 or later), or Hover.
          </p-accordionTab>
          <p-accordionTab header="How do I get invited to a manufacturer to create Roofing WRX or XML jobs?">
            An admin from the manufacturer must invite you to the manufacturer and have the type of job you want to create enabled (Roofing WRX or XML).
          </p-accordionTab>
        </p-accordion>
      </div>
    </section>

    <section class="helpSection mb-3">
      <h3 class="title3 helpTitle">Contact us</h3>
      <p class="helpSubtitle">If you need additional support, you can always contact us by the following means:</p>

      <div class="flex flex-column gap-3">
        <a href="mailto:support-roofingpassport@sherwin.com" pButton class="main link">
          <fa-icon [icon]="faEnvelope"></fa-icon>
          support-roofingpassport@sherwin.com
        </a>
        <a href="tel:+18006775270" pButton class="main link">
          <fa-icon [icon]="faPhone"></fa-icon>
          800-677-5270
        </a>
      </div>
    </section>
  </div>
</div>
