<div class="grid align-items-end">
    <div class="col-5">
        <label for="startDate" class="mr-2">Start Date:</label>
        <p-calendar [(ngModel)]="startDate" dateFormat="mm.dd.yy" class="full-width-calendar" [disabledDays]="[0,6]"
            [minDate]="maxDate" (onClose)="onDateChange($event)"></p-calendar>
    </div>
    <div class="col-5">
        <label for="endDate" class="mr-2">End Date:</label>
        <p-calendar [(ngModel)]="endDate" dateFormat="mm.dd.yy" class="full-width-calendar" [disabledDays]="[0,6]"
            [minDate]="minDate"></p-calendar>
    </div>
    <div class="col-2">
        <button pButton class="main" (click)="applyDateFilter()">Filter</button>
    </div>
</div>
<div style="max-width: calc(100vw - 330px);">
    <p-table #companyTable [value]="listByCompanyByUser" styleClass="p-datatable p-datatable-striped" [rows]="10" [scrollable]="true" [rowHover]="true"
        [loading]="isLoading" scrollHeight="700px">
        <ng-template pTemplate="header">
            <tr>
                <th>Company Name</th>
                <th>Email</th>
                <th>Eagleview</th>
                <th>Roofing WRX</th>
                <th>XML</th>
                <th>Eagleview %</th>
                <th>Roofing WRX %</th>
                <th>XML %</th>
                <th>Total</th>
                <th *ngFor="let col of displayedColumnsByCompanyByUser">
                    {{ col }} 
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-company>
            <tr>
                <td>{{company.companyName}}</td>
                <td>{{company.userEmail}}</td>
                <td>{{company.eagleViewOrders}}</td>
                <td>{{company.roofingWRXOrders}}</td>
                <td>{{company.xmlOrders}}</td>
                <td>{{company.eagleViewOrderPercent}}</td>
                <td>{{company.roofingWrxOrderPercent}}</td>
                <td>{{company.xmlOrderPercent}}</td>
                <td>{{company.totalOrders}}</td>           
                <td *ngFor="let column of displayedColumnsByCompanyByUser">{{company[column] }}</td>
            </tr>
        </ng-template>
    </p-table>
    <div *ngIf="listByCompanyByUser.length === 0"><empty-state label="No orders found"
        description="There are no order for the selected timeframe."></empty-state>
    </div>
</div>