import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AccordionModule } from "primeng/accordion";

import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { FileUploadModule } from "primeng/fileupload";
import { InputNumberModule } from "primeng/inputnumber";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { PanelModule } from "primeng/panel";
import { TableModule } from "primeng/table";
import { MenuModule } from "primeng/menu";
import { TooltipModule } from "primeng/tooltip";
import { TabMenuModule } from "primeng/tabmenu";
import { DividerModule } from "primeng/divider";
import { ToolbarModule } from "primeng/toolbar";
import { TieredMenuModule } from "primeng/tieredmenu";
import { ToastModule } from "primeng/toast";
import { RadioButtonModule } from "primeng/radiobutton";
import { TabViewModule } from "primeng/tabview";
import { CheckboxModule } from "primeng/checkbox";
import { ListboxModule } from "primeng/listbox";
import { MessagesModule } from "primeng/messages";
import { AutoCompleteModule } from "primeng/autocomplete";
import { PasswordModule } from "primeng/password";
import { ChipModule } from "primeng/chip";
import { SkeletonModule } from "primeng/skeleton";
import { SplitButtonModule } from "primeng/splitbutton";
import { BadgeModule } from "primeng/badge";
import { MessageModule } from "primeng/message";

@NgModule({
  declarations: [],
  imports: [CommonModule, DynamicDialogModule],
  exports: [
    AccordionModule,
    ButtonModule,
    CalendarModule,
    ConfirmDialogModule,
    DialogModule,
    DynamicDialogModule,
    DropdownModule,
    FileUploadModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    SplitButtonModule,
    TableModule,
    TooltipModule,
    PanelModule,
    MenuModule,
    DialogModule,
    TabMenuModule,
    DividerModule,
    ToolbarModule,
    TieredMenuModule,
    ToastModule,
    DividerModule,
    RadioButtonModule,
    TabMenuModule,
    TabViewModule,
    CheckboxModule,
    ListboxModule,
    MessagesModule,
    AutoCompleteModule,
    DialogModule,
    PasswordModule,
    ChipModule,
    SkeletonModule,
    BadgeModule,
    MessageModule,
  ],
})
export class PrimengLibraryModule {}
