import { Component } from "@angular/core";

@Component({
  selector: "app-company-profile",
  templateUrl: "./company-profile.component.html",
  styleUrls: [],
})
export class CompanyProfileComponent {
  constructor() {}
}
