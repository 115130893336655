import { Component, OnInit } from "@angular/core";
import { AppState } from "src/app/shared/services/app-state";
import { UserInfoService } from "src/app/shared/services/user-info.service";
import { AdminService } from "src/app/shared/services/admin.service";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { MessageService } from "primeng/api";
import { faDownload, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
})
export class HelpComponent implements OnInit {
  isAdmin: boolean = false;
  errorMessage: string;

  faDownload = faDownload;
  faEnvelope = faEnvelope;
  faPhone = faPhone;

  constructor(
    private appState: AppState,
    public adminService: AdminService,
    public userService: UserInfoService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.isAdmin = this.appState.isAdmin;
  }

  downloadUserGuide(adminGuide: boolean): void {
    const downloadUserGuide = adminGuide
      ? this.adminService.downloadAdminUserGuide()
      : this.userService.downloadUserGuide();

    downloadUserGuide.subscribe(
      () => {
        this.showSuccessToast("User Guide has been downloaded.");
      },
      (error) => {
        this.handleDownloadError(error);
      }
    );
  }

  private showSuccessToast(message: string): void {
    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: message,
    });
  }

  private handleDownloadError(error: any): void {
    if (typeof error === "object") {
      this.errorMessage = error.error ?? AppConstants.RoofingPassportCommunicationError;
    } else {
      this.errorMessage = error;
    }

    this.showErrorToast(this.errorMessage);
  }

  private showErrorToast(message: string): void {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: message,
    });
  }
}
