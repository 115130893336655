import { Component, Input, OnInit } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  faInfoCircle = faInfoCircle;

  @Input() color: string = 'mono';
  @Input() icon: object;
  @Input() label: string;
  @Input() description: string;
  @Input() styleClass: string;

  constructor() { }

  ngOnInit(): void {
  }
}
