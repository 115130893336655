import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { AppConstants } from "src/app/shared/constants/app-constants";

type DataSource = keyof typeof AppConstants.ThirdPartyServiceName;

@Component({
  selector: "app-created-successfully",
  templateUrl: "./created-successfully.component.html",
  styleUrls: ["./created-successfully.component.scss"],
})
export class CreatedSuccessfullyComponent implements OnInit {
  faCheck = faCheck;
  faPlus = faPlus;
  jobNumber: string = "";
  jobName: string = "";
  dataSource: DataSource | null = null;
  isVisible: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.jobNumber = params["jobNumber"];
      this.jobName = params["jobName"];
      this.dataSource = params["dataSource"] as DataSource;
    });
  }

  hideAndNavigate(): void {
    this.isVisible = false;
    this.router.navigate(["/jobs/new"]);
  }
}
