import { AuthContext } from "src/app/shared/services/authentication.service";
import { AppConstants } from "src/app/shared/constants/app-constants";

export enum UserRole {
  Normal = "Normal",
  Admin = "Admin",
  RPAdmin = "RPAdmin",
  PSAdmin = "PSAdmin",
  Reporting = "Reporting",
}

export enum AdminContext {
  Internal = "Internal",
  EagleView = "EagleView",
  RoofingWRX = "RoofingWRX",
}

export enum ProfileStatus {
  Invalid = "Invalid",
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Revoked = "Revoked",
  Inactive = "Inactive",
}

export enum AuthStatus {
  None = "None",
  Valid = "Valid",
  Expired = "Expired",
  Error = "Error",
}

export enum ProfilePayee {
  Manufacturer = "Manufacturer",
  Company = "Company",
}

export interface AppInitializationData {
  license: LicenseAgreementStatus;
  user: UserData;
  templateData?: UiTemplateData[];
  faqEntries?: FAQEntry[];
  profiles?: ManufacturerProfile[];
  manufacturers?: Manufacturer[];
}

export interface UserData {
  isActive: boolean;
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
  role: UserRole;
  adminContext: AdminContext;
}

export interface UiTemplateData {
  key: string;
  url: string;
  urlText: string;
  toolTip: string;
  order: number;
}

export interface FAQEntry {
  question: string;
  answer: string;
}

export interface Manufacturer {
  id: number;
  name: string;
  description?: string;
  street?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  website?: string;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  roofingWRXEnabled?: boolean;
  roofingWRXJobsEnabled?: boolean;
  roofingWRXCompanyId?: number;
  smartBuildDistributorId?: number;
  isTestCompany?: boolean;
}

export interface ManufacturerProfile {
  status: ProfileStatus;
  role: UserRole;
  payee: ProfilePayee;
  manufacturer: Manufacturer;
  licenseAgreement: LicenseAgreementStatus;
  authorizations?: ThirdPartyAuth[];
  roofingWRXBranches?: RoofingWRXBranch[];
  roofingWRXEnabled?: boolean;
  roofingWRXJobsEnabled?: boolean;
  nonWRXRegistrationCompleted?: boolean;
  isDefaultProfile?: boolean;
  skipRegistrationStatus?: boolean;
  enabledPartnerSystems?: Record<string, boolean>;
}

export interface ThirdPartyAuth {
  status: AuthStatus;
  context: AuthContext;
  registrationCompleted?: string;
  registrationRequested?: string;
  claims?: any;
  rejected?: boolean;
}

export interface LicenseAgreementStatus {
  accepted: boolean;
  lastAcceptedDateTime: Date;
}

export interface RoofingWRXBranch {
  id?: number;
  company_id?: number;
  name?: string;
  billing_status?: number;
}

export enum Service {
  EagleView = "EagleView",
  RoofingWRX = "RoofingWRX",
  SmartBuild = "SmartBuild",
}

export interface ServiceStatus {
  service: Service;
  isAvailable: boolean;
}
