<div>
  <h3 class="srOnly">Structure</h3>

  <!-- Simple view -->
  <div *ngIf="!showAdvancedView" class="container px-0 pt-2">
    <!-- Company -->
    <section class="mb-5">
      <div class="flex align-items-center gap-3 mb-3">
        <img src="assets/img/icons/chart_top.svg" alt="" class="w-2rem" />
        <h4 class="title3">Company</h4>
      </div>

      <section class="structureCard card card_border mb-4">
        <form [formGroup]="structureForm">
          <div class="mb-2">
            <div class="inputCont">
              <label for="company_name" class="label">Company name *</label>
              <input type="text" id="company_name" class="w-full" pInputText placeholder="Company name"
                formControlName="name" />
            </div>
          </div>

          <section class="mb-2">
            <h4 class="title4 mb-3">Legal address</h4>
            <div class="grid my-0">
              <div class="col-12 xl:col-8 py-0">
                <div class="inputCont">
                  <label for="company_street" class="label">Street *</label>
                  <input type="text" id="company_street" class="w-full" pInputText placeholder="Street"
                    formControlName="street" />
                </div>
              </div>
              <div class="col-12 lg:col-4 py-0">
                <div class="inputCont">
                  <label for="company_city" class="label">City *</label>
                  <input type="text" id="company_city" class="w-full" pInputText placeholder="City"
                    formControlName="city" />
                </div>
              </div>
              <div class="col-12 lg:col-4 py-0">
                <div class="inputCont">
                  <label for="company_state" class="label">State/Province *</label>
                  <p-dropdown inputId="company_state" [options]="placeholderOptions" optionLabel="name" [filter]="true"
                    class="w-full" placeholder="Select state/province"></p-dropdown>
                </div>
              </div>
              <div class="col-6 lg:col-4 py-0">
                <div class="inputCont">
                  <label for="company_postalCode" class="label">Postal code *</label>
                  <input type="text" id="company_postalCode" class="w-full" pInputText placeholder="Postal code"
                    formControlName="postalCode" />
                </div>
              </div>
            </div>
          </section>

          <section class="mb-2">
            <h4 class="title4 mb-3">Contact info</h4>
            <div class="grid my-0">
              <div class="col-12 xl:col-8 py-0">
                <div class="inputCont">
                  <label for="company_website" class="label">Website</label>
                  <input type="text" id="company_website" class="w-full" pInputText placeholder="Website"
                    formControlName="website" />
                </div>
              </div>
            </div>
            <div class="grid my-0">
              <div class="col-12 xl:col-4 py-0">
                <div class="inputCont">
                  <label for="company_contactName" class="label">Contact name</label>
                  <input type="text" id="company_contactName" class="w-full" pInputText placeholder="Contact name"
                    formControlName="contactName" />
                </div>
              </div>
              <div class="col-12 lg:col-6 xl:col-4 py-0">
                <div class="inputCont">
                  <label for="company_contactEmail" class="label">Contact email</label>
                  <input type="email" id="company_contactEmail" class="w-full" pInputText placeholder="Contact email"
                    formControlName="contactEmail" />
                </div>
              </div>
              <div class="col-12 lg:col-6 xl:col-4 py-0">
                <div class="inputCont">
                  <label for="company_contactPhone" class="label">Contact phone</label>
                  <input type="tel" id="company_contactPhone" class="w-full" pInputText placeholder="Contact phone"
                    formControlName="contactPhone" />
                </div>
              </div>
            </div>
          </section>
        </form>
      </section>
    </section>

    <!-- Nodes -->
    <section>
      <div class="flex align-items-center justify-content-between gap-3">
        <div class="flex align-items-center gap-3 mb-3">
          <img src="assets/img/icons/chart_bottom.svg" alt="" class="w-2rem" />
          <h4 class="title3">Nodes</h4>
        </div>
        <p class="text-sm">2 nodes</p>
      </div>

      <section class="structureCard card card_border pb-0 mb-4">
        <form action="" [formGroup]="nodeForm">
          <div class="structureCard__topCont">
            <p class="flex gap-3 text-sm">
              <span class="srOnly">Node type:</span>
              <fa-icon [icon]="faDiagramProject"></fa-icon>
              <span class="font-semibold">Branch</span>
            </p>
            <p class="flex gap-2 text-sm">
              <span class="srOnly">Users:</span>
              <fa-icon [icon]="faUserGroup"></fa-icon>
              8 users in this node
              <button pButton class="link" (click)="showUsersDialog()">Edit</button>
            </p>
          </div>
          <div class="grid my-0 mb-2">
            <div class="col-12 xl:col-8 py-0">
              <div class="inputCont">
                <label for="branch_name" class="label">Name *</label>
                <input type="text" id="branch_name" class="w-full" pInputText placeholder="Company name"
                  formControlName="name" />
              </div>
            </div>
          </div>
          <section>
            <h4 class="title4 mb-3">Legal address</h4>
            <div class="grid my-0">
              <div class="col-12 xl:col-8 py-0">
                <div class="inputCont">
                  <label for="branch_street" class="label">Street *</label>
                  <input type="text" id="branch_street" class="w-full" pInputText placeholder="Street"
                    formControlName="street" />
                </div>
              </div>
              <div class="col-12 lg:col-4 py-0">
                <div class="inputCont">
                  <label for="branch_city" class="label">City *</label>
                  <input type="text" id="branch_city" class="w-full" pInputText placeholder="City"
                    formControlName="city" />
                </div>
              </div>
              <div class="col-12 lg:col-4 py-0">
                <div class="inputCont">
                  <label for="branch_state" class="label">State/Province *</label>
                  <p-dropdown inputId="branch_state" [options]="placeholderOptions" optionLabel="name" [filter]="true"
                    class="w-full" placeholder="Select state/province"></p-dropdown>
                </div>
              </div>
              <div class="col-6 lg:col-4 py-0">
                <div class="inputCont">
                  <label for="branch_postalCode" class="label">Postal code *</label>
                  <input type="text" id="branch_postalCode" class="w-full" pInputText placeholder="Postal code"
                    formControlName="postalCode" />
                </div>
              </div>
              <div class="col-6 lg:col-4 py-0">
                <div class="inputCont_check billableInputCont">
                  <p-checkbox inputId="branch_billable" name="billable" value="true" binary="true"></p-checkbox>
                  <label for="branch_billable" class="label_check">Billable</label>
                  <span class="ml-2" pTooltip="Choose wether this node can be used for billing." tooltipPosition="top">
                    <fa-icon [icon]="faQuestionCircle"></fa-icon>
                  </span>
                </div>
              </div>
            </div>
          </section>
        </form>
      </section>
    </section>

    <div class="structureCard card card_border p-0 mb-4">
      <button pButton class="link w-full justify-content-center p-3" (click)="showNodeDialog()">
        <fa-icon [icon]="faPlus"></fa-icon>
        New node
      </button>
    </div>
  </div>

  <!-- Advanced view -->
  <div *ngIf="showAdvancedView" class="mb-5">
    <section class="selectionOptions">
      <h4 class="srOnly">Selection options</h4>
      <div class="flex align-items-center gap-3 text-sm">
        <fa-icon [icon]="faCheck"></fa-icon>
        <p class="font-semibold">2 users selected</p>
      </div>
      <div class="flex align-items-center gap-3">
        <button pButton class="outline small" (click)="showMoveUsersDialog([1, 2])">
          <fa-icon [icon]="faUpDownLeftRight"></fa-icon>
          Move to...
        </button>
        <button pButton class="outline small square" title="More actions">
          <fa-icon [icon]="faEllipsisV"></fa-icon>
        </button>
      </div>
    </section>

    <p-tree [value]="nodes" draggableNodes="true" droppableNodes="true" styleClass="companyTree">
      <ng-template let-node pTemplate="node">
        <div class="companyNode">
          <div class="companyNode__startCont">
            <fa-icon [icon]="faGripVertical" class="companyNode__dragIcon"></fa-icon>
            <fa-icon [icon]="getNodeIcon(node.nodeType)" class="companyNode__typeIcon"></fa-icon>
            <span class="companyNode__label">{{ node.label }}</span>
            <badge *ngIf="node.nodeType != 'branch'" color="main">{{ node.children.length }}<span class="srOnly">
                children</span></badge>
          </div>
          <div class="companyNode__endCont">
            <status-badge [color]="getNodeStatus(node.status, node.billable).color"
              [text]="getNodeStatus(node.status, node.billable).text"></status-badge>
            <button *ngIf="node.children" pButton class="default link small square" title="Expand/Collapse node"
              (click)="toggleNode(node)">
              <fa-icon [icon]="node.expanded ? faChevronUp : faChevronDown"></fa-icon>
            </button>
            <button pButton class="default link small square" title="Edit" (click)="showNodeDialog(node.id)">
              <fa-icon [icon]="faPen"></fa-icon>
            </button>
            <button pButton (click)="nodeActionsMenu.toggle($event)" class="default link small square"
              title="More actions">
              <fa-icon [icon]="faEllipsisV"></fa-icon>
            </button>
            <p-overlayPanel #nodeActionsMenu [appendTo]="'body'">
              <ng-template pTemplate>
                <ul class="dropdownMenu">
                  <li>
                    <button class="dropdownButton">
                      <fa-icon [icon]="faToggleOn" class="dropdownButton__icon"></fa-icon>
                      <span>Activate</span>
                    </button>
                  </li>
                  <li>
                    <button class="dropdownButton">
                      <fa-icon [icon]="faToggleOff" class="dropdownButton__icon"></fa-icon>
                      <span>Deactivate</span>
                    </button>
                  </li>
                  <li>
                    <button class="dropdownButton danger">
                      <fa-icon [icon]="faTrash" class="dropdownButton__icon"></fa-icon>
                      <span>Delete</span>
                    </button>
                  </li>
                </ul>
              </ng-template>
            </p-overlayPanel>
          </div>
        </div>
      </ng-template>

      <ng-template let-node pTemplate="usersTable">
        <p class="mb-3">8 users in this branch</p>
        <p-table [value]="node.data" [(selection)]="selectedUsers" dataKey="id" [tableStyle]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 4rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th>Name</th>
              <th>Company role</th>
              <th>Last seen</th>
              <th class="tableButtonCell"><span class="srOnly">Move</span></th>
              <th class="tableButtonCell"><span class="srOnly">Edit</span></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-user>
            <tr>
              <td>
                <p-tableCheckbox [value]="user"></p-tableCheckbox>
              </td>
              <td>{{ user.name }}</td>
              <td>{{ user.role }}</td>
              <td>{{ user.lastSeen }}</td>
              <td class="tableButtonCell">
                <button title="Move" class="tableButton" (click)="showMoveUsersDialog([user.id])">
                  <fa-icon [icon]="faUpDownLeftRight"></fa-icon>
                </button>
              </td>
              <td class="tableButtonCell">
                <button title="Edit" class="tableButton">
                  <fa-icon [icon]="faPen"></fa-icon>
                </button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </ng-template>

      <ng-template let-node pTemplate="newNode">
        <div class="actionNode">
          <button pButton class="link w-full p-3" (click)="showNodeDialog()">
            <fa-icon [icon]="faPlus"></fa-icon>
            New node
          </button>
        </div>
      </ng-template>
    </p-tree>
  </div>
</div>

<!-- Dialog - New/Edit node -->
<p-dialog header="{{ nodeDialogIsEditing ? 'Edit' : 'New' }} node" [(visible)]="displayNodeDialog"
  styleClass="dialog_medium" [modal]="true" [draggable]="false">
  <form action="" [formGroup]="nodeForm">
  <div class="inputCont">
    <label for="branch_name" class="label">Name *</label>
    <input type="text" id="branch_name" class="w-full" pInputText placeholder="Company name" formControlName="name" />
  </div>
  <fieldset>
    <legend class="label">Type *</legend>
    <div class="optionsCont mb-4">
      <option-box [id]="'nodeType_1'" name="nodeType" value="company" [checked]="true" [icon]="faBuilding"
        label="Company" [smallTitle]="true">
      </option-box>
      <option-box [id]="'nodeType_2'" name="nodeType" value="region" [icon]="faGlobeAmericas" label="Region"
        [smallTitle]="true">
      </option-box>
      <option-box [id]="'nodeType_3'" name="nodeType" value="branch" [icon]="faDiagramProject" label="Branch"
        [smallTitle]="true">
      </option-box>
    </div>
  </fieldset>
  <div class="inputCont">
    <label for="node_parent" class="label">Parent node *</label>
    <p-dropdown inputId="node_parent" [options]="placeholderOptions" optionLabel="name" [filter]="true" class="w-full"
      placeholder="Select parent node"></p-dropdown>
  </div>
  <section class="mb-2">
    <h4 class="title4 mb-3">Legal address</h4>
    <div class="inputCont">
      <label for="branch_street" class="label">Street *</label>
      <input type="text" id="branch_street" class="w-full" pInputText placeholder="Street" formControlName="street" />
    </div>
    <div class="inputCont">
      <label for="branch_city" class="label">City *</label>
      <input type="text" id="branch_city" class="w-full" pInputText placeholder="City" formControlName="city" />
    </div>
    <div class="grid my-0">
      <div class="col-12 lg:col-8 py-0">
        <div class="inputCont">
          <label for="branch_state" class="label">State/Province *</label>
          <p-dropdown inputId="branch_state" [options]="placeholderOptions" optionLabel="name" [filter]="true"
            class="w-full" placeholder="Select state/province"></p-dropdown>
        </div>
      </div>
      <div class="col-6 lg:col-4 py-0">
        <div class="inputCont">
          <label for="branch_postalCode" class="label">Postal code *</label>
          <input type="text" id="branch_postalCode" class="w-full" pInputText placeholder="Postal code"
            formControlName="postalCode" />
        </div>
      </div>
    </div>
    <div class="inputCont_check mt-0 mb-5">
      <p-checkbox inputId="branch_billable" name="billable" value="true" binary="true"></p-checkbox>
      <label for="branch_billable" class="label_check">Billable</label>
      <span class="ml-2" pTooltip="Choose wether this node can be used for billing." tooltipPosition="top">
        <fa-icon [icon]="faQuestionCircle"></fa-icon>
      </span>
    </div>
  </section>
  <section class="footActionsCont spaced">
    <button pButton type="button" class="fullMobile" [ngClass]="{ success: !nodeDialogIsEditing }"
      (click)="displayNodeDialog = false">
      <fa-icon [icon]="nodeDialogIsEditing ? faSave : faCheck"></fa-icon>
      {{ nodeDialogIsEditing ? "Save changes" : "Create node" }}
    </button>
    <button pButton type="button" class="outline default fullMobile" (click)="displayNodeDialog = false">Cancel</button>
  </section>
</form>
</p-dialog>

<!-- Dialog - Users in node -->
<p-dialog header="Edit users in node" [(visible)]="displayUsersDialog" styleClass="dialog_medium" [modal]="true"
  [draggable]="false">
  <div class="flex align-items-center justify-content-between gap-3 flex-wrap mb-4">
    <div class="flex align-items-center gap-2 text-sm">
      <fa-icon [icon]="faDiagramProject"></fa-icon>
      <span class="font-semibold">Branch ABC</span>
    </div>
    <p class="text-sm">8 users in this node</p>
  </div>
  <div class="flex align-items-center usersListselectionOptions gap-3 mb-3">
    <p class="text-sm font-semibold flex-shrink-0">No users selected</p>
    <div class="w-full">
      <label for="moveUsers_destination" class="srOnly">Destination node</label>
      <p-dropdown inputId="moveUsers_destination" [options]="placeholderOptions" optionLabel="name" [filter]="true"
        class="w-full" placeholder="Select node"></p-dropdown>
    </div>
    <button pButton type="button" class="outline flex-shrink-0">
      <fa-icon [icon]="faUpDownLeftRight"></fa-icon>
      Move
    </button>
  </div>
  <p-table [value]="placeholderUsers" [(selection)]="selectedUsers" dataKey="id">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th>Name</th>
        <th>Company role</th>
        <th class="tableButtonCell"><span class="srOnly">Edit</span></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr>
        <td>
          <p-tableCheckbox [value]="user"></p-tableCheckbox>
        </td>
        <td>{{ user.name }}</td>
        <td>{{ user.role }}</td>
        <td class="tableButtonCell">
          <button title="Edit" class="tableButton">
            <fa-icon [icon]="faPen"></fa-icon>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<!-- Dialog - Move users -->
<p-dialog header="Move user{{ moveDialogIsMultiple ? 's' : '' }}" [(visible)]="displayMoveUsersDialog"
  styleClass="dialog_small" [modal]="true" [draggable]="false">
  <p class="text-sm mb-2">Where do you want to move the following user{{ moveDialogIsMultiple ? "s" : "" }}?</p>
  <ul class="list-none flex flex-wrap gap-2 text-sm p-0 mb-3 m-0">
    <li>John Doe,</li>
    <li>Jane Doe</li>
  </ul>
  <div class="inputCont">
    <label for="moveUsers_destination" class="label">Destination node</label>
    <p-dropdown inputId="moveUsers_destination" [options]="placeholderOptions" optionLabel="name" [filter]="true"
      class="w-full" placeholder="Select node"></p-dropdown>
  </div>
  <section class="footActionsCont spaced">
    <button pButton type="button" class="fullMobile" (click)="displayNodeDialog = false">Move users</button>
    <button pButton type="button" class="outline default fullMobile" (click)="displayNodeDialog = false">Cancel</button>
  </section>
</p-dialog>