import { IMenuItemMap } from "src/app/core/data/models/menu.model";
import { AuthContext } from "src/app/shared/services/authentication.service";
import { Manufacturer, Service, ServiceStatus } from "../data/models/AppInitializationData";

export enum UserRole {
  Normal = "Normal",
  Admin = "Admin",
  RPAdmin = "RPAdmin",
  PSAdmin = "PSAdmin",
  Reporting = "Reporting",
}

export enum AdminContext {
  Internal = "Internal",
  EagleView = "EagleView",
  RoofingWRX = "RoofingWRX",
}

export enum ProfileStatus {
  Invalid = "Invalid",
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Revoked = "Revoked",
  Inactive = "Inactive",
}

export enum AuthStatus {
  None = "None",
  Valid = "Valid",
  Expired = "Expired",
  Error = "Error",
}

export enum ProfilePayee {
  Manufacturer = "Manufacturer",
  Company = "Company",
}

export interface AppInitializationData {
  license: LicenseAgreementStatus;
  user: UserData;
  templateData?: UiTemplateData[];
  faqEntries?: FAQEntry[];
  profiles?: ManufacturerProfile[];
  serviceStatus?: ServiceStatus[];
  featureFlags?: Record<Service, boolean>[];
}

export interface UserData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
  roles: UserRole[];
  adminContext: AdminContext;
}

export interface UiTemplateData {
  key: string;
  url: string;
  urlText: string;
  toolTip: string;
  order: number;
}

export interface FAQEntry {
  question: string;
  answer: string;
}

export interface ManufacturerProfile {
  status: ProfileStatus;
  role: UserRole;
  payee: ProfilePayee;
  manufacturer: Manufacturer;
  licenseAgreement: LicenseAgreementStatus;
  authorizations?: ThirdPartyAuth[];
  roofingWRXBranches?: RoofingWRXBranch[];
  roofingWRXEnabled?: boolean;
  roofingWRXJobsEnabled?: boolean;
  isDefaultProfile?: boolean;
  skipRegistrationStatus?: boolean;
}

export interface ThirdPartyAuth {
  context: AuthContext;
  status: AuthStatus;
  registrationCompleted?: string;
  claims?: any;
}

export interface LicenseAgreementStatus {
  accepted: boolean;
  lastAcceptedDateTime: Date;
}

export interface RoofingWRXBranch {
  id?: number;
  company_id?: number;
  name?: string;
  billing_status?: number;
}

export interface IUserConfig {
  userId: string;
  manufacturerId: string;
}

export interface ISectionMenu {
  [menuSectionId: string]: IMenuItemMap[];
}

export interface AppStateModel {
  error: any;
  manufacturers: Manufacturer[];
  sectionMenus: ISectionMenu;
}

export const APP_DEFAULTS: AppStateModel = {
  error: null,
  manufacturers: [],
  sectionMenus: {} as ISectionMenu,
};
export { Manufacturer };

export interface JobComment {
  date: string;
  text: string;
  contact_name: string;
  contact_id: string;
}
