import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MenuItem } from "primeng/api";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: "roofing-passport-side-navigation",
  templateUrl: "./side-navigation.component.html",
  styleUrls: ["./side-navigation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavigationComponent {
  @Input() menuItems: MenuItem[];
  @Input() headerText: string;

  constructor() {}

  isCollapsed: boolean = false;
  faChevronLeft = faChevronLeft;

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed
  }
}
