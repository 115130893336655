import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { of, Subscription } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { AppState } from "src/app/shared/services/app-state";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  appVersion?: AppVersion;
  previousEVToken = "";
  previousSBToken = "";
  rawCookie = "";
  result = "";
  showAuthenticating = false;
  busy: Subscription;

  constructor(
    private router: Router,
    private appState: AppState
  ) {}

  ngOnInit(): void {
    this.appState.appData$
      .pipe(
        take(1),
        switchMap((data) => {
          if (this.appState.needsCurrentProfile) {
            return of(data);
          }
          return this.appState.profile$.pipe(take(1));
        })
      )
      .subscribe(() => {
        document.location.href = `/jobs`;
      });
  }
}

export interface AppVersion {
  application: string;
  version: number;
}
