<p-dialog
  styleClass="w-30rem overflow-hidden"
  [(visible)]="isVisible"
  [modal]="true"
  [draggable]="false"
  (onHide)="closeModal()"
>
  <app-eagleview-second-step *ngIf="jobDataSource === 'option1'" [onClose]="closeModal"></app-eagleview-second-step>
  <app-xml-second-step *ngIf="jobDataSource === 'option2'" [onClose]="closeModal"></app-xml-second-step>
  <app-blueprints-second-step *ngIf="jobDataSource === 'option3'" [onClose]="closeModal"></app-blueprints-second-step>
</p-dialog>
