import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { formatDate } from "@angular/common";
import { Order } from "src/app/core/data/models/Reporting";
import { ReportingService } from "src/app/shared/services/reporting.service";
import { AppState } from "src/app/shared/services/app-state";
import { MessageService, FilterService } from "primeng/api";
import { Table } from "primeng/table";
import { faDownload, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

const ORDERS_FILE_NAME = "KPI-reporting-orders.csv";

interface OrdersTableColumn {
  field: keyof Order;
  header: string;
  dateFormat?: string;
}

const getFirstDayOfCurrentMonth = () => new Date(new Date().getFullYear(), new Date().getMonth(), 1);

@Component({
  selector: "app-admin-reporting",
  templateUrl: "./admin-reporting.component.html",
  styleUrls: ["./admin-reporting.component.scss"],
})
export class AdminReportingComponent implements OnInit {
  @ViewChild("ordersTable") ordersTable: Table;
  orders: Order[] = [];
  fromDateFilter: Date;
  isLoadingOrders: boolean = false;
  ordersTableColumns: OrdersTableColumn[];
  dateFilterHasChanged: boolean = false;
  selectedOrder: Order;

  companies = [];
  companiesColumns = [];
  companiesByUser = [];
  companiesColumnsByUser = [];

  faDownload = faDownload;
  faFilterCircleXmark = faFilterCircleXmark;
  selectedTab: string;
  selectedTabIndex: number = 0;
  filters: Record<keyof Order, string> = {
    jobNumber: "",
    jobName: "",
    createdOn: "",
    createdBy: "",
    jobStatus: "",
  };

  constructor(
    public appState: AppState,
    private reportingService: ReportingService,
    private messageService: MessageService,
    private filterService: FilterService,
    protected router: Router
  ) {
    // Initialize fromDateFilter to the first day of the current month
    this.fromDateFilter = getFirstDayOfCurrentMonth();

    this.filterService.register("dateEquals", (value: string, filter: string): boolean => {
      if (!filter) {
        return true;
      }
      if (value === undefined || value === null) {
        return false;
      }
      const dateValue = formatDate(value, "yyyy-MM-dd", "en-US");
      const filterDate = formatDate(filter, "yyyy-MM-dd", "en-US");
      return dateValue === filterDate;
    });
  }

  get canUseReporting() {
    return this.appState.canUseReporting;
  }

  ngOnInit(): void {
    if (!this.canUseReporting) {
      this.returnToProjectList();
    }

    this.loadOrders();
    this.initializeColumns();
  }

  pageSize = 10;
  totalReportsCount = 10;
  page = 1;

  onPageChange(page: number) {
    this.page = page;
    this.loadOrders();
  }

  onPageSizeChange(pageSize: number) {
    this.page = 1;
    this.pageSize = pageSize;
    this.loadOrders();
  }

  getTotalPages(): number {
    return Math.ceil(this.totalReportsCount / this.pageSize);
  }

  initializeColumns() {
    this.ordersTableColumns = [
      { field: "jobNumber", header: "Job Number" },
      { field: "jobName", header: "Job Name" },
      { field: "createdOn", header: "Created Date (UTC)" },
      { field: "createdBy", header: "Created By" },
      { field: "jobStatus", header: "Status" },
    ];
  }

  loadOrders(): void {
    this.isLoadingOrders = true;
    const filterDate = formatDate(this.fromDateFilter, "MM-dd-yyyy", "en-US");
    const filter = { fromDate: filterDate };
    this.reportingService.getOrdersByFilter(filter, this.page, this.pageSize).subscribe({
      next: (orders) => {
        this.orders = orders["reportingOrders"];
        this.totalReportsCount = orders["totalReportingCount"];
        this.isLoadingOrders = false;
      },
      error: (err) => {
        this.showErrorToast(err.message);
        this.clearOrders();
        this.isLoadingOrders = false;
      },
    });
  }

  clearOrders(): void {
    this.orders = [];
  }

  onDateChange(): void {
    this.dateFilterHasChanged = true;
    this.loadOrders();
  }

  areFiltersApplied(): boolean {
    const hasFilters = Object.values(this.filters).some((x) => x !== "");
    const hasSorting = this.ordersTable ? !!this.ordersTable.sortField : false;

    return hasFilters || hasSorting || this.dateFilterHasChanged;
  }

  clearFilters(): void {
    for (const key in this.filters) {
      this.filters[key] = "";
    }
    this.ordersTable.filters = {};
    this.ordersTable.sortField = undefined;
    this.ordersTable.sortOrder = 1;
    this.ordersTable.sort({ field: "jobNumber" });

    if (this.dateFilterHasChanged) {
      this.fromDateFilter = getFirstDayOfCurrentMonth();
      this.dateFilterHasChanged = false;
      this.loadOrders();
    }

    this.ordersTable.reset();
  }

  onTabChange(index: number) {
    if (index === 0) {
      this.selectedTab = 'Orders';
    } else if (index === 1) {
      this.selectedTab = 'OrdersCompany';
    } else if (index === 2) {
      this.selectedTab = 'OrdersCompanyByUser';
    }
  }

  listCompanies(companies) {
    this.companies = companies.data;
    this.companiesColumns = companies.columns;
  }

  listCompaniesByUser(companies) {
    this.companiesByUser = companies.data;
    this.companiesColumnsByUser = companies.columns;
  }

  exportOrdersCSV(report: string = 'Orders') {
    if (report === 'Orders' && this.orders.length > 0) {
      const formattedData = this.orders.map((order) => ({
        ...order,
        createdOn: formatDate(order.createdOn, "MM/dd/yyyy", "en-US"),
      }));

      let csvContent = this.ordersTableColumns.map((col) => col.header).join(",") + "\n";
      formattedData.forEach((order) => {
        const row = this.ordersTableColumns.map((col) => order[col.field]).join(",");
        csvContent += row + "\n";
      });

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", ORDERS_FILE_NAME);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (report === 'OrdersCompany' && this.companies.length > 0) {
      let csvContent = Object.keys(this.companies[0]).join(",") + "\n";
      this.companies.forEach((company) => {
        const row = Object.values(company).join(",");
        csvContent += row + "\n";
      });

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", ORDERS_FILE_NAME);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (report === 'OrdersCompanyByUser' && this.companiesByUser.length > 0) {
      let csvContent = Object.keys(this.companiesByUser[0]).join(",") + "\n";

      this.companiesByUser.forEach((company) => {
        const row = Object.values(company).join(",");
        csvContent += row + "\n";
      });

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", ORDERS_FILE_NAME);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private showErrorToast(message: string): void {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: message,
    });
  }

  returnToProjectList() {
    this.router.navigate(["jobs"]);
  }
}
