<div
  class="serviceItem {{ styleClass }}"
  [ngClass]="{ compact: isCompact, banner: isBanner, disabled: !isServiceAvailable || disabled }"
>
  <div class="serviceItem__startCont">
    <img *ngIf="isLogoVisible" class="serviceLogo" [src]="logo" alt="" />
    <div *ngIf="!isCompact">
      <h4 class="text-base lg:text-lg font-bold pb-2">{{ label }} <span *ngIf="isBanner">account required</span></h4>
      <p class="text-xs lg:text-sm max-w-30rem">
        {{ description }}
        <a *ngIf="learnMoreLink && !isCompact && !isBanner" [href]="learnMoreLink" class="font-bold text-main"
          >Learn more</a
        >
      </p>
      <p *ngIf="disclaimer" class="text-xs text-light pt-2">
        {{ disclaimer }}
      </p>
    </div>
    <div *ngIf="isCompact">
      <p *ngIf="account" class="compactAccountStatus">
        Your {{ label }} account:
        <span class="compactAccountStatus__name">{{ account }}</span>
      </p>
      <p *ngIf="!account" class="compactAccountStatus">You don’t have a linked {{ label }} account</p>
    </div>
  </div>
  <div class="serviceItem__endCont">
    <div class="serviceItem__actionsCont">
      <a
        pButton
        *ngIf="learnMoreLink && !account && (isCompact || isBanner)"
        class="outline"
        [ngClass]="{ small: isCompact }"
        [href]="learnMoreLink"
      >
        Why do I need this?
      </a>
      <ng-container *ngIf="isDemo">
        <app-info-button [pTooltip]="infoTooltip" (click)="onInfoButtonClick($event)"></app-info-button>
      </ng-container>
      <ng-container *ngIf="!isDemo">
        <button
          pButton
          *ngIf="!account"
          type="button"
          [ngClass]="{ small: isCompact }"
          [disabled]="!isServiceAvailable || disabled"
          (click)="onAddAccount()"
        >
          Add account
        </button>
        <button
          pButton
          *ngIf="account"
          type="button"
          class="outline square"
          [ngClass]="{ small: isCompact }"
          [disabled]="!isServiceAvailable || disabled"
        >
          <fa-icon [icon]="faCog"></fa-icon>
        </button>
      </ng-container>
    </div>
    <p *ngIf="account && !isCompact && isServiceAvailable" class="accountStatus">
      <span class="srOnly">Your account:</span>
      {{ account }}
    </p>
    <p *ngIf="!isServiceAvailable" class="serviceStatus">
      <span class="statusDot danger"></span>
      <span>Service currently unavailable</span>
      <fa-icon [icon]="faQuestionCircle"></fa-icon>
    </p>
  </div>
  <p-template *ngIf="isInfoAlertVisible" class="block w-full">
    <alert color="main" [icon]="faCircleInfo" [label]="infoAlertTitle" [description]="infoAlertDescription"></alert>
  </p-template>
</div>
<ng-content></ng-content>
