import { Component, EventEmitter, Output } from "@angular/core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { AppState } from "src/app/shared/services/app-state";

@Component({
  selector: "app-register-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.scss"],
})
export class TermsComponent {
  @Output() nextClicked = new EventEmitter<void>();

  isRoofingAgreed: boolean;

  faArrowRight = faArrowRight;
  constructor(appState: AppState) {
    this.isRoofingAgreed = appState.hasAcceptedTermsOfService;
  }

  canGoNext(): boolean {
    return this.isRoofingAgreed;
  }

  onNextClick() {
    this.canGoNext() && this.nextClicked.emit();
  }
}
