<p-header>
  <h2 class="font-light text-2xl">Obtaining dimensions data</h2>
</p-header>
<p class="text-sm mt-3 mb-4">Visualize how we create your takeoff report, step-by-step:</p>

<div class="flex flex-column align-items-center mb-5">
  <div
    class="border-1 border-outline py-3 px-4 flex align-items-center justify-content-center font-semibold lineAfter"
    [ngClass]="{ 'animated-line': currentStep === 1 }"
  >
    <fa-icon size="lg" class="text-main" [icon]="faCircleUser"></fa-icon>
    <span class="text-sm ml-4">You</span>
  </div>
  <div
    class="border-1 mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold lineAfter relative"
    [ngClass]="{
      'border-main': currentStep === 1 || currentStep === 3,
      'border-outline': !(currentStep === 1 || currentStep === 3),
      'animated-line': currentStep === 1 || currentStep === 3
    }"
  >
    <img src="assets/img/logo/rfLogo_icon.svg" alt="Roofing Passport" class="w-2rem" />
    <span class="text-sm ml-4">Roofing Passport</span>
    <fa-icon *ngIf="currentStep === 1 || currentStep === 3" [icon]="faGear" class="icon gearIcon"></fa-icon>
  </div>
  <div class="relative w-full flex flex-column align-items-center">
    <div
      class="border-1 mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold lineAfter"
      [ngClass]="{
        'border-main': currentStep === 2,
        'border-outline': currentStep !== 2,
        'animated-line': currentStep === 2
      }"
    >
      <fa-icon
        *ngIf="currentStep === 1 || currentStep === 3"
        [icon]="faPaperPlane"
        class="icon paperPlaneIcon"
        [ngClass]="{ 'animated-line': currentStep === 2 }"
      ></fa-icon>
      <img src="assets/img/icons/source_ev.png" alt="EagleView" class="w-2rem" />
      <span class="text-sm ml-4">EagleView</span>
      <fa-icon *ngIf="currentStep === 2" [icon]="faGear" class="icon gearIcon"></fa-icon>
      <fa-icon *ngIf="currentStep === 2" [icon]="faCamera" class="icon cameraIcon"></fa-icon>
    </div>
    <div
      class="border-1 border-outline mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold border-outline nonSelectedService"
    >
      <img src="assets/img/icons/source_rw.png" alt="Roofing WRX" class="w-2rem" />
      <span class="text-sm ml-4">Roofing WRX</span>
    </div>
  </div>
  <div class="border-1 border-outline mt-5 py-3 px-4 flex align-items-center justify-content-center font-semibold">
    <fa-icon size="lg" class="text-main" [icon]="faLocationDot"></fa-icon>
    <span class="text-sm ml-4">Job Location</span>
  </div>
</div>

<p-divider></p-divider>
<h4 class="my-3 font-semibold text-xs">Step {{ currentStep }}</h4>
<p class="text-sm" [ngSwitch]="currentStep">
  <ng-container *ngSwitchCase="1">
    Once you confirm the new job, the <span class="font-semibold">location data</span> you enter is processed by Roofing
    Passport and sent to EagleView.
  </ng-container>
  <ng-container *ngSwitchCase="2">
    EagleView then retrieves
    <span class="font-semibold">raw data of roof measurements and roof pitch</span> from the specified address. If data
    needs to be updated, EagleView sends a drone to the job location.
  </ng-container>
  <ng-container *ngSwitchCase="3">
    EagleView sends <span class="font-semibold">dimensions data</span> back to Roofing Passport, which translates in
    into usable data for the takeoff report.
  </ng-container>
</p>
<div class="flex justify-content-between mt-4">
  <button *ngIf="currentStep > 1" pButton class="default outline text-main border-main" (click)="previousStep()">
    <fa-icon [icon]="faArrowLeft"></fa-icon>
    Back
  </button>
  <div class="ml-auto">
    <button *ngIf="currentStep !== 3" pButton class="default outline mr-3 text-main border-main" (click)="skip()">
      Skip
    </button>
    <button pButton (click)="nextStep()">
      {{ currentStep === 3 ? "Continue" : "Next" }}
      <fa-icon [icon]="faArrowRight"></fa-icon>
    </button>
  </div>
</div>
