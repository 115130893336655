<div #mainSideMenu class="rpSidebar" [ngClass]="{'collapsed' : isCollapsed}">
  <button #mainSideMenuToggleBtn (click)="toggleSidebar()" class="rpSidebar__collapseBtn" title="Collapse/Expand" aria-label="Collapse/Expand sidebar">
    <fa-icon [icon]="faChevronLeft"></fa-icon>
  </button>
  <div class="rpSidebar__titleCont">
    <h1 *ngIf="headerText" class="title3 rpSidebar__title">{{ headerText }}</h1>
  </div>
  <nav>
    <ul class="rpSidebar__list">
      <li *ngFor="let item of menuItems">
        <a routerLink="{{item.routerLink}}" class="rpSidebar__link" [ngClass]="{'active': item.state === true}">
          <fa-icon [icon]="item.iconStyle" class="rpSidebar__link__icon"></fa-icon>
          <span class="rpSidebar__link__label">{{ item.label }}</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
