import { Component } from "@angular/core";
import { faUserCircle, faEnvelope, faTimes } from "@fortawesome/free-solid-svg-icons";
import { AppState } from "src/app/shared/services/app-state";

@Component({
  selector: "app-account-settings-companies",
  templateUrl: "./companies.component.html",
  styleUrls: ["./companies.component.scss"],
})
export class CompaniesComponent {
  faUserCircle = faUserCircle;
  faEnvelope = faEnvelope;
  faTimes = faTimes;
  profiles = this.appState.profiles;
  activeProfileId: number = this.appState.companyId;

  constructor(private appState: AppState) {}
}
