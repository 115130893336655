import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-intro-modal",
  templateUrl: "./intro-modal.component.html",
  styleUrls: ["./intro-modal.component.scss"],
})
export class IntroModalComponent implements OnInit {
  isVisible: boolean = false;
  faInfoCircle = faInfoCircle;
  email: string;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  open(email: string) {
    this.isVisible = true;
    this.email = email;
  }

  startNewJobDemo() {
    this.isVisible = false;
    this.router.navigate(["/demo/create-job"], { queryParams: { email: this.email } });
  }
}
