import { Component, Input, Output, EventEmitter } from "@angular/core";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-dialog-smartbuild-step",
  templateUrl: "./dialog-smartbuild-step.component.html",
  styleUrls: ["./dialog-smartbuild-step.component.scss"],
})
export class DialogSmartbuildStepComponent {
  @Input() isVisible: boolean = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();

  currentStep: number = 1;

  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;

  closeModal = () => {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  };

  nextStep() {
    if (this.currentStep < 2) {
      this.currentStep++;
    } else {
      this.closeModal();
    }
  }

  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  skip() {
    this.closeModal();
  }

  constructor() {}
}
