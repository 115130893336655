import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ApiEndpoints } from "../constants/api-endpoints";
import { AuthenticationService } from "./authentication.service";
import { AppState } from "./app-state";

@Injectable({
  providedIn: "root",
})
export class RulesEngineService {
  constructor(
    private httpClient: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService
  ) {}

  getRoofingSystems(companyId?: number): Observable<RoofingSystem[]> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getRoofingSystems(companyId)}`;

    return this.httpClient.get<RoofingSystem[]>(url, { headers }).pipe(
      map((response) => response),
      catchError((_err) => of([]))
    );
  }

  getRoofingSystemInputs(roofingSystemId?: number): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getRoofingSystemInputs(roofingSystemId)}`;

    return this.httpClient.get(url, { headers }).pipe(
      map((response) => response),
      catchError((_err) => of([]))
    );
  }
}

type RoofingSystem = {
  id: number;
  shortName: string;
  description: string;
};
