<p-toast [style]="{ marginBottom: '10px' }" position="bottom-right">
  <ng-template let-message pTemplate="message">
    <div class="toastContent">
      <fa-icon *ngIf="message.severity == 'info'" [icon]="faInfoCircle" class="toastIcon"></fa-icon>
      <fa-icon *ngIf="message.severity == 'success'" [icon]="faCheckCircle" class="toastIcon"></fa-icon>
      <fa-icon *ngIf="message.severity == 'warn'" [icon]="faExclamationCircle" class="toastIcon"></fa-icon>
      <fa-icon *ngIf="message.severity == 'error'" [icon]="faWarning" class="toastIcon"></fa-icon>
      <div class="toastTextCont">
        <h4 class="toastTitle">{{message.summary}}</h4>
        <p>{{message.detail}}</p>
      </div>
    </div>
  </ng-template>
</p-toast>
