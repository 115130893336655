import { Component, OnInit } from "@angular/core";
import { faArrowRight, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { AdminTask } from "src/app/core/data/models/AdminTask";
import { ISortableRequest } from "src/app/interfaces/sortable-request";
import { TaskService } from "src/app/shared/services/task.service";
@Component({
  selector: "work-queue",
  templateUrl: "./work-queue.component.html",
  styleUrls: ["./work-queue.component.scss"],
})
export class WorkQueueComponent implements OnInit {
  constructor(private taskService: TaskService) { }

  tasks: AdminTask[] = [];
  faArrowRight = faArrowRight;
  faBuilding = faBuilding;
  isVisible: boolean = false;
  selectedTask: AdminTask = {} as AdminTask;
  sortBy: ISortableRequest =
    {
      SortBy: 4,
      SortDescending: true
    };
  pageSize: number = 10;
  totalCount: number = 10;
  totalPages: number = 1;
  pageNumber: number = 1;

  ngOnInit(): void {
    this.loadTasks();
  }

  onPageChange(page: number) {
    this.pageNumber = page;
    this.loadTasks();
  }

  onPageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.loadTasks();
  }

  loadTasks(): void {
    this.taskService.getTasks(this.pageNumber, this.pageSize, this.sortBy).subscribe({
      next: (tasks) => {
        this.tasks = tasks;
        this.tasks.forEach((x) => (x.typeDisplay = x.type.split(/(?=[A-Z])/).join(" ")));

        // this.newCompanyCount = tasks.filter((x) => x.typeId === AdminTaskTypeIds.NewCompany).length;
        // this.newUserCount = tasks.filter((x) => x.typeId === AdminTaskTypeIds.NewUser).length;
      },
      error: (err) => { },
    });
  }
  sortByColumn(column: string) {
    switch (column) {
      case 'Date':
        this.sortBy.SortBy = 4;
        break;
      case 'Type':
        this.sortBy.SortBy = 5;
        break;
      case 'Company':
        this.sortBy.SortBy = 1;
        break;
      case 'User':
        this.sortBy.SortBy = 2;
        break;

      default:
        break;
    }
    this.sortBy.SortDescending = !this.sortBy.SortDescending;
    this.loadTasks();
  }

  viewTask(task: AdminTask) {
    this.selectedTask = task;
    this.isVisible = true;
  }
}
