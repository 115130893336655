export enum JobStatusEnum {
  "Job Started" = "mono",
  "Job Created" = "mono",
  "Job Completed" = "main",
  "TakeOff Report Generating" = "warning",
  "TakeOff Report Completed" = "success",
  "Dimension Data Generating" = "warning",
  "Dimension Data Completed" = "success",
  "Information Required" = "danger",
  "Job Canceled" = "danger",
  "Job Failed" = "danger",
}

export enum JobStatusIdEnum {
  "Job Started" = 1,
  "Dimension Data Generating" = 2,
  "Dimension Data Completed" = 3,
  "TakeOff Report Generating" = 4,
  "TakeOff Report Completed" = 5,
  "Job Completed" = 6,
  "Information Required" = 7,
  "Job Failed" = 8,
  "Job Canceled" = 9,
  "Job Created" = 10,
}
