import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-clear-filters-button",
  templateUrl: "./clear-filters-button.component.html",
  styleUrls: ["./clear-filters-button.component.scss"],
})
export class ClearFiltersButtonComponent implements OnInit {
  faFilterCircleXmark = faFilterCircleXmark;

  @Output() onClick = new EventEmitter<void>();
  @Input() class = "";

  constructor() {}

  ngOnInit(): void {}

  handleClick(): void {
    this.onClick.emit();
  }
}
