import { NgModule } from "@angular/core";
import { FaqDialogComponent } from "./faq-dialog/faq-dialog.component";
import { NotificationComponent } from "./notification/notification.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { HelpComponent } from "./help/help.component";
import { CoreModule } from "../core/core.module";
import { CommonModule } from "@angular/common";
import { OrdersModule } from "./orders/orders.module";
import { NotificationService } from "../shared/services/notification.service";
import { AdminModule } from "./admin/admin.module";
import { FeaturesComponent } from "./features.component";
import { SharedModule } from "../shared/shared.module";
import { FeaturesRoutingModule } from "./features-routing.module";
import { AccountSettingsModule } from "./account-settings/account-settings.module";
import { RegisterAccountModule } from "./register-account/register-account.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { CompanySettingsModule } from "./company-settings/company-settings.module";
import { SelectCompanyComponent } from "./select-company/select-company.component";
import { CompanyAffiliationComponent } from "./company-affiliation/company-affiliation.component";
import { DemoComponent } from "./demo/demo.component";
import { DemoHeaderComponent } from "./demo/demo-header/demo-header.component";
import { DemoModule } from "./demo/demo.module";

@NgModule({
  declarations: [
    FeaturesComponent,
    FaqDialogComponent,
    NotificationComponent,
    ConfirmDialogComponent,
    HelpComponent,
    SelectCompanyComponent,
    CompanyAffiliationComponent,
    DemoComponent,
    DemoHeaderComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    SharedModule,
    OrdersModule,
    AdminModule,
    DemoModule,
    FeaturesRoutingModule,
    AccountSettingsModule,
    RegisterAccountModule,
    FontAwesomeModule,
    CompanySettingsModule,
  ],
  exports: [
    FaqDialogComponent,
    NotificationComponent,
    ConfirmDialogComponent,
    HelpComponent,
    FontAwesomeModule,
    DemoHeaderComponent,
  ],
  providers: [NotificationService],
})
export class FeaturesModule {}
