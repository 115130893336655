import { Component, Input } from "@angular/core";
import {
  faCircleUser,
  faLocationDot,
  faArrowRight,
  faArrowLeft,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-xml-first-step",
  templateUrl: "./xml.component.html",
  styleUrls: ["./xml.component.scss"],
})
export class XmlComponent {
  @Input() onClose: () => void;

  currentStep: number = 1;

  faCircleUser = faCircleUser;
  faLocationDot = faLocationDot;
  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  faPaperPlane = faPaperPlane;

  constructor() {}

  nextStep() {
    if (this.currentStep < 2) {
      this.currentStep++;
    } else {
      this.onClose();
    }
  }

  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  skip() {
    this.onClose();
  }
}
