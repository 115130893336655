<div class="container container_steps">
  <div class="pageHeading pageHeading_center columnMobileOnly">
    <h1 class="pageTitle pageTitle_steps title2 my-0">Create new job</h1>

    <p *ngIf="representedUser && representedUser.name !== null" class="flex align-items-center gap-2 mx-4 md:mx-0">
      <span class="text-xs">Create for:</span>
      <status-badge color="mono" [icon]="faUser" [text]="representedUser.name"></status-badge>
    </p>
  </div>

  <p-accordion
    *ngIf="!completedStep[0] || !completedStep[1] || !completedStep[2] || !completedStep[3]"
    [multiple]="false"
  >
    <!-- Step 0 -->
    <step-card
      id="jobStep_0"
      number="1"
      label="Expected outcome"
      [selected]="activeStep[0]"
      textSelected="What do you need to obtain?"
      [completed]="completedStep[0]"
      [textCompleted]="
        isRoofingPassportEngine()
          ? 'Get a takeoff report, processed by Roofing Passport'
          : 'Get a takeoff report and edit in SmartBuild'
      "
    >
      <h2 class="srOnly">Expected outcome</h2>

      <section class="mb-4">
        <option-box
          label="Get a takeoff report, processed by Roofing Passport"
          subtitle="Generate a takeoff ready for estimation and ordering."
          [id]="'jobExpectedOutcomeOption_1'"
          name="jobExpectedOutcome"
          [value]="EXPECTED_OUTCOME.takeOffReport"
          [selectedValue]="expectedOutcome"
          (optionSelected)="selectExpectedOutcome(EXPECTED_OUTCOME.takeOffReport)"
          [checked]="expectedOutcome === EXPECTED_OUTCOME.takeOffReport"
          styleClass="expectedOutcomeOptionBox"
        ></option-box>
        <option-box
          label="Get a takeoff report and edit in SmartBuild"
          subtitle="After generating your takeoff report, you can use SmartBuild to accurately estimate material and labor costs."
          [id]="'jobExpectedOutcomeOption_2'"
          name="jobExpectedOutcome"
          [value]="EXPECTED_OUTCOME.takeOffReportAndEditInSmartBuild"
          [selectedValue]="expectedOutcome"
          (optionSelected)="selectExpectedOutcome(EXPECTED_OUTCOME.takeOffReportAndEditInSmartBuild)"
          [checked]="expectedOutcome === EXPECTED_OUTCOME.takeOffReportAndEditInSmartBuild"
          styleClass="mt-4 expectedOutcomeOptionBox"
          [ngClass]="{ disabledOptionBox: !isSmartBuildActive }"
        ></option-box>
        <div
          class="border-bottom-1 border-right-1 border-left-1 border-outline flex justify-content-between p-3"
          [ngClass]="{ 'bg-bkg': !isSmartBuildActive }"
        >
          <div class="flex align-items-center gap-2">
            <img src="assets/img/icons/source_sb.png" alt="SmartBuild" class="w-3rem" />
            <p class="text-xs">You {{ hasSmartBuildEnabled ? "have" : "don't have" }} a linked SmartBuild account.</p>
          </div>
          <div class="flex column-gap-3 align-items-center">
            <a
              *ngIf="!hasSmartBuildEnabled && isSmartBuildActive"
              pButton
              type="button"
              class="fullMobile"
              routerLink="/account-settings/integrations"
              >Add account</a
            >
            <div *ngIf="!isSmartBuildActive" class="flex align-items-center">
              <status-badge color="transparent" text="SmartBuild is currently unavailable"></status-badge>
              <fa-icon
                [icon]="faQuestionCircle"
                pTooltip="SmartBuild is temporarily unavailable. You can proceed with creating a new job using RP Engine, or try again later when the service is restored."
              ></fa-icon>
            </div>
          </div>
        </div>
      </section>

      <div class="divider mt-4 mb-4"></div>
      <section class="footActionsCont spaced">
        <button pButton type="button" class="fullMobile" (click)="changeStep(0, 1, false, true)">
          Next
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </button>
      </section>
    </step-card>

    <!-- Step 1 -->
    <step-card
      id="jobStep_1"
      number="2"
      label="Data source"
      [selected]="activeStep[1]"
      [textSelected]="'Pick the method you want to use to obtain dimensions data:'"
      [completed]="completedStep[1]"
      [textCompleted]="getTextCompletedStep1()"
      [iconCompleted]="iconCompleted"
    >
      <h2 class="srOnly">Data source</h2>

      <section *ngIf="isRoofingPassportEngine()" class="mb-4 w-full">
        <div class="w-full lg:w-24rem lg:mr-3 mb-2 lg:mb-0">
          <label for="jobInput_roofingSystem" class="label">Roofing System</label>
          <p-dropdown
            [options]="roofingSystems"
            placeholder="Select Roofing System"
            [(ngModel)]="roofingSystem"
            id="jobInput_roofingSystem"
            required
            class="w-full"
          ></p-dropdown>
          <label id="roofingSystem-help" class="p-invalid" for="jobInput_roofingSystem" *ngIf="roofingSystemHasError">
            <fa-icon [icon]="faTriangleExclamation"></fa-icon>
            Please select a roofing system
          </label>
        </div>
        <form [formGroup]="jobDimensions" class="w-full grid mt-3">
          <ng-container *ngFor="let input of roofingSystemInputs">
            <div class="col-12 lg:col-3">
              <label [for]="input.inputName" class="label">{{ input.label }}</label>
              <p-inputNumber
                [id]="input.inputName"
                [formControlName]="input.inputName"
                type="number"
                class="dimensionInput"
                [suffix]="' ' + input.suffix"
                [useGrouping]="false"
              ></p-inputNumber>
            </div>
          </ng-container>
        </form>
      </section>

      <section class="optionsCont mb-4">
        <h3 class="srOnly">Select source</h3>
        <option-box
          [id]="'jobDataSourceOption_1'"
          name="jobDataSource"
          value="option1"
          [selectedValue]="step1SelectedOption"
          (optionSelected)="selectJobDataSource(1)"
          [checked]="true"
          [icon]="faLocationDot"
          label="Address"
        >
          <span>Obtain dimensions data from any location based on accurate aerial views provided by EagleView.</span>
        </option-box>
        <option-box
          [id]="'jobDataSourceOption_2'"
          name="jobDataSource"
          value="option2"
          [selectedValue]="step1SelectedOption"
          (optionSelected)="selectJobDataSource(2)"
          [icon]="faFile"
          label="XML File"
        >
          <span>Bring your own XML roofing geometry file from a third party.</span>
        </option-box>
        <option-box
          [id]="'jobDataSourceOption_3'"
          name="jobDataSource"
          value="option3"
          [selectedValue]="step1SelectedOption"
          (optionSelected)="selectJobDataSource(3)"
          [icon]="faMap"
          label="Blueprints"
          subtitle="& other documents"
        >
          <span
            >Upload any plans and documents related to your project. This data source is only supported by Roofing
            WRX.</span
          >
        </option-box>
      </section>

      <section class="grid" *ngIf="step1SelectedOption === 'option1' && isEagleViewActive">
        <h3 class="srOnly">Address settings</h3>
        <div class="col-12 md:col-6" [formGroup]="newJobLocation">
          <div class="inputCont">
            <label for="jobInput_jobName1" class="label">Job name</label>
            <input
              pInputText
              id="jobInput_jobName1"
              type="text"
              name="name"
              class="w-full"
              placeholder="Job name"
              formControlName="name"
              (input)="jobNameExists = false"
            />
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_jobName1"
              *ngIf="locationFormJobName.invalid && (locationFormJobName.touched || locationFormJobName.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              <ng-container *ngIf="locationFormJobName.errors?.maxlength; else defaultError">
                Job name should not exceed 29 characters.
              </ng-container>
              <ng-template #defaultError> Please enter a job name </ng-template>
            </label>
          </div>
          <div class="inputCont">
            <label for="jobInput_jobType1" class="label">Job Type</label>
            <p-dropdown
              [options]="jobTypes"
              optionLabel="name"
              class="w-full"
              placeholder="Select job type"
              formControlName="jobType"
              optionValue="name"
              id="jobInput_jobType1"
            ></p-dropdown>
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_jobType1"
              *ngIf="locationFormJobType.invalid && (locationFormJobType.touched || locationFormJobType.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please select a job type</label
            >
          </div>
          <div class="inputCont">
            <label for="jobInput_address" class="label">Address</label>
            <input
              pInputText
              id="jobInput_address"
              type="text"
              name="middletName"
              class="w-full"
              placeholder="Address"
              formControlName="address"
              (change)="adressChange()"
            />
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_address"
              *ngIf="locationFormAddress.invalid && (locationFormAddress.touched || locationFormAddress.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please enter an address</label
            >
          </div>
          <div class="inputCont">
            <label for="jobInput_state" class="label">State/province</label>
            <p-dropdown
              [options]="stateList"
              optionLabel="name"
              class="w-full"
              placeholder="Select state"
              formControlName="state"
              optionValue="name"
              id="jobInput_state"
              optionValue="abbreviation"
              (onChange)="adressChange()"
            ></p-dropdown>
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_state"
              *ngIf="locationFormState.invalid && (locationFormState.touched || locationFormState.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please enter a state</label
            >
          </div>
          <div class="inputCont">
            <label for="jobInput_city" class="label">City</label>
            <input
              pInputText
              id="jobInput_city"
              class=""
              type="text"
              name="middletName"
              class="w-full"
              placeholder="City"
              formControlName="city"
              (change)="adressChange()"
            />
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_city"
              *ngIf="locationFormCity.invalid && (locationFormCity.touched || locationFormCity.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please enter a city
            </label>
          </div>
          <div class="inputCont">
            <label for="jobInput_ZIPCode" class="label">ZIP code</label>
            <input
              pInputText
              id="jobInput_ZIPCode"
              type="text"
              name="middletName"
              formControlName="zip"
              class="w-full max-w-10rem"
              placeholder="ZIP code"
              (change)="adressChange()"
            />
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_ZIPCode"
              *ngIf="locationFormZip.invalid && (locationFormZip.touched || locationFormZip.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please enter a ZIP code
            </label>
          </div>
          <div class="inputCont">
            <p-checkbox
              inputId="jobInput_changedRecentCheck"
              name="groupname"
              [binary]="true"
              formControlName="changesInLast4Years"
            ></p-checkbox>
            <label for="jobInput_changedRecentCheck" class="label_check"> Changed in the last 4 years </label>
          </div>
          <button pButton type="button" class="outline" (click)="findAddress()">
            <fa-icon [icon]="faSearch"></fa-icon>
            Locate address
          </button>
        </div>
        <div class="col-12 md:col-6">
          <iframe
            id="pictometry_ipa"
            [src]="pictometryUrl | safe: 'resourceUrl'"
            height="100%"
            width="100%"
            style="border: 0px"
            title="EagleView Map"
          ></iframe>
        </div>
      </section>

      <section *ngIf="step1SelectedOption === 'option1' && !isEagleViewActive">
        <h3 class="srOnly">Address settings</h3>
        <app-service-item
          [label]="'EagleView'"
          [description]="
            'You need an EagleView account in order to use addresses as a data source. Please create or link an existing EagleView account to continue, or try using a different data source.'
          "
          [disclaimer]="'At this time, EagleView provides data for buildings in the United States and Canada only.'"
          [logo]="'assets/img/icons/source_ev.png'"
          [isServiceAvailable]="true"
          [isBanner]="true"
        >
        </app-service-item>
      </section>

      <section *ngIf="step1SelectedOption === 'option2'">
        <h3 class="srOnly">XML settings</h3>
        <div class="grid">
          <div class="col-12 md:col-6" [formGroup]="newJobXML">
            <div class="inputCont">
              <label for="jobInput_jobName2" class="label">Job name</label>
              <input
                pInputText
                id="jobInput_jobName2"
                type="text"
                name="jobName"
                class="w-full"
                formControlName="name"
                placeholder="Job name"
                (input)="jobNameExists = false"
              />
              <label
                id="username2-help"
                class="p-invalid"
                for="jobInput_jobName2"
                *ngIf="xmlFormJobName.invalid && (xmlFormJobName.touched || xmlFormJobName.dirty)"
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                <ng-container *ngIf="xmlFormJobName.errors?.maxlength; else defaultError">
                  Job name should not exceed 29 characters.
                </ng-container>
                <ng-template #defaultError> Please enter a job name </ng-template>
              </label>
            </div>
            <div class="inputCont">
              <label for="jobInput_jobType2" class="label">Job Type</label>
              <p-dropdown
                [options]="jobTypes"
                optionLabel="name"
                class="w-full"
                placeholder="Select job type"
                formControlName="jobType"
                id="jobInput_jobType2"
                optionValue="name"
              ></p-dropdown>
              <label
                id="username2-help"
                class="p-invalid"
                for="jobInput_jobType2"
                *ngIf="xmlFormJobType.invalid && (xmlFormJobType.touched || xmlFormJobType.dirty)"
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                Please select a job type
              </label>
            </div>
            <div class="inputCont">
              <label for="jobInput_branchId2" class="label">Branch ID</label>
              <p-dropdown
                [options]="branches"
                optionLabel="name"
                optionValue="id"
                class="w-full"
                placeholder="Select Branch ID"
                formControlName="branch_Id"
                id="jobInput_branchId2"
              ></p-dropdown>
              <label
                id="branchId-help"
                class="p-invalid"
                for="jobInput_branchId2"
                *ngIf="
                  newJobXML.get('branch_Id').invalid &&
                  (newJobXML.get('branch_Id').touched || newJobXML.get('branch_Id').dirty)
                "
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                Please select a Branch ID
              </label>
            </div>
            <div class="pt-4" *ngIf="NEW_JOB_FEATURE_FLAGS.PROCESS_MANUALLY">
              <p-checkbox
                inputId="jobInput_processManuallyXml"
                name="groupname"
                [binary]="true"
                formControlName="processManually"
              ></p-checkbox>
              <label for="jobInput_processManuallyXml" class="label_check">Process Manually</label>
              <p class="text-xs max-w-24rem pt-2 text-light">
                By choosing this option, your job/order will be sent to RoofingWRX for manual processing and
                verification of the output.
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <file-drop-input
              [id]="'jobInput_fileXML'"
              name="jobInput_fileXML"
              accept=".xml"
              acceptText="XML"
              label="XML file"
              (change)="fileUpload($event)"
              (drop)="drop($event)"
              [fileList]="uploadFiles"
              (removeFile)="removeFile($event)"
            ></file-drop-input>
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_fileXML"
              *ngIf="step1SelectedOption === 'option2' && newJobXML.invalid && uploadFiles.length === 0"
              >Please upload your XML file.
            </label>
            <small class="inputDescription">
              The XML file must be formatted with 3D point, edge, and face information. XML takeoffs from EagleView,
              TopView (v9.19 or later) and Hover are also compatible.
            </small>
          </div>
        </div>
      </section>

      <section *ngIf="step1SelectedOption === 'option3'">
        <h3 class="srOnly">Blueprints settings</h3>
        <div class="grid">
          <div class="col-12 md:col-6" [formGroup]="newJobBluePrint">
            <div class="inputCont">
              <label for="jobInput_jobName3" class="label">Job name</label>
              <input
                pInputText
                id="jobInput_jobName3"
                type="text"
                name="jobName"
                class="w-full"
                placeholder="Job name"
                formControlName="name"
                (input)="jobNameExists = false"
              />
              <label
                id="username2-help"
                class="p-invalid"
                for="jobInput_jobName3"
                *ngIf="bluePrintsFormJobName.invalid && (bluePrintsFormJobName.touched || bluePrintsFormJobName.dirty)"
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                <ng-container *ngIf="bluePrintsFormJobName.errors?.maxlength; else defaultError">
                  Job name should not exceed 29 characters.
                </ng-container>
                <ng-template #defaultError> Please enter a job name </ng-template>
              </label>
            </div>
            <div class="inputCont">
              <label for="jobInput_jobType1" class="label">Job Type</label>
              <p-dropdown
                [options]="jobTypes"
                optionLabel="name"
                class="w-full"
                placeholder="Select job type"
                formControlName="jobType"
                id="jobInput_jobType1"
                optionValue="name"
              ></p-dropdown>
              <label
                id="username2-help"
                class="p-invalid"
                for="jobInput_jobType1"
                *ngIf="bluePrintsFormJobType.invalid && (bluePrintsFormJobType.touched || bluePrintsFormJobType.dirty)"
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                Please select a job type
              </label>
            </div>
            <div class="inputCont">
              <label for="jobInput_branchId3" class="label">Branch ID</label>
              <p-dropdown
                [options]="branches"
                optionLabel="name"
                optionValue="id"
                class="w-full"
                placeholder="Select Branch ID"
                formControlName="branch_Id"
                id="jobInput_branchId3"
              ></p-dropdown>
              <label
                id="branchId-help"
                class="p-invalid"
                for="jobInput_branchId3"
                *ngIf="bluePrintsBranchId.invalid && (bluePrintsBranchId.touched || bluePrintsBranchId.dirty)"
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                Please select a Branch ID
              </label>
            </div>
            <div class="inputCont">
              <p class="label">Pricing model</p>
              <div class="inputCont_check">
                <p-radioButton
                  inputId="jobInput_structure_single"
                  name="pricingmodel"
                  formControlName="pricingmodel"
                  value="Single"
                ></p-radioButton>
                <label for="jobInput_structure_single" class="label_check">Single structure</label>
              </div>
              <div class="inputCont_check">
                <p-radioButton
                  inputId="jobInput_structure_multiple"
                  name="pricingmodel"
                  formControlName="pricingmodel"
                  value="Multi"
                ></p-radioButton>
                <label for="jobInput_structure_multiple" class="label_check">Multiple structures</label>
              </div>
              <p
                id="username2-help"
                class="p-invalid"
                *ngIf="
                  bluePrintsPricingmodel.invalid && (bluePrintsPricingmodel.touched || bluePrintsPricingmodel.dirty)
                "
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                Please select a pricing model
              </p>
            </div>
            <!-- <div class="inputCont">
              <label for="jobInput_coments" class="label">Comments</label>
              <textarea
                pInputTextarea
                id="jobInput_coments"
                class="w-full"
                placeholder="Comments"
                formControlName="comments"
              ></textarea>
            </div> -->
          </div>
          <div class="col-12 md:col-6">
            <file-drop-input
              [id]="'jobInput_fileXML'"
              name="jobInput_fileXML"
              [multiple]="true"
              accept=".pdf,image/png,image/jpeg,.xml"
              acceptText="PDF, JPG, PNG, XML"
              label="Project files"
              (change)="fileUpload($event)"
              (drop)="drop($event)"
              [fileList]="uploadFiles"
              (removeFile)="removeFile($event)"
            ></file-drop-input>
            <label
              id="username2-help"
              for="jobInput_fileXML"
              class="p-invalid"
              *ngIf="step1SelectedOption === 'option3' && newJobBluePrint.invalid && uploadFiles.length === 0"
              >Please upload your files.
            </label>
            <small class="inputDescription">
              Please include as many helpful documents as possible so your order can be processed in a timely manner.
              Valid documents may include:
              <ul>
                <li>Dimensioned Sketches (Slope or Ridge to Eave measurements included).</li>
                <li>Architectural Plans (Roof Plan, Dimensioned floor Plan and Elevations at a minimum).</li>
                <li>Aerial Photos (with a scale and all slopes included).</li>
                <li>Satellite Imagery (with a scale and all slopes included).</li>
              </ul>
            </small>
          </div>
        </div>
      </section>
      <alert
        *ngIf="isStep1Invalid()"
        color="danger"
        [icon]="faTriangleExclamation"
        [label]="'Couldn\'t set data source'"
        [description]="'Please check the marked fields and try again.'"
        [styleClass]="'mt-2'"
      >
      </alert>
      <alert
        *ngIf="showSearchResultMessageError === true"
        color="danger"
        [icon]="faTriangleExclamation"
        [label]="'Couldn\'t locate Address'"
        [description]="'Please check the marked fields and try again.'"
        [styleClass]="'mt-2'"
      >
      </alert>

      <alert
        *ngIf="jobNameExists"
        color="danger"
        [icon]="faTriangleExclamation"
        [label]="'Job name already exists'"
        [description]="'You already have another job with the same name. Please enter a new job name and try again.'"
        [styleClass]="'mt-2'"
      >
      </alert>
      <div class="divider mt-4 mb-4"></div>
      <section class="footActionsCont spaced">
        <button
          pButton
          [disabled]="
            jobNameExists ||
            (!isEVPaymentSetupValid && step1SelectedOption === 'option1') ||
            (!addressLocated && step1SelectedOption === 'option1') ||
            (step1SelectedOption === 'option3' && uploadFiles.length === 0)
          "
          type="button"
          class="fullMobile"
          (click)="changeStep(1, 2, false, true)"
        >
          Next
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </button>
        <button pButton class="default outline fullMobile" type="button" (click)="changeStep(1, 0, true)">
          <fa-icon [icon]="faArrowLeft"></fa-icon>
          Go back
        </button>
      </section>
    </step-card>

    <!-- Step 2 -->
    <step-card
      id="jobStep_3"
      [number]="3"
      label="Summary"
      [selected]="activeStep[2]"
      [textSelected]="'Please review your job and add any necessary information:'"
      [completed]="completedStep[2]"
      [textCompleted]="'Completed'"
    >
      <h2 class="srOnly">Summary</h2>
      <section class="mb-4">
        <div class="service-item">
          <img src="assets/img/icons/source_rw.png" class="service-item__image mr-4" alt="" />
          <div>
            <span for="" class="service-item__title mr-4">RoofingWRX</span>
            <div class="inline-flex">
              <span for="" class="service-item__account mr-4">Your RoofingWRX Account</span>
              <span for="" class="service-item__account font-bold mr-4">Account</span>
            </div>
          </div>
          <span class="service-item__icon">
            <button pButton type="button" class="outline square">
              <fa-icon [icon]="faCog"></fa-icon>
            </button>
          </span>
        </div>
        <div class="service-item">
          <img src="assets/img/icons/source_ev.png" class="service-item__image mr-4" alt="" />
          <div>
            <span for="" class="service-item__title mr-4">EagleView</span>
            <div class="inline-flex">
              <span for="" class="service-item__account mr-4">Your EagleView Account</span>
              <span for="" class="service-item__account font-bold mr-4">Account</span>
            </div>
          </div>
          <span class="service-item__icon">
            <button pButton type="button" class="outline square">
              <fa-icon [icon]="faCog"></fa-icon>
            </button>
          </span>
        </div>
      </section>

      <section>
        <div *ngIf="step1SelectedOption === 'option1'" class="inputCont">
          <label for="jobInput_code" class="label">Promotional code (optional)</label>
          <input pInputText type="text" [(ngModel)]="promoCode" id="jobInput_code" placeholder="Enter code" />
        </div>
        <div class="inputCont">
          <label for="jobComments" class="label">Comments</label>
          <textarea
            pInputTextarea
            id="jobComments"
            class="w-full"
            placeholder="Enter additional details"
            [(ngModel)]="jobComments"
          ></textarea>
        </div>
        <alert
          *ngIf="representedUser && representedUser.name !== null"
          color="main"
          [icon]="faUser"
          label="{{ representedUser.name }} will be the owner of this job"
          description="You're about to create a job in behalf of another user ({{
            representedUser.name
          }}). This user will see this job in their account and have full control of it. Please make sure the user you entered is correct and that you're not sharing sensitive information."
          styleClass="mb-4"
        >
        </alert>
      </section>
      <alert
        *ngIf="step4HasError"
        color="danger"
        [icon]="faTriangleExclamation"
        [label]="'Couldn\'t create job'"
        [description]="'Please check the marked fields and try again.'"
        [styleClass]="'mt-2'"
      >
      </alert>
      <div class="divider mb-4"></div>
      <section class="footActionsCont spaced">
        <button pButton type="button" class="success fullMobile" (click)="createJob()">
          <fa-icon [icon]="faCheck"></fa-icon>
          Create job
        </button>
        <button pButton type="button" class="outline default fullMobile" (click)="changeStep(2, 1, true)">
          <fa-icon [icon]="faArrowLeft"></fa-icon>
          Go back
        </button>
      </section>
    </step-card>
  </p-accordion>
</div>
