export const AppConstants = {
  RoofPassportOrderSuccess: "Order was successfully created.",
  RoofingPassportCommunicationError: "Error communicating with Roofing Passport services",

  USZipRegex: "^\\d{5}$",
  CAZipRegex: "^[ABCEGHJ-NPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z]([ -]?\\d[ABCEGHJ-NPRSTV-Z]\\d)?$",

  SmartBuildOutputReportErrors: {
    NoDefaultOutputMessage:
      "You do not have any outputs selected by default. Please contact support-roofingpassport@sherwin.com if you need help setting up these outputs.",
    UnauthorizedMessage:
      "There is an issue with your account set up preventing access to your jobs outputs. Please contact support-roofingpassport@sherwin.com.",
    ServerError: "There was an error generating your outputs. Please contact support-roofingpassport@sherwin.com",
  },

  ThirdPartyServiceName: {
    SmartBuild: "SmartBuild",
    EagleView: "EagleView",
    RoofingWRX: "Roofing WRX",
  },

  ThirdPartyServiceInfo: {
    SmartBuild:
      "The Sherwin-Williams Roofing Passport integrates with Keymark’s SmartBuild. For new users, you need to link your new SmartBuild account with your Roofing Passport account. To do this click the ‘Log in with SmartBuild’ button below to sign in to Smartbuild. Please use the SmartBuild credentials that are listed in the Welcome to Roofing Passport email you received when you were invited to join Roofing Passport.  <br/><br/>If you are a returning Roofing Passport customer, please reconnect your account. ",
    EagleView:
      "The Sherwin-Williams Roofing Passport integrates seamlessly with EagleView. We require a unique set of credentials from EagleView that are specific to the program. For new users, you need to link your new EagleView account with your Roofing Passport account. Click the ‘Log in with EagleView’ button below to sign in to EagleView. Please use the EagleView credentials that are listed in the Welcome to Roofing Passport email you received when you were invited to join Roofing Passport.  <br/><br/>If you are a returning Roofing Passport customer, please reconnect your account.",
    RoofingWRX:
      "The Sherwin-Williams Roofing Passport integrates with Roofing WRX. For new users, you need to link your new Roofing WRX account with your Roofing Passport account. To do this click the ‘Log in with Roofing WRX’ button below to sign in to Roofing WRX. Please use the Roofing WRX credentials that are listed in the Welcome to Roofing Passport email you received when you were invited to join Roofing Passport. <br/><br/>If you are a returning Roofing Passport customer, please reconnect your account. ",
  },

  HTTP_STATUS_CODES: {
    OK: 200,
    CREATED: 201,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    NOT_ACCEPTABLE: 406,
    CONFLICT: 409,
    UNPROCESSABLE_ENTITY: 422,
    INTERNAL_SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 503,
  },

  createJobErrors: {
    xml: {
      fileValidation:
        "The file you are attempting to upload is not able to be processed. Please note that in order to properly generate an accurate cut list, the data must be formatted with 3D point, edge, and face information. Files originating from EagleView, TopView (v9.19 or later), or Hover are known to show the required information.",
      generic: "Error creating job from XML.",
    },
  },
};
