import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { DemoComponent } from "./demo.component";
import { StartComponent } from "./start/start.component";
import { CreateJobComponent } from "./create-job/create-job.component";
import { CreateJobSuccessComponent } from "./create-job-success/create-job-success.component";
import { NewJobDemoComponent } from "./new-job-demo/new-job-demo.component";

const DemoRoutes: Routes = [
  {
    path: "demo",
    component: DemoComponent,
    children: [
      {
        path: "",
        title: "Demo - Roofing Passport",
        component: StartComponent,
      },
      {
        path: "create-job",
        component: CreateJobComponent,
        children: [
          { path: "", component: NewJobDemoComponent },
          { path: "success", component: CreateJobSuccessComponent },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(DemoRoutes)],
  exports: [RouterModule],
})
export class DemoRoutingModule {}
