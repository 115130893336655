<p-dialog
  header="Title"
  [(visible)]="isVisible"
  [dismissableMask]="true"
  styleClass="dialog_medium"
  [closable]="true"
  (onHide)="close()"
  [modal]="true"
  [draggable]="false"
>
  <ng-template pTemplate="header">
    <h3 class="title3">Review new account request</h3>
  </ng-template>
  <p class="mb-2 text-sm">A new {{ selectedTask.partnerSystem }} account has been requested for use with Roofing Passport:</p>
  <p-divider></p-divider>
  <p class="subtitle mb-3">User details</p>
  <div class="grid">
    <div class="col-3 col-offset-1">
      <p for="name" class="mb-2 text-sm text-right">Name</p>
      <p for="name" class="mb-2 text-sm text-right">Company</p>

      <p for="email" class="mb-2 text-sm text-right">Email</p>
      <p for="message" class="mb-2 text-sm text-right">Phone Number</p>
    </div>

    <div class="col-7">
      <p id="name" name="name" class="mb-2 text-sm font-semibold">{{ selectedTask.inviteeFullName }}</p>
      <p id="name" name="name" class="mb-2 text-sm font-semibold">{{ selectedTask.inviteeCompanyName }}</p>

      <p id="name" name="name" class="mb-2 text-sm font-semibold">{{ selectedTask.inviteeEmail }}</p>
      <p id="name" name="name" class="mb-2 text-sm font-semibold">{{ selectedTask.inviteePhoneNumber }}</p>
    </div>
  </div>
  <p-divider></p-divider>
  <p class="subtitle mb-3">Inviter details</p>
  <div class="grid">
    <div class="col-3 col-offset-1">
      <p for="name" class="mb-2 text-sm text-right">Name</p>
      <p for="name" class="mb-2 text-sm text-right">Company</p>

      <p for="email" class="mb-2 text-sm text-right">Email</p>
      <p for="message" class="mb-2 text-sm text-right">Phone Number</p>
    </div>

    <div class="col-7">
      <p id="name" name="name" class="mb-2 text-sm font-semibold">{{ selectedTask.inviterFullName }}</p>
      <p id="name" name="name" class="mb-2 text-sm font-semibold">{{ selectedTask.inviterCompanyName }}</p>

      <p id="name" name="name" class="mb-2 text-sm font-semibold">{{ selectedTask.inviterEmail }}</p>
      <p id="name" name="name" class="mb-2 text-sm font-semibold">{{ selectedTask.inviterPhoneNumber }}</p>
    </div>
  </div>
  <p-divider></p-divider>
  <div class="mb-4">
    <p class="mb-4 text-sm">Do you want to accept this request?</p>
    <div class="grid">
      <div class="col-6">
        <option-box
          [id]="'AcceptedOption_1'"
          name="inviteType"
          value="true"
          [selectedValue]="isAccepted"
          (optionSelected)="acceptOption(true)"
          [checked]="false"
          [icon]="faCheck"
          [iconColor]="'success'"
          label="Accept"
        >
        </option-box>
      </div>
      <div class="col-6">
        <option-box
          [id]="'AcceptedOption_2'"
          name="inviteType"
          value="false"
          [selectedValue]="!isAccepted"
          (optionSelected)="acceptOption(false)"
          [checked]="false"
          [icon]="faXmark"
          [iconColor]="'danger'"
          label="Reject"
        >
        </option-box>
      </div>
    </div>
  </div>
  <div class="mb-4" *ngIf="isAccepted === true">
    <p-divider></p-divider>
    <h4 class="title4 mb-3">Account Details</h4>
    <div class="inputCont">
      <label for="workQueueDetailsInput_username" class="label">Username</label>
      <input
        pInputText
        type="text"
        id="workQueueDetailsInput_username"
        class="w-full"
        placeholder="Username"
        [(ngModel)]="username"
      />
    </div>
    <div class="inputCont">
      <label for="inviteInput_bworkQueueDetailsInput_usernameranch" class="label">Password</label>
      <div class="inputIconCont right">
        <input
          pPassword
          type="password"
          id="workQueueDetailsInput_password"
          class="w-full"
          [feedback]="false"
          [showPassword]="showPassword"
          placeholder="Password"
          [(ngModel)]="password"
        />
        <button pButton type="button" class="default link square inputIconBtn" (click)="showPassword = !showPassword" title="Show/hide password">
          <fa-icon [icon]="showPassword ? faEyeSlash : faEye"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="mb-4">
    <alert color="danger" *ngIf="isError" [icon]="faCircleInfo" [label]="'Error'" description="Error message"> </alert>
  </div>
  <div class="">
    <button pButton class="outline default float-left" (click)="close()" >Cancel</button>
    <button pButton class="main float-right" (click)="submit()">Submit</button>
  </div>
</p-dialog>
